import React, { Component, Fragment } from "react";
import * as config from "../../config";
import * as messages from "../../messages.js";
import DataTable from "react-data-table-component";
import LoadingOverlay from "react-loading-overlay";
import DataTableExtensions from "react-data-table-component-extensions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import moment from "moment";
import Select from "react-select";
import axios from "../../api.js";
import { subMonths } from "date-fns";
import buttonIcon from "../UnassignedPOList/Split-assign-icon.png";
import commentIcon from "../SearchComponent/info-icon.png";
//import ReactExport from "react-export-excel";
import { confirmAlert } from "react-confirm-alert";
import ReactTooltip from "react-tooltip";
import { msalInstance } from "../../authConfig";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import * as FileSaver from "file-saver";
import Confirmation from "../SharedModel/ErrorMessage"
import excel from "exceljs";
import popupIcon from "../SplitComponent/tick-icon.png";
import NavBarComponent from "../NavBarComponent/NavBarComponent.jsx";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const ADTransactionsList = [
  {
    label: "Amortization",
    value: "A",
  },
  {
    label: "Depreciation",
    value: "D",
  },
  {
    label: "Both",
    value: "B",
  },
];

toast.configure();



export default class SearchPo extends Component {
  constructor() {
    super();
    this.mdfy = React.createRef();
    this.state = {
      listColumns: [],
      filteredList: [],
      columns: [],
      show: false,
      poId: "",
      supName: "",
      CstElmntName: "",
      splitBy: "",
      fromDate: "",
      toDate: "",
      column0Show: false,
      column1Show: false,
      column2Show: false,
      column3Show: false,
      txt1: "",
      txt2: "",
      txt3: "",
      txt4: "",
      txt5: "",
      isSplitted: false,
      isLoading: false,
      serachData: [],
      gridColumns: [],
      showTable: false,
      setmarginTop: false,
      firstchecked: false,
      document_date: "",
      created_dt: "",
      excelColumns: [],
      options: [],
      AsyncLoading: false,
      Splitobject: [],
      ADTransactions: ADTransactionsList,
      ADCostElement: "",
      allocationCodeList: [],
      allocationCode: "",
      ServiceLine: "",
      Service: "",
      ServiceLineList: [],
      ServiceList: [],
      FilteredServiceList: [],
      softwareNames: [],
      software: "",
      isddlLoading: false,
      docDesc: {},
      ShowAttributeVale: false,
      MGCAttributes: [],
      attributename: "",
      selectedColumns: [],
      ExportData: [],
      SearchObj: {},
      showConfirmation: false,
    };
    this.refPM = React.createRef();

    this.bindL1Codes();
    this.bindL2Codes();
    this.bindAttributeName();
    this.getDocDesc();
    this.getPdAllocationCode();
    this.bindSoftwareNames();
    this.getColumnsList();


  }

  //will fetch docdescription for Document type which will show as Tooltip
  getDocDesc = async () => {
    try {

      axios
        .get(config.baseURL + "getDocDescriptions")
        .then((response) => {

          var obj = {};
          response.data.forEach(function (e) {
            obj[e["DocType"]] = e["DocTypeDescription"];
          });
          this.setState({ docDesc: obj });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isLoading: false });
        });
    } catch (error) {
      console.log(error);
    }
  };

  //this method will get Service data and bind to required sesssion Attributes
  bindL1Codes = () => {
    axios
      .get(config.baseURL + "getL1Codes")
      .then((response) => {
        this.setState({ ServiceLineList: response.data });
        this.getLocalStorage();
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  };

  //this method will get Service line data and bind to required sesssion Attributes
  bindL2Codes = () => {
    axios
      .get(config.baseURL + "getAllL2Codes")
      .then((response) => {
        this.setState({ ServiceList: response.data });
        this.setState({ FilteredServiceList: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //this method will get MGC Attributes data and bind to required sesssion Attributes
  bindAttributeName = () => {
    axios
      .get(config.baseURL + "getMgcAttributeNames")
      .then((response) => {
        this.setState({ MGCAttributes: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //this method will get Service Line data and bind to required sesssion Attributes based in input L1
  cascadeL2 = (l1Code) => {
    try {
      this.setState({ ServiceList: [] });
      axios
        .get(config.baseURL + "getL2Codes/" + l1Code)
        .then((response) => {
          this.setState({ ServiceList: response.data });
          this.setState({ isddlLoading: false });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isddlLoading: false });
        });
    } catch (error) {
      console.log(error);
    }
  };
  //this method will get Softwares data and bind to required sesssion Attributes
  bindSoftwareNames = () => {
    axios
      .get(config.baseURL + "getServiceSoftwareNames")
      .then((response) => {
        var swarray = response.data;
        var otherobj = {
          label: "Other",
          value: "Other",
        };
        swarray.push(otherobj);
        this.setState({ softwareNames: swarray });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  };
  //this method will get columns names which are used for dynamic column search and bind to required sesssion Attributes
  getColumnsList = () => {
    try {
      this.state.isLoading = true;
      var exixtingColumns = [
        "CostElementName",
        "ModifiedDate",
        "ModifiedBy",
        "LoadDateTime",
        "EndDate",
        "ActiveStatus",
        "IsApproved",
        "IsJE",
        "IsUnassigned",
        "IsSystemGenerated",
        "LastUpdated",
        "LineNo",
        "StartDate",
        "PurchasingDocument",
        "EntityId",
        "LineNo",
        "CreationDate",
        "AccAsgtQuantity",
        "FullComment",
        "VendorName",
        "TBMMGCCode",
        "SupplierMaterialNumber",
        "Username",
        "Version",
        "Name",
        "MateriacolnitmypeCode",
        "EffectiveValue",
        "TransactionType",
      ];
      axios
        .get(config.baseURL + "getColumnsList")
        .then((response) => {
          var arry = response.data;
          arry = arry.filter(
            (item) => !exixtingColumns.includes(item.ColumnName)
          );

          var i = 0;
          for (i = 0; i < arry.length; i++) {
            arry[i].checked = false;
            if (arry[i].ColumnName === "Performance") {
              arry[i].ColumnName = "Performance";
              arry[i].label = "Performance $'s";
            }
          }
          var AdTransatcionColumn = {
            ColumnName: "TransactionType",
            DisplayColumn: "po.[CostElement]",
            checked: false,
            label: "Amortization/ Depreciation",
          };
          //enhacement requirement
          var AdTBMMgcColumn = {
            ColumnName: "TBMMGCCode",
            DisplayColumn: "po.[TBMMGCCode]",
            checked: false,
            label: "Parent MGC (TBM)",
          };


          arry.push(AdTransatcionColumn);
          arry.push(AdTBMMgcColumn);
          var newArray = arry.sort(function (a, b) {
            return a.label.localeCompare(b.label);
          });
          this.setState({ listColumns: newArray });
          this.setState({ filteredList: newArray });
          this.setState({ isLoading: false });
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // this will get name for the loggedin user
  getName = () => {
    try {
      var email =
        msalInstance.getAllAccounts()[0] !== null
          ? msalInstance.getAllAccounts()[0].username
          : "";


      return email;
    } catch (error) {
      console.log(error);
    }
  };

  //this method will open dynamic columns from transaction table where user can select columns to search
  openPopUp = () => {
    try {
      this.setState({ show: true });
      var colArray = this.state.listColumns;

      this.setState({ filteredList: colArray });
      var columnsArray = this.state.columns;
      var selectedArr = this.state.selectedColumns;
      var k = 0;
      for (k = 0; k < selectedArr.length; k++) {
        var index3 = columnsArray.findIndex((obj) => obj === selectedArr[k]);
        if (index3 < 0) {
          var index4 = selectedArr.findIndex((obj) => obj === selectedArr[k]);
          selectedArr.splice(index4, 1);
        }
      }
      this.setState({ selectedColumns: selectedArr });
      if (columnsArray.length > 0) {
        var i = 0;
        for (i = 0; i < colArray.length; i++) {
          var index = columnsArray.findIndex(
            (obj) => obj === colArray[i].label
          );
          if (index >= 0) {
            colArray[i].checked = true;
          } else {
            colArray[i].checked = false;
          }
        }

        this.setState({ filteredList: colArray });
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleCheckboxListChange = (controlId, label, idx) => {
    try {
      var array = this.state.columns;
      var selectedArr = this.state.selectedColumns;
      if (array.length <= 0) {
        this.setState({ firstchecked: true });
      }
      var filteredList = this.state.filteredList;
      if (document.getElementById(controlId).checked) {
        filteredList[idx].checked = true;
        if (
          filteredList[idx].label === "IsSplitted" &&
          filteredList[idx].checked === false
        ) {
          this.setState({ isSplitted: false });
        }
        filteredList.filter((fl) => fl.checked === true);
        const index = selectedArr.indexOf(filteredList[idx].label);
        if (index <= -1) {
          selectedArr.push(filteredList[idx].label);
        }
        if (selectedArr.length > 4) {
          filteredList[idx].checked = false;
          document.getElementById(controlId).checked = false;
          toast.error(messages.maxColumns, {
            autoClose: 10000,
          });
        }
      } else {
        filteredList[idx].checked = false;
        const index = selectedArr.indexOf(filteredList[idx].label);
        if (index > -1) {
          selectedArr.splice(index, 1);
          this.setState({ selectedColumns: selectedArr });
        }
      }

      this.setState({ filteredList: filteredList });
    } catch (error) {
      console.log(error);
    }
  };

  handleSearchChange = (e) => {
    try {
      var filtercolumns =
        e.target.value !== ""
          ? this.state.listColumns.filter((name) =>
            name.label
              .toLocaleLowerCase()
              .includes(e.target.value.toLocaleLowerCase())
          )
          : this.state.listColumns;
      setTimeout(() => {
        this.setState({ filteredList: filtercolumns });
        var filteredArray = this.state.filteredList;
        var columnsArray = this.state.columns;
        if (columnsArray.length > 0) {
          var i = 0;
          for (i = 0; i < filteredArray.length; i++) {
            var index = columnsArray.findIndex(
              (obj) => obj === filteredArray[i].label
            );
            if (index >= 0) {
              filteredArray[i].checked = true;
            }
          }

          this.setState({ filteredList: filteredArray });
        }
      }, 500);
    } catch (error) {
      console.log(error);
    }
  };
  //this method handles functionlity when you close the dynamic columns popup
  closePopup = () => {
    try {
      var array = this.state.columns;
      if (this.state.firstchecked) {
        setTimeout(() => {
          this.setState({ firstchecked: false });
        }, 200);

        array = [];
        this.setState({ columns: array });
        this.setState({ column0Show: false });
        this.setState({ column1Show: false });
        this.setState({ column2Show: false });
        this.setState({ column3Show: false });
        var filteredArray = this.state.listColumns;
        var i = 0;
        for (i = 0; i < filteredArray.length; i++) {
          filteredArray[i].checked = false;
        }
        this.setState({ filteredList: filteredArray });
        this.setState({ columns: [] });
      }

      if (array.length > 0) {
        this.setState({ setmarginTop: true });
      } else {
        this.setState({ setmarginTop: false });
      }
      this.setState({ show: false });
    } catch (error) {
      console.log(error);
    }
  };
  //this method handles functionlity when you close the dynamic columns popup when click on add button
  closePopupOnAdd = () => {
    try {
      var array = this.state.columns;
      var filteredList = this.state.filteredList;
      var i = 0;
      var selectedArr = this.state.selectedColumns;
      if (selectedArr.length > 0) {
        var fArray = filteredList;
        var j = 0;
        for (j = 0; j < selectedArr.length; j++) {
          var index = filteredList.findIndex(
            (obj) => obj.label === selectedArr[j]
          );
          if (index < 0) {
            var colObj = this.state.listColumns.filter(
              (s) => s.label === selectedArr[j]
            );
            fArray.push(colObj[0]);
          }
        }

        var k = 0;
        for (k = 0; k < array.length; k++) {
          var index3 = selectedArr.findIndex((obj) => obj === array[k]);
          if (index3 < 0) {
            var index4 = array.findIndex((obj) => obj === array[k]);
            array.splice(index4, 1);
          }
        }

        filteredList = fArray;
      }
      let checkTureExists = filteredList.findIndex(
        (row) => row.checked === true
      );
      if (checkTureExists < 0) {
        toast.error(messages.minColumns, {
          autoClose: 10000,
        });
      } else {
        for (i = 0; i < filteredList.length; i++) {
          if (filteredList[i].checked) {
            var index12 = array.indexOf(filteredList[i].label);
            if (index12 < 0) {
              array.push(filteredList[i].label);
              setTimeout(() => {
                this.setState({ columns: array });
              }, 1000);
            }
          } else {
            var index23 = array.indexOf(filteredList[i].label);
            if (index23 >= 0) {
              array.splice(index23, 1);
              setTimeout(() => {
                this.setState({ columns: array });
              }, 1000);
            }
          }
        }

        if (array.length > 0) {
          this.setState({ setmarginTop: true });
        } else {
          this.setState({ setmarginTop: false });
        }
        if (array.length > 0) {
          array = this.sortL1andL2(array);
          var l2filterarr = this.state.FilteredServiceList;
          this.setState({ ServiceList: l2filterarr });
          this.setState({ txt1: "" });
          this.setState({ txt2: "" });
          this.setState({ txt3: "" });
          this.setState({ txt4: "" });
          this.setState({ txt5: "" });
          this.setState({ allocationCode: "" });
          this.setState({ ADCostElement: "" });
          this.setState({ ServiceLine: "" });
          this.setState({ Service: "" });
          this.setState({ software: "" });
          this.setState({ software: "" });
          this.setState({ isSplitted: false });
          this.setState({ attributename: "" });
          this.ShowColumns(array);
          setTimeout(() => {
            this.setState({ firstchecked: false });
          }, 200);
          this.setState({ show: false });
        } else {
          toast.error(messages.minColumns, {
            autoClose: 10000,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };


  //this sot l1 and l2 columns in columns array for cascading purpose
  sortL1andL2 = (array) => {
    try {
      var l1index = array.indexOf("Service Line(L1)");
      var l2index = array.indexOf("Service (L2)");
      if (l1index >= 0 && l2index >= 0) {
        if (l1index > l2index) {
          array.splice(l2index, 1, array.splice(l1index, 1, array[l2index])[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
    return array;
  };
  //to show controls based on user selected columns drom dynamic columns list
  ShowColumns = (colarray) => {
    try {
      var array = colarray;
      const index = array.indexOf("MGC Attribute");
      if (index > -1) {
        array.splice(index, 1);
        array.push("MGC Attribute");
      }

      this.setState({ column0Show: false });
      this.setState({ column1Show: false });
      this.setState({ column2Show: false });
      this.setState({ column3Show: false });
      setTimeout(() => {
        this.setState({ columns: array });
      }, 1000);

      var i = 0;
      for (i = 0; i < array.length; i++) {
        switch (i) {
          case 0:
            this.setState({ column0Show: true });
            break;
          case 1:
            this.setState({ column1Show: true });
            break;
          case 2:
            this.setState({ column2Show: true });
            break;
          case 3:
            this.setState({ column3Show: true });
            break;
          default:
            break;
        }
      }
      var mgcAttrIndex = array.indexOf("MGC Attribute");
      if (mgcAttrIndex >= 0) {
        this.setState({ ShowAttributeVale: true });
      } else {
        this.setState({ ShowAttributeVale: false });
      }
    } catch (error) {
      console.log(error);
    }
  };

  //handles key down event form calender control	
  keyDownEvent = (event) => {
    try {
      const key = event.key;
      if (key === "Backspace" || key === "Delete") {
        return true;
      } else {
        event.preventDefault();
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };
  //it will clear session attributes
  RestControls = () => {
    try {
      var array = this.state.FilteredServiceList;
      this.setState({ ServiceList: array });
      this.setState({ column0Show: false });
      this.setState({ column1Show: false });
      this.setState({ column2Show: false });
      this.setState({ column3Show: false });
      this.setState({ ShowAttributeVale: false });
      this.setState({ attributename: "" });
      this.setState({ txt1: "" });
      this.setState({ txt2: "" });
      this.setState({ txt3: "" });
      this.setState({ txt4: "" });
      this.setState({ poId: "" });
      this.setState({ attributename: "" });
      this.setState({ isSplitted: false });
      this.setState({ supName: "" });
      this.setState({ CstElmntName: "" });
      this.setState({ splitBy: "" });
      this.setState({ fromDate: "" });
      this.setState({ toDate: "" });
      this.setState({ showTable: false });
      this.setState({ setmarginTop: false });
      this.setState({ ADCostElement: "" });
      this.setState({ allocationCode: "" });
      this.setState({ software: "" });
      this.setState({ ServiceLine: "" });
      this.setState({ Service: "" });
      this.setState({ Splitobject: [] });
      localStorage.removeItem("SerachData");
      var filteredArray = this.state.listColumns;
      var i = 0;
      for (i = 0; i < filteredArray.length; i++) {
        filteredArray[i].checked = false;
      }
      setTimeout(() => {
        this.setState({ filteredList: filteredArray });
      }, 500);
      this.setState({ selectedColumns: [] });
      this.setState({ columns: [] });
    } catch (error) {
      console.log(error);
    }
  };
  //this method will handle inputChange event called when we enter data and updte the required session attribute
  handleChangeValue = (e, type, index) => {
    try {
      if (e !== undefined && e !== null && type === "date") {
        const name = index;
        this.setState({ [name]: e });

        if (index === "toDate") {
          var dateOne1 = new Date(e);
          var dateTwo1 = new Date(this.state.fromDate);
          if (this.state.fromDate !== null) {
            if (dateOne1 < dateTwo1) {
              this.setState({ toDate: null });
              toast.error(messages.fromDateGreater, {
                autoClose: 10000,
              });
            }
          }
        }
        if (index === "fromDate") {
          var dateOne12 = new Date(e);
          var dateTwo12 = new Date(this.state.toDate);
          if (this.state.toDate != null) {
            if (dateOne12 > dateTwo12) {
              this.setState({ fromDate: null });
              toast.error(messages.toDatelesser, {
                autoClose: 10000,
              });
            }
          }
        }
      } else if (e !== undefined && e !== null && type === "chk") {
        this.setState({ isSplitted: e.target.checked });

        this.setState({ [index]: e.target.checked ? "true" : "" });
      } else if (e !== undefined && e !== null && e !== "" && type === "num") {
        var t = e.target.value;
        e.target.value =
          t.indexOf(".") >= 0
            ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)
            : t;
        if (e.target.value.length <= 20)
          this.setState({
            [index]: e.target.value,
          });
      } else if (type === "select") {
        this.setState({ ADCostElement: e });
        this.setState({ [index]: e });
      } else if (type === "selectA") {
        const myArray = e.split("-");
        this.setState({ allocationCode: e });
        this.setState({ [index]: myArray[0] });
      } else if (type === "selectL1") {
        this.setState({ ServiceLine: e });
        this.setState({ [index]: e });
        if (e !== "" && e !== null && e !== undefined) {
          this.setState({ isddlLoading: true });
          this.cascadeL2(e);
        } else {
          var array = this.state.FilteredServiceList;
          this.setState({ ServiceList: array });
        }
      } else if (type === "selectL2") {
        this.setState({ Service: e });
        this.setState({ [index]: e });
      } else if (type === "selectSW") {
        this.setState({ software: e });
        this.setState({ [index]: e });
      } else if (type === "selectMGC") {
        this.setState({ txt5: "" });
        this.setState({ attributename: e });
        this.setState({ [index]: e });
      } else {
        if (e !== undefined && e !== null)
          this.setState({
            [index]:
              e.target !== undefined
                ? e.target.value
                : new Date(this.formattedDate(e)),
          });
        else
          this.setState({
            [index]: "",
          });
      }
    } catch (error) {
      console.log(error);
    }
  };
  //this will search data based on filter given by user
  Search = () => {
    try {
      console.log("Step 1 : " + new Date());
      if (
        this.state.poId.trimEnd().trimStart() === "" &&
        this.state.supName.trimEnd().trimStart() === "" &&
        this.state.CstElmntName.trimEnd().trimStart() === "" &&
        this.state.splitBy.trimEnd().trimStart() === ""
      ) {
        if (
          moment(this.state.txt1, moment.ISO_8601, true).isValid() === false &&
          moment(this.state.txt2, moment.ISO_8601, true).isValid() === false &&
          moment(this.state.txt3, moment.ISO_8601, true).isValid() === false &&
          moment(this.state.txt4, moment.ISO_8601, true).isValid() === false
        ) {
          if (
            this.state.txt1.trimEnd().trimStart() === "" &&
            this.state.txt2.trimEnd().trimStart() === "" &&
            this.state.txt3.trimEnd().trimStart() === "" &&
            this.state.txt4.trimEnd().trimStart() === ""
          ) {
            if (
              (this.state.attributename === null ||
                this.state.attributename === "") &&
              this.state.txt5.trimEnd().trimStart() !== "" &&
              this.state.txt5 !== null &&
              this.state.txt5 !== undefined
            ) {
              toast.error(messages.attributeNameRequired, {
                autoClose: 10000,
              });
            } else {
              if (
                this.state.fromDate !== "" &&
                this.state.fromDate !== null &&
                this.state.toDate !== "" &&
                this.state.toDate !== null
              ) {
                this.SearchInnerFunction();
              } else {
                toast.error(messages.dateRequired, {
                  autoClose: 10000,
                });
              }
            }
          } else {
            if (
              this.state.attributename !== null &&
              this.state.attributename !== "" &&
              this.state.txt5.trimEnd().trimStart() === ""
            ) {
              toast.error(messages.attributevalueRequired, {
                autoClose: 10000,
              });
            } else {
              this.SearchInnerFunction();
            }
          }
        } else {
          if (
            this.state.attributename !== null &&
            this.state.attributename !== "" &&
            this.state.txt5.trimEnd().trimStart() === ""
          ) {
            toast.error(messages.attributevalueRequired, {
              autoClose: 10000,
            });
          } else {
            this.SearchInnerFunction();
          }
        }
      } else {
        if (this.state.fromDate !== "" && this.state.toDate === "") {
          toast.error(messages.dateRequired, {
            autoClose: 10000,
          });
        } else if (this.state.fromDate === "" && this.state.toDate !== "") {
          toast.error(messages.dateRequired, {
            autoClose: 10000,
          });
        } else {
          if (
            this.state.attributename !== null &&
            this.state.attributename !== "" &&
            this.state.txt5.trimEnd().trimStart() === ""
          ) {
            toast.error(messages.attributevalueRequired, {
              autoClose: 10000,
            });
          } else {
            this.SearchInnerFunction();
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  //internal method called in side the search
  SearchInnerFunction = () => {
    try {
      var colArray = this.state.listColumns;
      var dynamicColumns = this.state.columns;
      this.setState({ isLoading: true });
      this.setState({ SearchObj: {} });
      const keys = [
        "PurchasingDocument",
        "VendorName",
        "CostElementName",
        "ModifiedBy",
        "DateFrom",
        "DateTo",
      ];

      const values = [
        this.state.poId.trimEnd().trimStart(),
        this.state.supName.trimEnd().trimStart(),
        this.state.CstElmntName.trimEnd().trimStart(),
        this.state.splitBy.trimEnd().trimStart(),
        this.formattedDate(this.state.fromDate),
        this.formattedDate(this.state.toDate),
      ];

      var i = 0;
      for (i = 0; i < dynamicColumns.length; i++) {
        keys.push(
          colArray.filter((item) => item.label === dynamicColumns[i])[0]
            .ColumnName
        );

        switch (i) {
          case 0:
            if (
              moment(this.state.txt1, "MM/DD/YYYY", true).isValid() === false
            ) {
              if (dynamicColumns[i] === "Is Splitted") {
                if (this.state.isSplitted) values.push(1);
                else values.push(0);
              } else {
                values.push(this.state.txt1.trimEnd().trimStart());
              }
            } else values.push(this.formattedDate(this.state.txt1));
            break;
          case 1:
            if (
              moment(this.state.txt2, "MM/DD/YYYY", true).isValid() === false
            ) {
              if (dynamicColumns[i] === "Is Splitted") {
                if (this.state.isSplitted) values.push(1);
                else values.push(0);
              } else {
                values.push(this.state.txt2.trimEnd().trimStart());
              }
            } else values.push(this.formattedDate(this.state.txt2));
            break;
          case 2:
            if (
              moment(this.state.txt3, "MM/DD/YYYY", true).isValid() === false
            ) {
              if (dynamicColumns[i] === "Is Splitted") {
                if (this.state.isSplitted) values.push(1);
                else values.push(0);
              } else {
                values.push(this.state.txt3.trimEnd().trimStart());
              }
            } else values.push(this.formattedDate(this.state.txt3));
            break;
          case 3:
            if (
              moment(this.state.txt4, "MM/DD/YYYY", true).isValid() === false
            ) {
              if (dynamicColumns[i] === "Is Splitted") {
                if (this.state.isSplitted) values.push(1);
                else values.push(0);
              } else {
                values.push(this.state.txt4.trimEnd().trimStart());
              }
            } else values.push(this.formattedDate(this.state.txt4));
            break;

          default:
            break;
        }
      }

      const index = dynamicColumns.indexOf("MGC Attribute");

      if (index > -1) {
        keys.push("AttributeValue");
        values.push(this.state.txt5);
      }

      const searchArray = keys.reduce(
        (carry, key, index) => ((carry[key] = values[index]), carry),
        {}
      );
      var obj = {
        searchparams: searchArray,
        Username: this.getName()
      };
      this.setState({ SearchObj: obj });
      this.setLocalStorage(searchArray);
      console.log("Step 2 : " + new Date());
      axios
        .post(config.baseURL + "getSearchResults", obj)
        .then((response) => {
          console.log("Step 3 : " + new Date());
          //this.prepareExcelColumns();
          this.prepareGridDynamicColumns();
          var arr = response.data[0];
          this.setState({ serachData: arr });
          this.setState({ isLoading: false });
          this.setState({ showTable: true });
          this.ShowColumns(this.state.columns);
          console.log("Step 4 : " + new Date());
          //this.DownloadExcelData(obj);

        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log(error.response);
          toast.error(messages.Error, {
            autoClose: 10000,
          });
        });
    } catch (error) {
      this.setState({ isLoading: false });
      toast.error("Search Failed", {
        autoClose: 10000,
      });
      console.log(error);
    }
  };

  // to store search criteria in to local storage to retain search related data
  setLocalStorage = (searchArray) => {
    try {
      var sessionobj = {
        searchparams: searchArray,
        Username: this.getName(),
        ADCostElement: this.state.ADCostElement,
        isSplitted: this.state.isSplitted,
        txt0: this.state.txt0,
        txt1: this.state.txt1,
        txt2: this.state.txt2,
        txt3: this.state.txt3,
        txt5: this.state.txt5,
        allocationCode: this.state.allocationCode,
        ServiceLine: this.state.ServiceLine,
        Service: this.state.Service,
        software: this.state.software,
        ShowAttributeVale: this.state.ShowAttributeVale,
        attributename: this.state.attributename,
        dynamicColumns: this.state.columns,
        Splitobject: this.state.Splitobject,
        listColumns: this.state.listColumns,
      };
      localStorage.setItem("SerachData", JSON.stringify(sessionobj));
    }
    catch (error) {
      console.log(error);
    }
  }
  // to get search criteria from  local storage to retain search related data
  getLocalStorage = () => {
    try {
      var sessionobj = localStorage.getItem("SerachData");

      if (sessionobj != null) {
        const obj = JSON.parse(sessionobj);
        this.setState({
          ADCostElement: obj.ADCostElement != "" ? obj.ADCostElement : "", isSplitted: obj.isSplitted,
          txt0: obj.txt0 != "" ? obj.txt0 : "",
          txt1: obj.txt1 != "" ? obj.txt1 : "",
          txt2: obj.txt2 != "" ? obj.txt2 : "",
          txt3: obj.txt3 != "" ? obj.txt3 : "",
          txt5: obj.txt5 != "" ? obj.txt5 : "",
          allocationCode: obj.allocationCode != "" ? obj.allocationCode : "",
          ServiceLine: obj.ServiceLine != "" ? obj.ServiceLine : "",
          Service: obj.Service != "" ? obj.Service : "",
          software: obj.software != "" ? obj.software : "",
          attributename: obj.attributename != "" ? obj.attributename : "",
          poId: obj.searchparams.PurchasingDocument != "" ? obj.searchparams.PurchasingDocument : "",
          CstElmntName: obj.searchparams.CostElementName != "" ? obj.searchparams.CostElementName : "",
          supName: obj.searchparams.VendorName != "" ? obj.searchparams.VendorName : "",
          fromDate: obj.searchparams.DateFrom != "" ? new Date(obj.searchparams.DateFrom) : "",
          toDate: obj.searchparams.DateTo != "" ? new Date(obj.searchparams.DateTo) : "",
          Splitobject: obj.Splitobject,
          columns: obj.dynamicColumns.length > 0 ? obj.dynamicColumns : [],
          listColumns: obj.listColumns.length > 0 ? obj.listColumns : []
        })
        this.ShowColumns(obj.dynamicColumns);
        var Searchobj = {
          searchparams: obj.searchparams,
          Username: obj.Username
        };
        this.setState({ SearchObj: Searchobj });
        axios
          .post(config.baseURL + "getSearchResults", Searchobj)
          .then((response) => {
            this.prepareGridDynamicColumns();
            var arr = response.data[0];
            this.setState({ serachData: arr });
            this.setState({ isLoading: false });
            this.setState({ showTable: true });
            this.ShowColumns(this.state.columns);
          })
          .catch((error) => {
            this.setState({ isLoading: false });
            console.log(error.response);
            toast.error(messages.Error, {
              autoClose: 10000,
            });
          });
        if (obj.dynamicColumns.length > 0) {
          this.setState({ setmarginTop: true });

        } else {
          this.setState({ setmarginTop: false });
        }


      }


    }
    catch (error) {
      console.log(error);
    }
  }
  // to down load excel based on search filters
  DownloadExcelData = (searchobj) => {
    try {
      var json = JSON.stringify(searchobj);
      json = json.replaceAll("/", "%2F");
      window.location.href = config.baseURL + 'downloadSearchExcelGet/' + encodeURIComponent(json);

    } catch (error) {
      toast.error("Download Failed", {
        autoClose: 10000,
      });
      console.log(error);
    }
  };



  //Export new method Client Side
  getSearchDatatoExport = async (searchobj) => {
    try {
      this.setState({ isLoading: true });
      var fileName = "TBM Search_PO_Results_" + this.formattedDateExcelName(new Date());
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      var json = JSON.stringify(searchobj);
      json = json.replaceAll("/", "%2F");

      axios
        .get(config.baseURL + "downloadSearchExcelGetNew/" + encodeURIComponent(json))
        .then((response) => {
          let workbook = new excel.Workbook();
          let worksheet = workbook.addWorksheet("Search Results");
          var coltoExport = [
            { header: "Purchasing Document", key: "PurchasingDocument", width: 10 },
            { header: "Vendor Parent Company", key: "ParentCompany", width: 10 },
            { header: "Cost Center", key: "CostCenter", width: 10 },
            { header: "Cost Center Name", key: "CostCenterName", width: 10 },
            { header: "Cost Element", key: "CostElement", width: 10 },
            { header: "Cost Element Name", key: "CostElementName", width: 10 },
            { header: "Cost Element Desc", key: "Description", width: 10 },
            { header: "Cost Element SubCategory", key: "SubCategory", width: 10 },
            { header: "Plant", key: "plantcode", width: 50 },
            { header: "Posting Date", key: "PostingDate", width: 10 },
            { header: "Created On", key: "CreatedOn", width: 10 },
            { header: "Document Date", key: "DocumentDate", width: 10 },
            { header: "Document Header Text", key: "DocumentHeaderText", width: 10 },
            { header: "Document Type", key: "DocumentType", width: 10 },
            { header: "Document Type Description", key: "DocTypeDescription", width: 10 },
            { header: "Effective Value", key: "EffectiveValue", width: 10 },
            { header: "Fiscal Year", key: "FiscalYear", width: 10 },
            { header: "Invoice Document Item", key: "InvoiceDocumentItem", width: 10 },
            { header: "Requisitioner", key: "Requisitioner", width: 10 },
            { header: "Reference Document Number", key: "ReferenceDocumentNumber", width: 10 },
            { header: "Purchasing Group", key: "PurchasingGroup", width: 10 },
            { header: "LineNo", key: "LineNo", width: 10 },
            { header: "Performance $'s", key: "Performance", width: 10 },
            { header: "Profit Center", key: "ProfitCenter", width: 10 },
            { header: "Short Text", key: "ShortText", width: 10 },
            { header: "Acc Asgt Quantity", key: "AccAsgtQuantity", width: 10 },
            { header: "Order Quantity", key: "OrderQuantity", width: 10 },
            { header: "Order Unit", key: "OrderUnit", width: 10 },
            { header: "Net Price", key: "NetPrice", width: 10 },
            { header: "Currency", key: "Currency", width: 10 },
            { header: "CO Area Currency", key: "COAreaCurrency", width: 10 },
            { header: "Controlling Area", key: "ControllingArea", width: 10 },
            { header: "Company Code", key: "CompanyCode", width: 10 },
            { header: "Still To Be Delivered Qty", key: "StillToBeDeliveredQty", width: 10 },
            { header: "Still To Be Delivered Val", key: "StillToBeDeliveredVal", width: 10 },
            { header: "Gross Order Value", key: "GrossOrderValue", width: 10 },
            { header: "Net Order Value", key: "NetOrderValue", width: 10 },
            { header: "Object Currency", key: "ObjectCurrency", width: 10 },
            { header: "Value in Controlling Area Currency", key: "ValCoAreaCrcy", width: 10 },
            { header: "Value in Object Currency", key: "ValObjCrcy", width: 10 },
            { header: "Period", key: "Period", width: 10 },
            { header: "Price Date", key: "PriceDate", width: 10 },
            { header: "Material Code", key: "MaterialCode", width: 10 },
            { header: "Material Group Code", key: "MaterialGroupCode", width: 10 },
            { header: "Parent MGC (TBM)", key: "TBMMGCCode", width: 10 },
            { header: "Modified By", key: "ModifiedBy", width: 10 },
            { header: "Modified Date", key: "ModifiedDate", width: 10 },
            { header: "Name", key: "Name", width: 10 },
            { header: "Comments", key: "FullComment", width: 10 },
            { header: "Software Name", key: "SoftwareName", width: 10 },
            { header: "Allocation Code", key: "AllocationCode", width: 10 },
            { header: "Amortization/ Depreciation", key: "TransactionType", width: 10 },
            { header: "Is Splitted", key: "IsSplitted", width: 10 },
            { header: "MGC Attribute", key: "MGCAttribute", width: 10 },
            { header: "IT Function", key: "ITFunction", width: 10 },
            { header: "Rollup Scorecard", key: "RollupScorecard", width: 10 },
            { header: "Purchase Order Text", key: "PurchaseOrderText", width: 10 },
            { header: "Material Type Code", key: "MaterialTypeCode", width: 10 },
            { header: "Document Number", key: "DocumentNumber", width: 10 },
            { header: "Supplier Material Number", key: "SupplierMaterialNumber", width: 10 },
            { header: "EntityId", key: "EntityId", width: 10 },
            { header: "Vendor Name", key: "VendorName", width: 10 },
            { header: "Vendor Code", key: "Vendor", width: 10 },
            { header: "Service Line", key: "ServiceLineL1", width: 10 },
            { header: "Service", key: "ServiceL2", width: 10 },
            { header: "Purchasing Document (PO ID)", key: "POID", width: 10 },
            { header: "Comments", key: "comments", width: 10 },

          ];

          worksheet.columns = coltoExport;
          worksheet.addRows(response.data);
          worksheet.getRow(1).font = { name: 'Calibri', family: 2, size: 11, bold: true };
          worksheet.getCell("A1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("B1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("C1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("D1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("E1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("F1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("G1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("H1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("I1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("J1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("K1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("L1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("M1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("N1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("O1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("P1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("Q1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("R1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("S1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("T1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("U1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("V1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("W1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("X1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("Y1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("Z1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("AA1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("AB1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("AC1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("AD1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("AE1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("AF1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("AG1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("AH1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("AI1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("AJ1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("AK1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("AL1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("AM1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("AN1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("AO1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("AP1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("AQ1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("AR1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("AS1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("AT1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("AU1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("AV1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("AW1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("AX1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("AY1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("AZ1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("BA1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("BB1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("BC1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("BD1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("BE1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("BF1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("BG1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("BH1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("BI1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
          worksheet.getCell("BJ1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };


          worksheet.getColumn(18).numFmt = '#,##0.00';
          worksheet.getColumn(24).numFmt = '#,##0.00';
          worksheet.getColumn(28).numFmt = '#,##0.00';
          worksheet.getColumn(30).numFmt = '#,##0.00';
          worksheet.getColumn(35).numFmt = '#,##0.00';
          worksheet.getColumn(36).numFmt = '#,##0.00';
          worksheet.getColumn(37).numFmt = '#,##0.00';
          worksheet.getColumn(38).numFmt = '#,##0.00';
          worksheet.getColumn(40).numFmt = '#,##0.00';
          worksheet.getColumn(41).numFmt = '#,##0.00';
          worksheet.getColumn(27).numFmt = '#,##0.00';
          worksheet.getColumn(62).numFmt = '@';
          workbook.xlsx.writeBuffer().then(function (buffer) {
            const data = new Blob([buffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
          });
          this.setState({ isLoading: false });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isLoading: false });
        });

    }
    catch (error) {
      console.log(error);
    }
  }




  //to prepare dynamic cloumns to show in data table
  prepareGridDynamicColumns = () => {
    try {
      var dynamicColumns = this.state.columns;
      var colArray = this.state.listColumns;
      this.setState({ gridColumns: [] });
      var objArray = [
        {
          name: "PO/Reference Id",
          selector: "PurchasingDocument",
          sortable: true,
          wrap: true,
          //width:"150px"
        },
        {
          name: "Vendor Name",
          selector: "VendorName",
          sortable: true,
          wrap: true,
        },
        {
          name: "Cost Element Name",
          selector: "CostElementName",
          sortable: true,
          wrap: true,
        },
        {
          name: "Cost Center Name",
          selector: "CostCenterName",
          sortable: true,
          wrap: true,
        },
        {
          name: "Performance $'s",
          selector: "Performance",
          sortable: true,
          wrap: true,
          cell: (row) =>
            row.Performance !== undefined && row.Performance !== null
              ? row.Performance.toLocaleString(navigator.language, {
                minimumFractionDigits: 2,
              })
              : "",
        },
        {
          name: "Updated Date",
          selector: "ModifiedDate",
          sortable: true,
          wrap: true,
          cell: (row) =>
            row.ModifiedDate !== null
              ? moment(row.ModifiedDate).format("MM/DD/YYYY")
              : "",
        },
      ];
      var i = 0;
      for (i = 0; i < dynamicColumns.length; i++) {
        var colValue = colArray.filter(
          (item) => item.label === dynamicColumns[i]
        )[0].ColumnName;
      

        if (dynamicColumns[i] === "Comment") {
          colValue = colValue !== "" && colValue !== null ? colValue + "..." : "";
          var obj = {
            name: dynamicColumns[i],
            selector: "FullComment",
            sortable: true,
            cell: (row) =>
              row.FullComment !== "" && row.FullComment !== null ? (
                row.FullComment.length > 10 ? (
                  <span>
                    {row.Comment !== "" && row.Comment !== null
                      ? row.Comment + "..."
                      : ""}
                    <img
                      data-tip={row.FullComment}
                      data-for="fullcomments"
                      style={{
                        width: "25px",
                        cursor: "pointer",
                        marginLeft: "8px",
                      }}
                      src={commentIcon}
                      alt="icon"
                    />
                    <ReactTooltip
                      aria-haspopup="true"
                      role="example"
                      className="extraClass"
                      id="fullcomments"
                      multiline="true"
                      type="dark"
                    ></ReactTooltip>
                  </span>
                ) : (
                  <span>{row.Comment}</span>
                )
              ) : (
                <span>{row.Comment}</span>
              ),
          };
          objArray.push(obj);
        } else if (dynamicColumns[i] === "Document Type") {
          var obj = {
            name: "Document Type",
            selector: "DocumentType",
            sortable: true,
            cell: (row) => (
              <span>
                <span
                  data-tip={
                    this.state.docDesc[row.DocumentType] !== null &&
                      this.state.docDesc[row.DocumentType] !== undefined &&
                      this.state.docDesc[row.DocumentType] !== ""
                      ? this.state.docDesc[row.DocumentType]
                      : "Unknown"
                  }
                  data-for="DocumentType"
                >
                  {row.DocumentType}
                </span>

                <ReactTooltip
                  aria-haspopup="true"
                  role="example"
                  className="extraClass"
                  id="DocumentType"
                  multiline="true"
                  type="dark"
                ></ReactTooltip>
              </span>
            ),
          };
          objArray.push(obj);
        } 
      else if (dynamicColumns[i] === "Posting Date") {
          var obj = {
            name: "Posting Date" ,
            selector: "PostingDate",
            sortable: true,
            cell: (row) =>
            row.PostingDate!== null
              ? moment(row.PostingDate).format("MM/DD/YYYY")
              : "",
          };
          objArray.push(obj);
      }
        else if (
          dynamicColumns[i] === "Effective Value" ||
          dynamicColumns[i] === "Gross Order Value" ||
          dynamicColumns[i] === "Net Order Value" ||
          dynamicColumns[i] === "Net Price" ||
          dynamicColumns[i] === "Value in Controlling Area Currency" ||
          dynamicColumns[i] === "Value in Object Currency" ||
          dynamicColumns[i] === "Order Quantity" ||
          dynamicColumns[i] === "Still To be Delivered Qty" ||
          dynamicColumns[i] === "Still To be Delivered Val"
        ) {
          objArray.push(this.retunCellValue(dynamicColumns[i], colValue));
        } else {
          if (dynamicColumns[i] === "Is Splitted") {
            var obj = {
              name: dynamicColumns[i],
              selector: colValue,
              sortable: true,
              cell: (row) => (row.IsSplitted ? "True" : "False"),
            };

            objArray.push(obj);
          } else {
            if (
              dynamicColumns[i] !== "Performance $'s" &&
              dynamicColumns[i] !== "Cost Center Name"
            ) {
              if (dynamicColumns[i] === "IT Function") {
                var obj = {
                  name: dynamicColumns[i],
                  selector: colValue,
                  sortable: true,
                  cell: (row) => <pre>{row.ITFunction}</pre>
                };
                objArray.push(obj);
              }
              
              else {
                objArray.push(this.returnExactcolumnValue(dynamicColumns[i], colValue));
                // var obj = {
                //   name: dynamicColumns[i],
                //   selector: colValue,
                //   sortable: true,
                //   cell: (row) => <pre>{row[colValue]}</pre>,
                // };

                // objArray.push(obj);
              }
            }
          }
        }
      }

      var actionColumns = {
        name: "",
        selector: "",
        width: "110px",
        cell: (row) => (
          <button
            className="btn btn-primary"
            onClick={(e) => {
              window.location.href = this.prepareDynamicUrlForButtons(row);
            }}
          >
            <img style={{ width: "33px" }} src={buttonIcon} alt="icon" />
          </button>
        ),
      };
      objArray.push(actionColumns);

      this.setState({ gridColumns: objArray });
    } catch (error) {
      console.log(error);
    }
  };
  //to prepare columns object for data tables based on condition 
  retunCellValue = (columnName, colValue) => {
    try {
      switch (columnName) {
        case "Effective Value": {
          var obj = {
            name: columnName,
            selector: colValue,
            sortable: true,
            cell: (row) =>
              row.EffectiveValue !== undefined && row.EffectiveValue !== null
                ? row.EffectiveValue.toLocaleString(navigator.language, {
                  minimumFractionDigits: 2,
                })
                : "",
          };
          return obj;
        }
        case "Value in Object Currency": {
          var obj = {
            name: columnName,
            selector: colValue,
            sortable: true,
            cell: (row) =>
              row.ValObjCrcy !== undefined && row.ValObjCrcy !== null
                ? row.ValObjCrcy.toLocaleString(navigator.language, {
                  minimumFractionDigits: 2,
                })
                : "",
          };
          return obj;
        }

        case "Gross Order Value": {
          var obj = {
            name: columnName,
            selector: colValue,
            sortable: true,
            cell: (row) =>
              row.GrossOrderValue !== undefined && row.GrossOrderValue !== null
                ? row.GrossOrderValue.toLocaleString(navigator.language, {
                  minimumFractionDigits: 2,
                })
                : "",
          };
          return obj;
        }
        case "Net Price": {
          var obj = {
            name: columnName,
            selector: colValue,
            sortable: true,
            cell: (row) =>
              row.NetPrice !== undefined && row.NetPrice !== null
                ? row.NetPrice.toLocaleString(navigator.language, {
                  minimumFractionDigits: 2,
                })
                : "",
          };
          return obj;
        }
        case "Net Order Value": {
          var obj = {
            name: columnName,
            selector: colValue,
            sortable: true,
            cell: (row) =>
              row.NetOrderValue !== undefined && row.NetOrderValue !== null
                ? row.NetOrderValue.toLocaleString(navigator.language, {
                  minimumFractionDigits: 2,
                })
                : "",
          };
          return obj;
        }

        case "Value in Controlling Area Currency": {
          var obj = {
            name: columnName,
            selector: colValue,
            sortable: true,
            cell: (row) =>
              row.ValCoAreaCrcy !== undefined && row.ValCoAreaCrcy !== null
                ? row.ValCoAreaCrcy.toLocaleString(navigator.language, {
                  minimumFractionDigits: 2,
                })
                : "",
          };
          return obj;
        }
        case "Still To be Delivered Val": {
          var obj = {
            name: columnName,
            selector: colValue,
            sortable: true,
            cell: (row) =>
              row.StillTobedeliveredVal !== undefined &&
                row.StillTobedeliveredVal !== null
                ? row.StillTobedeliveredVal.toLocaleString(navigator.language, {
                  minimumFractionDigits: 2,
                })
                : "",
          };
          return obj;
        }
        case "Still To be Delivered Qty": {
          var obj = {
            name: columnName,
            selector: colValue,
            sortable: true,
            cell: (row) =>
              row.StillTobedeliveredQty !== undefined &&
                row.StillTobedeliveredQty !== null
                ? row.StillTobedeliveredQty.toLocaleString(navigator.language, {
                  minimumFractionDigits: 2,
                })
                : "",
          };
          return obj;
        }
        case "Order Quantity": {
          var obj = {
            name: columnName,
            selector: colValue,
            sortable: true,
            cell: (row) =>
              row.OrderQuantity !== undefined &&
                row.OrderQuantity !== null
                ? row.OrderQuantity.toLocaleString(navigator.language, {
                  minimumFractionDigits: 2,
                })
                : "",
          };
          return obj;
        }
        case "Posting Date": {
          var obj = {
            name: columnName,
            selector: colValue,
            sortable: true,
            cell: (row) =>
            row.PostingDate[0] !== null
              ? moment(row.PostingDate[0]).format("MM/DD/YYYY")
              : "",
          };
          return obj;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  //to prepare columns object for data tables by setting colum properties 
  returnExactcolumnValue = (dynamicColumn, colValue) => {
    var obj = {
      name: dynamicColumn,
      selector: colValue,
      sortable: true,
      cell: (row) => <pre>{row[colValue]}</pre>,
    };
    return obj;
  };
  // to return date format for columns
  retunDate = (row) => {
    try {
      if (row.DateFrom !== null && row.DateFrom !== undefined) {
        return moment(row.DateFrom).format("MM/DD/YYYY");
      } else {
        return moment(row.ModifiedBy).format("MM/DD/YYYY");
      }
    } catch (error) {
      console.log(error);
    }
  };
  // it will prepare columns for excel sheet while exportig
  prepareExcelColumns = () => {
    try {
      this.setState({ excelColumns: [] });
      var objArray = [
        {
          name: "PO/Reference Id",
          selector: "PurchasingDocument",
          sortable: true,
        },
        {
          name: "Vendor Name",
          selector: "VendorName",
          sortable: true,
        },
        {
          name: "Vendor Code",
          selector: "Vendor",
          sortable: true,
        },
        {
          name: "Vendor Parent Company",
          selector: "ParentCompany",
          sortable: true,
        },
        {
          name: "Cost Center Name",
          selector: "CostCenterName",
          sortable: true,
        },
        {
          name: "Cost Center",
          selector: "CostCenter",
          sortable: true,
        },
        {
          name: "Cost Element",
          selector: "CostElement",
          sortable: true,
        },
        {
          name: "Cost Element Name",
          selector: "CostElementName",
          sortable: true,
        },
        {
          name: "Cost Element Description",
          selector: "Description",
          sortable: true,
        },
        {
          name: "Cost Element SubCategory",
          selector: "SubCategory",
          sortable: true,
        },
        {
          name: "Profit Centre",
          selector: "ProfitCenter",
          sortable: true,
        },
        {
          name: "Purchase Order Text",
          selector: "PurchaseOrderText",
          sortable: true,
        },
        {
          name: "Purchasing Group",
          selector: "PurchasingGroup",
          sortable: true,
        },
        {
          name: "Reference Document Number",
          selector: "ReferenceDocumentNumber",
          sortable: true,
        },
        {
          name: "Requisitioner",
          selector: "Requisitioner",
          sortable: true,
        },
        {
          name: "Short Text",
          selector: "ShortText",
          sortable: true,
        },
        {
          name: "Plant",
          selector: "PlantCode",
          sortable: true,
        },
        {
          name: "Acc Asgt Quantity",
          selector: "AccAsgtQuantity",
          sortable: true,
        },
        {
          name: "CO Area Currency",
          selector: "COAreaCurrency",
          sortable: true,
        },
        {
          name: "Company Code",
          selector: "CompanyCode",
          sortable: true,
        },
        {
          name: "Controlling Area",
          selector: "ControllingArea",
          sortable: true,
        },
        {
          name: "Currency",
          selector: "Currency",
          sortable: true,
        },
        {
          name: "Performance $'s",
          selector: "Performance",
          sortable: true,
        },
        {
          name: "Net Price",
          selector: "NetPrice",
          sortable: true,
        },
        {
          name: "Order Quantity",
          selector: "OrderQuantity",
          sortable: true,
        },
        {
          name: "Order Unit",
          selector: "OrderUnit",
          sortable: true,
        },
        {
          name: "Still To Be Delivered Qty",
          selector: "StillTobedeliveredQty",
          sortable: true,
        },
        {
          name: "Still To Be Delivered Val",
          selector: "StillTobedeliveredVal",
          sortable: true,
        },
        {
          name: "Gross Order Value",
          selector: "GrossOrderValue",
          sortable: true,
        },
        {
          name: "Net Order Value",
          selector: "NetOrderValue",
          sortable: true,
        },
        {
          name: "Object Currency",
          selector: "ObjectCurrency",
          sortable: true,
        },
        {
          name: "Value in Controlling Area Currency",
          selector: "ValCoAreaCrcy",
          sortable: true,
        },

        {
          name: "Value in Object Currency",
          selector: "ValObjCrcy",
          sortable: true,
        },
        {
          name: "Period",
          selector: "Period",
          sortable: true,
        },

        {
          name: "Posting Date",
          selector: "PostingDate",
          sortable: true,
        },
        {
          name: "Price Date",
          selector: "PriceDate",
          sortable: true,
        },

        {
          name: "Created On",
          selector: "CreatedOn",
          sortable: true,
        },
        {
          name: "Document Date",
          selector: "DocumentDate",
          sortable: true,
        },
        {
          name: "Document Header Text",
          selector: "DocumentHeaderText",
          sortable: true,
        },
        {
          name: "Document Type",
          selector: "DocumentType",
          sortable: true,
        },
        {
          name: "Document Type Description",
          selector: "DocTypeDescription",
          sortable: true,
        },
        {
          name: "Effective Value",
          selector: "EffectiveValue",
          sortable: true,
        },
        {
          name: "Fiscal Year",
          selector: "FiscalYear",
          sortable: true,
        },

        {
          name: "Invoice Document Item",
          selector: "InvoiceDocumentItem",
          sortable: true,
        },
        {
          name: "Material Group Code",
          selector: "MaterialGroupCode",
          sortable: true,
        },
        {
          name: "TBM MGC Code",
          selector: "TBMMGCCode",
          sortable: true,
        },
        {
          name: "Service Line",
          selector: "ServiceLineL1",
          sortable: true,
        },
        {
          name: "Service",
          selector: "ServiceL2",
          sortable: true,
        },
        {
          name: "Allocation Code",
          selector: "AllocationCode",
          sortable: true,
        },
        {
          name: " Material Code",
          selector: "MaterialCode",
          sortable: true,
        },
        {
          name: "Modified By",
          selector: "ModifiedBy",
          sortable: true,
        },
        {
          name: "Modified Date",
          selector: "ModifiedDate",
          sortable: true,
        },
        {
          name: "Name",
          selector: "Name",
          sortable: true,
        },

        {
          name: "Comments",
          selector: "FullComment",
          sortable: true,
        },
        {
          name: "Software Name",
          selector: "SoftwareName",
          sortable: true,
        },
        {
          name: "Amortization/ Depreciation",
          selector: "TransactionType",
          sortable: true,
        },

        {
          name: "Is Splitted",
          selector: "IsSplitted",
          sortable: true,
        },
        {
          name: "IT Function",
          selector: "ITFunction",
          sortable: true,
        },
        {
          name: "Rollup Scorecard",
          selector: "RollupScorecard",
          sortable: true,
        },
        {
          name: "MGC Attribute",
          selector: "MGCAttribute",
          sortable: true,
        },
      ];
      this.setState({ excelColumns: objArray });
    } catch (error) {
      console.log(error);
    }
  };
  // it will prepare urls to redirect to specific screen when we click button in data tables
  prepareDynamicUrlForButtons = (row) => {
    var url = "";
    if (
      row.PurchasingDocument !== "" &&
      row.TBMMGCCode !== null &&
      row.TBMMGCCode !== "" &&
      row.TBMMGCCode !== undefined
    ) {
      url =
        "/AssignL2?id=" +
        row.EntityId +
        "&isEdit=true" +
        "&isFrom=search&lineno=" +
        row.LineNo +
        "&postingDate=" +
        moment(row.PostingDate).format("YYYY-MM-DD")
        ;
    } else if (
      row.PurchasingDocument !== "" &&
      row.PurchasingDocument !== null &&
      (row.TBMMGCCode === null ||
        row.TBMMGCCode === "" ||
        row.TBMMGCCode === undefined)
    ) {
      url =
        "/SplitorAssign?id=" +
        row.EntityId +
        "&LineNumber=" +
        row.LineNo +
        "&postingDate=" +
        moment(row.PostingDate).format("YYYY-MM-DD")
        +
        "&isFrom=search";
    } else {
      url = "/AssignL2?id=" + row.EntityId + "&isEdit=false" + "&isFrom=search";
    }
    return url;
  };
  customStyles = {

    rows: {
      style: {
        minHeight: '60px',
        fontSize: '14px',
        wordBreak: 'break-all'
      },
    },
    cells: {
      style: {
        '&:not(:last-of-type)': {
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          borderRightColor: "rgba(0,0,0,.12)"
        },
      },
    },
  };
  //will create data table and render on page 
  getSearchTableData = () => {
    try {
      return (
        <DataTableExtensions
          columns={this.state.gridColumns}
          data={this.state.serachData}
          exportHeaders={true}
          export={false}
          print={false}
          filter={false}
        >
          <DataTable
            defaultSortField="id"
            defaultSortAsc={false}
            paginationPerPage={20}
            pagination
            highlightOnHover
            sort
            paginationRowsPerPageOptions={[20, 50, 100, 200]}
            customStyles={this.customStyles}
          />
        </DataTableExtensions>
      );
    } catch (error) {
      console.log(error);
    }
  };

  //this method will format the date to date string mm/dd/yyyy
  formattedDate = (d) => {
    if (d !== "" && d !== undefined) {
      let month = String(d.getMonth() + 1);
      let day = String(d.getDate());
      const year = String(d.getFullYear());

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return `${year}/${month}/${day}`;
    } else {
      return "";
    }
  };

  //this method will format the date to date string mm/dd/yyyy
  formattedDateExcelName = (d) => {
    if (d !== "" && d !== undefined) {
      let month = String(d.getMonth() + 1);
      let day = String(d.getDate());
      const year = String(d.getFullYear());

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return `${year}-${month}-${day}`;
    } else {
      return "";
    }
  };

  //this method will get Allocation codes data and bind to required sesssion Attributes
  getPdAllocationCode = () => {
    try {
      axios
        .get(config.baseURL + "getAllocationCodeList")
        .then((response) => {
          console.log(response);
          this.setState({ allocationCodeList: response.data });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };


  //in this method ae are setting value for allocation Drop down based on session attribute
  setAllocationCode_values = () => {
    try {
      return this.state.allocationCodeList.filter(
        (e) => e.label === this.state.allocationCode
      );
    } catch (error) {
      console.log(error);
    }
  };

  //in this method ae are setting value for service Drop down based on session attribute
  setL1Code_values = () => {
    try {
      return this.state.ServiceLineList.filter(
        (e) => e.label === this.state.ServiceLine
      );
    } catch (error) {
      console.log(error);
    }
  };

  //in this method ae are setting value for service line Drop down based on session attribute
  setL2Code_values = () => {
    try {
      return this.state.ServiceList.filter(
        (e) => e.label === this.state.Service
      );
    } catch (error) {
      console.log(error);
    }
  };

  //in this method ae are setting value for software Drop down based on session attribute
  setSoftware_values = () => {
    try {
      return this.state.softwareNames.filter(
        (e) => e.label === this.state.software
      );
    } catch (error) {
      console.log(error);
    }
  };

  //it will fetch controls based on Condition
  getControl = (ctrl, ctrlId, column) => {
    if (
      column === "Posting Date" ||
      column === "Created On" ||
      column === "Price Date" ||
      column === "Document Date"
    ) {
      return (
        <DatePicker
          className="form-control custom-form-control my-icon mb-2 mr-sm-4"
          placeholderText="mm/dd/yyyy"
          selected={ctrl}
          onChange={(e) => this.handleChangeValue(e, "txt", ctrlId)}
          autoComplete="off"
          onKeyDown={(e) => {
            this.keyDownEvent(e);
          }}
          dateFormat="MM/dd/yyyy"
          maxDate={moment().toDate()}
          minDate={subMonths(new Date(), 24)}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          ref={(c) => (this._startCalendar = c)}
          id="dpStartDate"
        />
      );
    } else if (column === "Is Splitted") {
      return (
        <input
          type="checkbox"
          checked={this.state.isSplitted}
          class="form-control"
          style={{ width: "25px" }}
          onChange={(e) => this.handleChangeValue(e, "chk", ctrlId)}
        />
      );
    } else if (
      column === "Value in Object Currency" ||
      column === "Value in Controlling Area Currency" ||
      column === "Net Order Value" ||
      column === "Gross Order Value" ||
      column === "Performance $'s" ||
      column === "Effective Value" ||
      column === "Still To be Delivered Val" ||
      column === "Order Quantity" ||
      column === "Still To be Delivered Qty" ||
      column === "Net Price"
    ) {
      return (
        <input
          type="number"
          id={ctrlId}
          onChange={(e) => this.handleChangeValue(e, "num", ctrlId)}
          value={ctrl}
          onKeyDown={(e) => {
            this.onKeyDown(e);
          }}
          class="form-control"
        />
      );
    } else if (column === "Amortization/ Depreciation") {
      return (
        <Select
          id={"ADdd"}
          value={this.setADvalues()}
          options={this.state.ADTransactions}
          onChange={(e) => this.handleChangeValue(e.value, "select", ctrlId)}
        />
      );
    } else if (column === "Allocation Code") {
      return (
        <Select
          id={"Alocdd"}
          options={this.state.allocationCodeList}
          value={this.setAllocationCode_values()}
          onChange={(e) => {
            this.handleChangeValue(e.label, "selectA", ctrlId);
          }}
        />
      );
    } else if (column === "Service Line(L1)") {
      return (
        <Select
          id={"Sldd"}
          options={this.state.ServiceLineList}
          value={this.setL1Code_values()}
          onChange={(e) => {
            this.handleChangeValue(e.label, "selectL1", ctrlId);
          }}
        />
      );
    } else if (column === "Service (L2)") {
      return (
        <Select
          id={"sdd"}
          isLoading={this.state.isddlLoading}
          options={this.state.ServiceList}
          value={this.setL2Code_values()}
          onChange={(e) => {
            this.handleChangeValue(e.label, "selectL2", ctrlId);
          }}
        />
      );
    } else if (column === "Software Name") {
      return (
        <Select
          id={"swdd"}
          options={this.state.softwareNames}
          value={this.setSoftware_values()}
          onChange={(e) => {
            this.handleChangeValue(e.label, "selectSW", ctrlId);
          }}
        />
      );
    } else if (column === "MGC Attribute") {
      return (
        <Select
          id={"MGCAttrName"}
          value={this.setAtributeNamevalues()}
          options={this.state.MGCAttributes}
          onChange={(e) => this.handleChangeValue(e.value, "selectMGC", ctrlId)}
        />
      );
    } else if (ctrlId === "txt5") {
      return (
        <input
          type="text"
          id={ctrlId}
          onChange={(e) => this.handleChangeValue(e, "txt", ctrlId)}
          value={ctrl}
          class="form-control"
          style={{ width: "50%" }}
        />
      );
    } else {
      return (
        <input
          type="text"
          id={ctrlId}
          onChange={(e) => this.handleChangeValue(e, "txt", ctrlId)}
          value={ctrl}
          class="form-control"
        />
      );
    }
  };
  //in this method ae are setting value for Transaction type Drop down based on session attribute
  setADvalues = () => {
    try {
      return this.state.ADTransactions.filter(
        (e) => e.value === this.state.ADCostElement
      );
    } catch (error) {
      console.log(error);
    }
  };
  //in this method ae are setting value for Attribute Drop down based on session attribute
  setAtributeNamevalues = () => {
    try {
      return this.state.MGCAttributes.filter(
        (e) => e.value === this.state.attributename
      );
    } catch (error) {
      console.log(error);
    }
  };

  onKeyDown = (event) => {
    var ASCIICode = event.which ? event.which : event.keyCode;

    // ASCIICode === 189 ||
    if (ASCIICode === 187 || ASCIICode === 101 || ASCIICode === 69) {
      event.preventDefault();
      return false;
    }
    return true;
  };
  //it will handle people picker event and sets the value
  onSearchHandleToLoadOptions = (query) => {
    axios.get(config.baseURL + "getUsers/" + query).then((response) => {

      const options = response.data.map((i) => ({
        id: i.mail,
        displayName: i.displayName,
      }));

      this.setState({ options: options });
    });
  };

  //it will handle people picker event and sets the value
  handlePeoplePickerValue = (e, index, mdfyref) => {


    if (index !== undefined && index === "splitBy" && e.length > 0) {
      this.setState({ Splitobject: [e[0]] });
      setTimeout(() => {
        this.setState({
          [index]: e[0] !== undefined && e[0] !== null ? e[0].id : "",
        });
      }, 200);
      mdfyref.current.blur();
    } else if (index !== undefined && index === "splitBy" && e.length === 0) {
      this.setState({ Splitobject: [] });
      setTimeout(() => {
        this.setState({ [index]: "" });
      }, 200);
    }

    // if(e.length>0)
    // {
    //    var obj={
    //     id:(e!=undefined && e!=null)? e[0].id :"",
    //     displayName:(e!=undefined && e!=null)? e[0].displayName :"",
    //   }
    //   splitarr.push(obj)
    //   this.setState({ Splitobject:splitarr});
    //   mdfyref.current.blur();
    // }
    // else{
    //   var obj={
    //     id:"",
    //     displayName:"",
    //   }
    //   splitarr.push(obj)
    //   this.setState({ Splitobject:splitarr});

    // }
  };

  resetSelectedFiles(e) {
    e.target.value = null;
  }
  //method will convert file to base 64 string
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  closeSubmittedPopup() {
    try {
      document.getElementById("submittedPopup").style.display = "none";
      document.getElementById("div-overlay").style.display = "none";
      this.setState({ isLoading: true });
      if (this.SearchInnerFunction()) {
        this.setState({ isLoading: true });
      }
    } catch (error) {
      console.log(error);
    }
  }

  //this method validate the form based on business functionalities
  validateExtension(inputFile) {
    const validFileExtensions = ["xls", "xlsx"];
    if (
      validFileExtensions.includes(
        inputFile.name.split(".").pop().toLowerCase()
      )
    ) {
      return true;
    }
    else {
      return false;
    }
  }
  //this method will import excel file from UI and update the data to db bycalling API
  uploadAttachment(e, el) {
    const files = e.target.files;
    console.log(files)
    var obj = {};
    for (let i = 0; i < files.length; i++) {
      let file = e.target.files[i];
      let fileName = file.name;
      let fileType = file.type;
      if (this.validateExtension(file)) {
        this.getBase64(file).then((data) => {
          obj = {
            attachmentid: 0,
            documentname: fileName,
            documenttype: "searchPOAttributeList",
            documenturl: "",
            documentformat: fileType,
            fileContent: data.split(";base64,")[1],
            isdeleted: false,
            Username: this.getName()
          };
          console.log(obj, "bbbbbjjj")
          this.setState({ selectedFiles: obj });
          this.setState({ isLoading: true });
          axios
            .post(config.baseURL + "searchPOImport", obj)
            .then((response) => {
              // handle success
              var res = response.data;
              console.log(response.data, "validation")
              this.setState({ isLoading: false });
              console.log(res[0].ErrorMessage, "validterespo")


              if (res.length > 0) {
                var msgTxt = "";
                const myArr = res[0].ErrorMessage.split(";");

                switch (myArr[0]) {
                  case "ERRATTR100":
                    msgTxt = messages.ERRATTR100;
                    break;
                  case "ERRATTR101":
                    msgTxt = messages.ERRATTR101;
                    break;
                  case "ERRATTR102":
                    msgTxt = messages.ERRATTR102;
                    break;
                  case "ERRMGC103":
                    msgTxt = messages.ERRMGC103;
                    break;
                  case "ERRMGC104":
                    msgTxt = messages.ERRMGC104 + myArr[1] + " - unable to import";
                    break;
                  case "ERRMGC105":
                    msgTxt = messages.ERRMGC105;
                    break;
                  case "ERRMGC106":
                    msgTxt = messages.ERRMGC106;
                    break;
                  case "Validations Passed":
                    document.getElementById("submittedPopup").style.display = "block";
                    document.getElementById("div-overlay").style.display = "block";
                    break;
                  default:
                    msgTxt = res[0].ErrorMessage;
                    break;

                }
                if (res[0].ErrorMessage !== "Validations Passed") {
                  console.log(res[0].ErrorMessage, "loggg");
                  var errorMsg = res[0].ErrorMessage.trim().replace(/^\|/, '').trim();
                  this.setState({ errorMessage: errorMsg })
                  this.setState({exceptionMessageContent:res[0].ExceptionMessageContent});
                  this.setState({ showConfirmation: true })

                }
              }
            })
            .catch((error) => {
              // handle error

              this.setState({ isLoading: false });
            });
        });
      }
      else {
        toast.error(messages.invalidExcel, {
          autoClose: 10000,
          tapToDismiss: false,
          onclick: false,
          closeOnHover: false,
          //pauseOnFocusLoss: false,
          // autoClose: 10000,
        });
      }

    }

  }

  updateState = (stateObj) => {
    this.setState(stateObj);
  };

  render() {
    return (
      <LoadingOverlay active={this.state.isLoading} spinner>
        {this.state.isLoading === true ? (
          ""
        ) : (
          <div class="body-support-block">
            <NavBarComponent updateState={this.updateState} activeTab = 'SearchPo'/>
            <div class="tabcontent1">
              <div class="container-fluid">
                <div class="row">
                  <div class="cardsContainer">
                    <div class="responsive-table ClsSearchList">
                      <div class="ClstableList">
                        {" "}
                        <label for="lname">PO/Reference Id</label>
                        <input
                          type="text"
                          onChange={(e) =>
                            this.handleChangeValue(e, "txt", "poId")
                          }
                          value={this.state.poId}
                          class="form-control"
                        />
                      </div>
                      <div class="ClstableList">
                        {" "}
                        <label for="lname">Vendor Name</label>{" "}
                        <input
                          type="text"
                          onChange={(e) =>
                            this.handleChangeValue(e, "txt", "supName")
                          }
                          value={this.state.supName}
                          class="form-control"
                        />{" "}
                      </div>
                      <div class="ClstableList">
                        {" "}
                        <label for="lname">Cost Element Name</label>{" "}
                        <input
                          type="text"
                          onChange={(e) =>
                            this.handleChangeValue(e, "txt", "CstElmntName")
                          }
                          value={this.state.CstElmntName}
                          class="form-control"
                        />{" "}
                      </div>
                      <div class="ClstableList">
                        {" "}
                        <label for="lname">Modified By</label>{" "}
                        <AsyncTypeahead
                          id="Splitby"
                          ref={this.mdfy}
                          isLoading={false}
                          labelKey="displayName"
                          minLength={2}
                          selected={this.state.Splitobject}
                          onChange={(e) =>
                            this.handlePeoplePickerValue(
                              e,
                              "splitBy",
                              this.mdfy
                            )
                          }
                          onSearch={this.onSearchHandleToLoadOptions}
                          options={this.state.options}
                          placeholder="Search for a User"
                          searchText="Searching....."
                          renderMenuItemChildren={(option) => (
                            <Fragment>
                              <span>{option.displayName}</span>
                            </Fragment>
                          )}
                        />
                        {/* <input
                          type="text"
                          onChange={(e) =>
                            this.handleChangeValue(e, "txt", "splitBy")
                          }
                          value={this.state.poId}
                          value={this.state.splitBy}
                          class="form-control"
                        />{" "} */}
                      </div>
                      <div class="ClstableList">
                        {" "}
                        <label for="lname">
                          TBM Change Date From<span class="required"></span>
                        </label>
                        <DatePicker
                          className="form-control  my-icon mb-2 mr-sm-4"
                          placeholderText="mm/dd/yyyy"
                          selected={this.state.fromDate}
                          onChange={(e) =>
                            this.handleChangeValue(e, "date", "fromDate")
                          }
                          autoComplete="off"
                          onKeyDown={(e) => {
                            this.keyDownEvent(e);
                          }}
                          dateFormat="MM/dd/yyyy"
                          maxDate={moment().toDate()}
                          minDate={subMonths(new Date(), 24)}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          ref={(c) => (this._startCalendar = c)}
                          id="dpStartDate"
                        />
                      </div>
                      <div class="ClstableList">
                        {" "}
                        <label for="lname">
                          TBM Change Date To<span class="required"></span>
                        </label>
                        <DatePicker
                          className="form-control  my-icon mb-2 mr-sm-4"
                          placeholderText="mm/dd/yyyy"
                          selected={this.state.toDate}
                          onChange={(e) =>
                            this.handleChangeValue(e, "date", "toDate")
                          }
                          autoComplete="off"
                          onKeyDown={(e) => {
                            this.keyDownEvent(e);
                          }}
                          dateFormat="MM/dd/yyyy"
                          maxDate={moment().toDate()}
                          minDate={subMonths(new Date(), 24)}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          ref={(c) => (this._startCalendar = c)}
                          id="dpStartDate"
                        />
                      </div>
                    </div>

                    <div
                      className="divddl"
                      style={{ float: "left", width: "100%" }}
                    >
                      <div
                        className={
                          this.state.column0Show
                            ? "ClstableList"
                            : "ClstableListhide"
                        }
                      >
                        {" "}
                        <label for="lname">{this.state.columns[0]}</label>
                        {this.getControl(
                          this.state.txt1,
                          "txt1",
                          this.state.columns[0]
                        )}
                      </div>
                      <div
                        className={
                          this.state.column1Show
                            ? "ClstableList"
                            : "ClstableListhide"
                        }
                      >
                        {" "}
                        <label for="lname">{this.state.columns[1]}</label>
                        {this.getControl(
                          this.state.txt2,
                          "txt2",
                          this.state.columns[1]
                        )}
                      </div>
                      <div
                        className={
                          this.state.column2Show
                            ? "ClstableList"
                            : "ClstableListhide"
                        }
                      >
                        {" "}
                        <label for="lname">{this.state.columns[2]}</label>
                        {this.getControl(
                          this.state.txt3,
                          "txt3",
                          this.state.columns[2]
                        )}
                      </div>
                      <div
                        className={
                          this.state.column3Show
                            ? "ClstableList"
                            : "ClstableListhide"
                        }
                      >
                        {" "}
                        <label for="lname">{this.state.columns[3]}</label>
                        {this.getControl(
                          this.state.txt4,
                          "txt4",
                          this.state.columns[3]
                        )}
                      </div>
                      <div
                        className={
                          this.state.ShowAttributeVale
                            ? "ClstableList"
                            : "ClstableListhide"
                        }
                      >
                        {" "}
                        <label for="lname">Attribute Value</label>
                        {this.getControl(
                          this.state.txt5,
                          "txt5",
                          "Attribute Value"
                        )}
                      </div>
                    </div>

                    <div
                      style={{ marginRight: "0px" }}
                      className={
                        this.state.setmarginTop
                          ? "searchbuttonmargin"
                          : "ClsSearchPOsButton"
                      }
                    >
                      <button
                        class="Clsbutton ClsSearch"
                        onClick={() => this.openPopUp()}
                      >
                        Add Columns
                      </button>
                      <button
                        class="Clsbutton ClsSearch"
                        onClick={() => this.Search()}
                      >
                        Search
                      </button>
                      <button
                        class="Clsbutton ClsReset"
                        onClick={() => this.RestControls()}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
                <div className={this.state.showTable ? "row mt-10" : "hidden"}>
                  <div class="cardsContainer">
                    <div className="responsive-table tableSearchPlacement Searchamount">
                      <div class="ClsSearchPOsButton">
                        <span className="input-group-btn left0">
                          <button
                            className="btn btn-primary btn-file btn-inner Clsbutton ClsSearch"
                            id="importbtn"
                          >
                            Import  Excel{" "}
                            <input
                              type="file"
                              name="importFiles"
                              id="importAttrinput"
                              data-test="importAttrinput1"
                              title=" "
                              onClick={(e) => this.resetSelectedFiles(e)}
                              onChange={(e) => this.uploadAttachment(e, this)}
                              accept=".xlsx"
                            />
                          </button>
                        </span>
                        <button class="Clsbutton ClsSearch" onClick={() => this.getSearchDatatoExport(this.state.SearchObj)}>
                          Export Excel
                        </button>
                        {/* <ExcelFile
                          filename={
                            "TBM Search_PO_Results_" +
                            this.formattedDateExcelName(new Date())
                          }
                          element={
                            <button class="Clsbutton ClsSearch">
                              Export Excel
                            </button>
                          }
                        >
                          <ExcelSheet
                            data={this.state.serachData}
                            name="Search Results"
                          >
                            {this.state.excelColumns.map((value, index) => {
                              return (
                                <ExcelColumn
                                  label={value.name}
                                  value={value.selector}
                                />
                              );
                            })}
                          </ExcelSheet>
                        </ExcelFile> */}
                      </div>
                      {this.getSearchTableData()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal show={this.state.show} className="searchModel">
              <Modal.Header style={{ backgroundColor: "#004267" }}>
                <Modal.Title style={{ color: "#fff" }}>Add Columns</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div class="addColumnsMain">
                  <div class="searchColumn">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search"
                      onChange={(e) => this.handleSearchChange(e)}
                    />{" "}
                    <button class="btn btn-primary">
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </div>
                  <div class="columnsChkBoxes">
                    <ul>
                      {this.state.filteredList.map((value, index) => {
                        return (
                          <li>
                            <input
                              id={"chk" + index}
                              type="checkbox"
                              checked={value.checked}
                              name={value.ColumnName}
                              onChange={(e) =>
                                this.handleCheckboxListChange(
                                  "chk" + index,
                                  value.ColumnName,
                                  index
                                )
                              }
                            />
                            <label>{value.label}</label>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  onClick={() => this.closePopupOnAdd()}
                >
                  ADD
                </Button>
                <Button variant="secondary" onClick={() => this.closePopup()}>
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
            <div className="modal show" id="submittedPopup">
              <div class="modal-dialog modal-success" role="document">
                <div class="modal-content">
                  <div class="modal-body">
                    <div class="successMessageMain">
                      <img src={popupIcon} alt="popup" />
                      <h4>Successfully Imported!</h4>
                      <button
                        type="button"
                        class="btn btn-primary"
                        onClick={() => this.closeSubmittedPopup()}
                      >
                        Done
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.state.showConfirmation ? (
              <div className="modalhow" id="errorPopup">

                <Confirmation
                  showConfirmation={this.state.showConfirmation}
                  ModalContent={this.state.exceptionMessageContent}
                  handleClose={() => {

                    this.setState({ showConfirmation: false })
                  }}
                  handleConfirm={() => {
                    navigator.clipboard.writeText(this.state.errorMessage)
                    // this.setState({showConfirmation:false})
                  }}
                  primaryButtonText="Copy"
                  secondaryButtonText="Cancel"
                />

              </div>
            ) : ""}
          </div>
        )}
      </LoadingOverlay>
    );
  }
}
