import React, { Component } from "react";
import * as config from "../../config";
import * as messages from "../../messages.js";
import axios from "../../api.js";
import Select from "react-select";
import RemoveIcon from "../SplitComponent/Delete-icon.svg";
import popupIcon from "../SplitComponent/tick-icon.png";
import AddIcon from "../SplitComponent/add.svg";
import warningIcon from "../MCGL2MappingComponent/Warning.png";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { msalInstance } from "../../authConfig";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { fr } from "date-fns/locale";

toast.configure();
export default class EditMCG extends Component {
  constructor() {
    super();
    this.state = {
      mgcId: 0,
      isLoading: true,
      isddlLoading: false,
      l1Code: "",
      l1CodeID: "",
      l2Code: "",
      l2CodeID: "",
      mgcCode: "",
      mgcdescription: "",
      L2CodeList: [],
      L1Codes: [],
      comments: "",
      attributes: [],
      attributesBackup:[],
      allocationCodeId: "",
      allocationCode: "",
      allocationCodeList:[],
      isL2Loading: false,
      commentsArray: [],
      isAttributeSaved:false
    };

    this.handleL1codeChange = this.handleL1codeChange.bind(this);
    let id = new URLSearchParams(window.location.search).get("id");
    this.bindData(id);
  }
// this will get name for the loggedin user
  getName = () => {
    try {
      var email =
        msalInstance.getAllAccounts()[0] !== null
          ? msalInstance.getAllAccounts()[0].username
          : "";
      return email;
    } catch (error) {
      console.log(error);
    }
  };
//this method will call api and fetch data from db regarding MGC based in MGC code
  bindData = async (mgcCode) => {
    try {
      axios
        .get(config.baseURL + "getMCGL2MappingById/" + mgcCode)
        .then((response) => {
          this.setState({ mgcId: mgcCode });
          this.setState({ l1Code: response.data[0].ServiceLineL1 });
          this.setState({ l2Code: response.data[0].ServiceL2 });
          //this.setState({ l1CodeID: response.data[0].ServiceLineId });
          //this.setState({ l2CodeID: response.data[0].ServiceId });
          this.setState({ mgcCode: response.data[0].MgcCode });
          //this.setState({ comments: response.data[0].Comments });
          this.setState({ allocationCode: response.data[0].AllocationCode });
          this.setState({ mgcdescription: response.data[0].MGCDescription });
          this.setState({
            allocationCodeId: response.data[0].AllocationTypeId,
          });
          this.getPdAllocationCode();
          this.bindL1Codes();
          this.bindAttributesList(mgcCode,"binddata");
          this.bindCommentsHistory(mgcCode,0);
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isLoading: false });
        });
    } catch (error) {
      console.log(error);
    }
  };
//this method will get the comments hisory and bind to required session Attrubutes
  bindCommentsHistory = (PoNumber,lineNumber) => {
    try {
      var obj = {
        PoNumber: PoNumber,
        LineId: lineNumber,
        EntityId:PoNumber
      };
      axios
        .post(config.baseURL + "getCommentsHistory",obj)
        .then((response) => {
          
          this.setState({ commentsArray: response.data });
          this.setState({ isLoading: false });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isLoading: false });
        });
    } catch (error) {
      console.log(error);
    }
  };

  
//this method will get Service data and bind to required sesssion Attributes
  bindL1Codes = () => {
    try {
      axios
        .get(config.baseURL + "getL1Codes")
        .then((response) => {
          
          this.setState({ L1Codes: response.data });
          this.bindL2Codes(this.state.l1Code);
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isLoading: false });
        });
    } catch (error) {
      console.log(error);
    }
  };

  //this method will get Service Line data and bind to required sesssion Attributes
  bindL2Codes = (l1Code) => {
    try {
      axios
        .get(config.baseURL + "getL2Codes/" + l1Code)
        .then((response) => {
          this.setState({ L2CodeList: response.data });
          //this.getPdAllocationCode();
          this.setState({ isddlLoading: false });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isddlLoading: false });
        });
    } catch (error) {
      console.log(error);
    }
  };

  //this method will get Attribute   data and bind to required sesssion Attributes
  bindAttributesList = async (mgcCode,frm) => {
    try {
      axios
        .get(config.baseURL + "getMCGAttributes/" + mgcCode)
        .then((response) => {
          this.setState({ attributes: response.data,attributesBackup: response.data });
          if(frm==="attrsave")
          {
            this.setState({ isLoading: false });
            document.getElementById("submittedPopup").style.display = "block";
            document.getElementById("div-overlay").style.display = "block";
          }
          else if(frm==="cancel")
          {
            this.setState({ isLoading: false });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isLoading: false });
        });
    } catch (error) {
      console.log(error);
      this.setState({ isLoading: false });
    }
  };
//this method will get Allocation code based on mgccode when we select service and service line
  getPdAllocationCode = () => {
    try {
      axios
        .get(config.baseURL + "getAllocationCodeList")
        .then((response) => {
          this.setState({ allocationCodeList: response.data });
          this.setState({ isLoading: false });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  //this method will handle inputChange event called when we enter data and updte the required session attribute
  handleInputValueChange = (e) => {
    try {
      this.setState({ mgcdescription: e.target.value });
    } catch (error) {
      console.log(error);
    }
  };
//this method will handle inputChange event for attribute name  called when we enter data and updte the required session attribute
  handleAttributesName = (e, idx) => {
    try {
      let attributesLst = this.state.attributes.slice();
      attributesLst[idx].AttributeName = e.target.value;
      this.setState({ attributes: attributesLst });
    } catch (error) {
      console.log(error);
    }
  };
//called when you move focus from input controls
  onblur = (e, idx) => {
    try {
      var name = e.target.value.toLowerCase();
      var existingattribute = this.state.attributes.filter(
        (attr) => attr.AttributeName.toLowerCase() === name
      );
      let attributesLst = this.state.attributes.slice();
      if (existingattribute.length > 0) {
        toast.error(messages.attributeNameExists, {
          autoClose: 10000,
        });
        attributesLst[idx].AttributeName = "";
      } else {
        attributesLst[idx].AttributeName = e.target.value;
      }
      this.setState({ attributes: attributesLst });
    } catch (error) {
      console.log(error);
    }
  };
//this method will handle inputChange event for attribute name  called when we enter data and updte the required session attribute
  handleAttributesValue = (e, idx) => {
    try {
      let attributesLst = this.state.attributes.slice();
      attributesLst[idx].AttributeValue = e.target.value;
      this.setState({ attributes: attributesLst });
    } catch (error) {
      console.log(error);
    }
  };
//this method will handle inputCommentChange event called when we enter data in to comments and updte the required session attribute
  handleCommentsChange = (e) => {
    try {
      this.setState({ comments: e.target.value });
    } catch (error) {
      console.log(error);
    }
  };
//this method will get Service data and bind to required sesssion Attributes
  handleL1codeChange = (l1value, l1label) => {
    try {
      this.setState({ l2Code: "" });
      this.setState({ l1CodeID: l1value });
      this.setState({ l1Code: l1label });
      this.setState({ isddlLoading: true });
      this.bindL2Codes(l1value);
    } catch (error) {
      console.log(error);
    }
  };
//this method will handle Service Change event called when we select data and updte the required session attribute
  handleL2codeChange = (l2value, l2label) => {
    try {
      this.setState({ l2CodeID: l2value });
      this.setState({ l2Code: l2label });
      this.setState({ isLoading: true });
      var obj = {
        l2code: l2label,
        mgcCode: this.state.mgcCode,
      };
      axios
        .post(config.baseURL + "checkL2Exists", obj)
        .then((response) => {
          if (response.data.length <= 0) {
            this.setState({ isddlLoading: false });
            this.getPdAllocationCode();
          } else {
            this.setState({ l2CodeID: "" });
            this.setState({ l2Code: "" });
            toast.error(
              messages.l2alreadyExists +
                " for MGC Code " +
                response.data[0].MGCCode,
              {
                autoClose: 10000,
              }
            );
          }
          this.setState({ isLoading: false });
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          this.setState({ isddlLoading: false });
          toast.error("Failed", {
            autoClose: 10000,
          });
        });
    } catch (error) {
      this.setState({ isLoading: false });
      this.setState({ isddlLoading: false });
      console.log(error);
    }
  };
//this method will handle Allocation code Change event called when we select data and updte the required session attribute
  handleAllocationcodeChange = (allocationValue, allocationCodeId) => {
    try {
      this.setState({ allocationCode: allocationValue });
      this.setState({ allocationCodeId: allocationCodeId });
    } catch (error) {
      console.log(error);
    }
  };
//this method will add new row to attribute list
  handleAdd = () => {
    try {
      let array = this.state.attributes;
      array.unshift({
        MCGAttributeId: "",
        MgcDetailsId: "",
        AttributeId: 0,
        AttributeName: "",
        AttributeValue: "",
        isdisabled: false,
        isdeleted: 0,
      });
      this.setState({ attributes: array });
    } catch (error) {
      console.log(error);
    }
  };
//this method will remove row from attribute list
  handleRemove = (idx) => {
    try {
      let attributesLst = this.state.attributes.slice();
      if (attributesLst[idx].AttributeName !=="") {
        var removeAttrMsg =
          "You are about to delete the attribute " +
          attributesLst[idx].AttributeName +
          ". This will affect all MGC codes in TBM. Are you sure you want to delete it?";
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="conformDiv">
                <div class="WarningMessageMain">
                
                <img src={warningIcon} alt="warning"/>
                <h4>Warning!</h4>
                </div>
                <p className="conformBody">{removeAttrMsg}</p>
                <div className="ClsSearchPOsButton">
                  <button
                    onClick={() => {
                      attributesLst[idx].isdeleted = 1;
                      this.setState({ attributes: attributesLst });
                      onClose();
                    }}
                    className="Clsbutton ClsSearch"
                  >
                    Yes
                  </button>
                  <button onClick={onClose} className="Clsbutton ClsReset">
                    No
                  </button>
                </div>
              </div>
            );
          },
        });
      } else {
        attributesLst.splice(idx, 1);
        this.setState({ attributes: attributesLst });
      }
    } catch (error) {}
  };
//this method will call api to save data from UI to DB
  submitData = () => {
    try {
      if (this.validateForm()) {
        
        const insertObject = {};
        insertObject.mgcId = this.state.mgcId;
        insertObject.mgcCode = this.state.mgcCode;
        insertObject.mgcdescription = this.state.mgcdescription
          .trimEnd()
          .trimStart();
        insertObject.serviceLine = this.state.l1Code;
        insertObject.screenName = "EditMCG";
        insertObject.serviceLineId = this.state.l1CodeID;
        insertObject.service = this.state.l2Code;
        insertObject.serviceId = this.state.l2CodeID;
        insertObject.allocationCode = this.state.allocationCodeId;
        insertObject.comments = this.state.comments.trimEnd().trimStart();
        insertObject.createdBy = this.getName();
        this.setState({ isLoading: true });
        axios
          .post(config.baseURL + "saveMcgToL2Mapping", insertObject)
          .then((response) => {
            // handle success
            this.setState({ isAttributeSaved: false });
            this.setState({ isLoading: false });
            document.getElementById("submittedPopup").style.display = "block";
            document.getElementById("div-overlay").style.display = "block";
          })
          .catch((error) => {
            // handle error
            this.setState({ isLoading: false });
            toast.error("Submit Failed", {
              autoClose: 10000,
            });
          });
      }
    } catch (error) {
      console.log(error);
    }
  };
//this method will call api to save data from UI to DB
  submitAttributeData = () => {
    try {
      if (this.validateAttributeList()) {
        
        const insertObject = {};
        insertObject.mgcCode = this.state.mgcCode;
        insertObject.createdBy = this.getName();
        var attrArray = [];
        var i = 0;
        for (i = 0; i < this.state.attributes.length; i++) {
          var obj = this.state.attributes[i];
          obj.AttributeName = obj.AttributeName.trimEnd().trimStart();
          obj.AttributeValue =
            obj.AttributeValue !== null && obj.AttributeValue !== undefined
              ? obj.AttributeValue.trimEnd().trimStart()
              : "";
          attrArray.push(obj);
        }
        insertObject.attributeList = attrArray;
        this.setState({ isLoading: true });
        axios
          .post(config.baseURL + "saveMcgAttributes", insertObject)
          .then((response) => {
            // handle success
            this.setState({ isAttributeSaved: true });
            this.bindAttributesList(this.state.mgcCode,"attrsave")
            
          })
          .catch((error) => {
            // handle error
            this.setState({ isLoading: false });
            toast.error("Submit Failed", {
              autoClose: 10000,
            });
          });
      }
    } catch (error) {
      console.log(error);
    }
  };
//this method validate the form based on business functionalities
  validateAttributeList = () => {
    try {
      var result = true;
      let i = 0;
        let array = this.state.attributes;
        var attrname="";
        for (i = 0; i < array.length; i++) {
          attrname = array[i].AttributeName.trimEnd().trimStart();
          if (attrname === "" || attrname === undefined) {
            toast.error(messages.validationMesageAttributes, {
              autoClose: 10000,
            });
            result = false;
            break;
          } else {
            var notdeletedArray = array.filter((attr) => attr.isdeleted === 0);
            var existingattribute = notdeletedArray.filter(
              (attr) =>
                attr.AttributeName.trimEnd()
                  .trimStart()
                  .toLowerCase() ===
                attrname.trimEnd().trimStart().toLowerCase()
            );
            let attributesLst = this.state.attributes.slice();
            if (existingattribute.length > 1) {
              toast.error(messages.attributeNameExists, {
                autoClose: 10000,
              });
              attributesLst[i].AttributeName = "";
              this.setState({ attributes: attributesLst });
              result = false;
              break;
            }
          }
        }
      
      return result;
    } catch (error) {
      console.log(error);
    }
  };

//this method handles functionlity when you close the submit popup
  closeSubmittedPopup() {
    try {
      document.getElementById("submittedPopup").style.display = "none";
      document.getElementById("div-overlay").style.display = "none";
      if(this.state.isAttributeSaved==false)
      {
        window.location.href = "/MGCList";
      }
      

      
    } catch (error) {
      console.log(error);
    }
  }
//this method validate the form based on business functionalities
  validateForm = () => {
    try {
      var result = true;

      if (this.state.mgcdescription===null||this.state.mgcdescription.trimEnd().trimStart() === "") {
        result = false;
      }
      if (this.state.comments.trimEnd().trimStart() === "") {
        result = false;
      }
      if (this.state.l1Code === "") {
        result = false;
      }
      if (this.state.l2Code === "") {
        result = false;
      }
      if (this.state.allocationCodeId === "" || this.state.allocationCodeId === null || this.state.allocationCodeId === undefined) {
        result = false;
      }
      if (result) {
        return result;
      } else {
        toast.error(messages.validationMesage, {
          autoClose: 10000,
        });
      }
  } catch (error) {
      console.log(error);
    }
  };

 
//halde cancel click in the page
  cancelClick = () => {
    try {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="conformDiv">
              <p className="conformBody">
                If you cancel then your changes will be lost. Are you sure you
                want to cancel?
              </p>
              <div className="ClsSearchPOsButton">
                <button
                  onClick={() => {
                    window.location.href = "/MGCList";
                  }}
                  className="Clsbutton ClsSearch"
                >
                  Yes
                </button>
                <button onClick={onClose} className="Clsbutton ClsReset">
                  No
                </button>
              </div>
            </div>
          );
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
//halde cancel for attributes click in the page
  cancelClickAttributes = () => {
    try {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="conformDiv">
              <p className="conformBody">
                If you cancel then your changes will be lost. Are you sure you
                want to cancel?
              </p>
              <div className="ClsSearchPOsButton">
                <button
                  onClick={() => {
                    onClose();
                    this.setState({isLoading:true})
                    this.bindAttributesList(this.state.mgcCode,"cancel")
    
                  }}
                  className="Clsbutton ClsSearch"
                >
                  Yes
                </button>
                <button onClick={onClose} className="Clsbutton ClsReset">
                  No
                </button>
              </div>
            </div>
          );
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
//in this method ae are setting value for service  Drop down based on session attribute
  setl1_values = () => {
    try {
      return this.state.L1Codes.filter((e) => e.label === this.state.l1Code);
    } catch (error) {
      console.log(error);
    }
  };
//in this method ae are setting value for service line Drop down based on session attribute
  setl2_values = () => {
    try {
      return this.state.L2CodeList.filter((e) => e.label === this.state.l2Code);
    } catch (error) {
      console.log(error);
    }
  };
//in this method ae are setting value for allcoation code Drop down based on session attribute
  setAllocationCode_values = () => {
    try {
      
      return this.state.allocationCodeList.filter(
        (e) => e.label === this.state.allocationCode
      );
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <div>
        <LoadingOverlay active={this.state.isLoading} spinner>
          {this.state.isLoading === true ? (
            ""
          ) : (
            <div class="body-support-block">
              <div id="Unassigned" class="tabcontent tabcontent1">
                <div class="container-fluid splitAssignContainerFluid">
                  <div class="row">
                    <div class="cardsContainer bodyContentHeight">
                      <div class="responsive-table">
                        <div class="ClsSplitLeft" style={{width:"65%"}}>
                          <div class="ClsSplitTitle">Edit MGC Code</div>
                          <div class="ClsAssigndivMGC">
                            <div class="ClsEditMGcDiv leftpaneEditmcg">
                              <div class="row">
                                <div class="col-6">
                                  <label for="lname">Parent MGC (TBM)</label>
                                  <input
                                    type="text"
                                    value={this.state.mgcCode}
                                    class="form-control"
                                    disabled
                                  />
                                </div>
                                <div class="col-6">
                                  <label for="lname">
                                  Parent MGC Description
                                    <span class="required"> *</span>
                                  </label>
                                  <input
                                    type="text"
                                    maxLength="200"
                                    value={this.state.mgcdescription}
                                    class="form-control"
                                    onChange={(e) => {
                                      this.handleInputValueChange(e);
                                    }}
                                  />
                                </div>
                              </div>
                              <div class="row rowTwo">
                                <div class="col-4">
                                  <label for="lname">
                                    Service Line<span class="required"> *</span>
                                  </label>
                                  <input
                                    type="text"
                                    value={this.state.l1Code}
                                    class="form-control"
                                    disabled
                                  />
                                  {/* <Select
                                    options={this.state.L1Codes}
                                    value={this.setl1_values()}
                                    disabled
                                    onChange={(e) => {
                                      this.handleL1codeChange(
                                        e != null ? e.value : "",
                                        e != null ? e.label : ""
                                      );
                                    }}
                                  /> */}
                                </div>

                                <div class="col-4">
                                  <label for="lname">
                                    Service<span class="required"> *</span>
                                  </label>
                                  <input
                                    type="text"
                                    value={this.state.l2Code}
                                    class="form-control"
                                    disabled
                                  />
                                  {/* <Select
                                    options={this.state.L2CodeList}
                                    isLoading={this.state.isddlLoading}
                                    disabled
                                    value={this.setl2_values()}
                                    onChange={(e) => {
                                      this.handleL2codeChange(
                                        e != null ? e.value : "",
                                        e != null ? e.label : ""
                                      );
                                    }}
                                  /> */}
                                </div>

                                <div class="col-4">
                                  <label for="lname">
                                    Allocation Code
                                    <span class="required"> *</span>
                                  </label>
                                  <Select
                                  
                                    options={this.state.allocationCodeList}
                                    value={this.setAllocationCode_values()}
                                    onChange={(e) => {
                                      this.handleAllocationcodeChange(
                                        e !== null ? e.label : "",
                                        e !== null ? e.value : ""
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="ClsSplitCommentsArea">
                              <div class="">
                                <label for="lname">
                                  Comments<span class="required"> *</span>
                                </label>
                                <textarea
                                  rows="4"
                                  maxLength="3000"
                                  value={this.state.comments}
                                  cols="50"
                                  name="comment"
                                  form="usrform"
                                  class="form-control"
                                  onChange={(e) => {
                                    this.handleCommentsChange(e);
                                  }}
                                ></textarea>
                              </div>
                            </div>
                            <div>
                              {this.state.commentsArray.map((cmntsObj, idx) => (
                                <div class="commentSingle">
                                  <div class="row">
                                    <div class="col-md-8">
                                      <h4>{cmntsObj.CreatedBy}</h4>
                                      <p class="textBreak">{cmntsObj.Comment}</p>
                                    </div>
                                    <div class="col-md-4">
                                      <p class="commentDate">
                                        {cmntsObj.CreatedDate}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div class="ClsSearchPOsButtonMGC">
                            <button
                              style={{ opacity: this.state.savebtnOpacity }}
                              class="Clsbutton ClsSearch"
                              onClick={() => this.submitData()}
                              disabled={this.state.isSaveDisabled}
                            >
                              Save
                            </button>
                            <button
                              class="Clsbutton ClsReset"
                              style={{marginRight:"10px"}}
                              onClick={() => this.cancelClick()}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                        <div class="ClsSplitRight" style={{width:"35%"}}>
                          <div class="ClsSplitTitle">
                            Attribute
                            <button
                              style={{ float: "right",marginTop:"0px"}}
                              className="Clsbutton ClsAdd"
                              onClick={() => this.handleAdd()}
                            >
                              <img className="addbuttonImage" src={AddIcon} alt="add" />{" "}
                              Add
                            </button>
                          </div>
                          <div class="ClsSplitDivMGC">
                            <table className="table TableTitleHeight">
                              <thead className="attributetbheader">
                                <tr>
                                  <td>
                                    <label for="lname">
                                      Name <span class="required"> *</span>
                                    </label>
                                  </td>
                                  <td>
                                    <label for="lname">Value</label>
                                  </td>
                                  <td>
                                    <label for="lname"> &nbsp;</label>
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.attributes.map((attr, idx) => (
                                  <tr
                                    key={idx}
                                    className={
                                      attr.isdeleted == false
                                        ? "col-12"
                                        : "col-12 hidden"
                                    }
                                    row={idx}
                                  >
                                    <td class="col-5">
                                      <div>
                                        <input
                                          maxLength="100"
                                          
                                          className="amountInput form-control"
                                          value={attr.AttributeName}
                                          onChange={(e) => {
                                            this.handleAttributesName(e, idx);
                                          }}
                                          disabled={attr.isdisabled}
                                        />
                                      </div>
                                    </td>
                                    <td class="col-5">
                                      <div>
                                        <input
                                        maxLength="500"
                                        
                                          className="amountInput form-control"
                                          value={attr.AttributeValue}
                                          onChange={(e) => {
                                            this.handleAttributesValue(e, idx);
                                          }}
                                        />
                                      </div>
                                    </td>
                                    <td className="col-2">
                                      <div>
                                        <button onClick={() =>
                                              this.handleRemove(idx)
                                            } tabindex="0" className="removeSpanMGC">
                                          <img
                                            style={{ width: "24px",marginTop:"2px" }}
                                            src={RemoveIcon}
                                            alt="remove"
                                          />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div class="ClsSearchPOsButtonMGC">
                            <button
                              style={{ opacity: this.state.savebtnOpacity }}
                              class="Clsbutton ClsSearch"
                              onClick={() => this.submitAttributeData()}
                              disabled={this.state.isSaveDisabled}
                            >
                              Save
                            </button>
                            <button
                              class="Clsbutton ClsReset"
                              onClick={() => this.cancelClickAttributes()}
                            >
                              Cancel
                            </button>
                          </div>
                         </div>
                       </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </LoadingOverlay>

        <div className="modal show" id="submittedPopup">
          <div class="modal-dialog modal-success" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div class="successMessageMain">
                  <img src={popupIcon} alt="popup"/>
                  <h4>Successfully Updated!</h4>
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => this.closeSubmittedPopup()}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        
      </div>
    );
  }
}
