import React, { Component } from "react";
import * as config from "../../config";
import * as messages from "../../messages.js";
import axios from "../../api.js";
import popupIcon from "../SplitComponent/tick-icon.png";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { msalInstance } from "../../authConfig";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import moment from "moment";
import {subMonths } from "date-fns";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import ReactTooltip from "react-tooltip";
import commentIcon from "../SearchComponent/info-icon.png";
import { utc } from "moment";
import { textAlign } from "@material-ui/system";
import NavBarComponent from "../NavBarComponent/NavBarComponent.jsx";

toast.configure();

export default class ClosingDate extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
       username:"",
       reason:"",
       closingDate:"",
       data:[],
       filterData:[]
      
      
      
    };
  }
//this method will call when page loads and load data to required controls
  componentDidMount = () => {
    this.getName();
    this.getClosingDates();
  };

  // this will get name for the loggedin user
  getName = () => {
    try {
      var email =
        msalInstance.getAllAccounts()[0] !== null
          ? msalInstance.getAllAccounts()[0].username
          : "";
       this.setState({username:email})
      return email;
    } catch (error) {
      console.log(error);
    }
  };

  //this method validate the form based on business functionalities
  validateForm = () => {
    try {
     var result=true;
      if (this.state.reason.trimEnd().trimStart() === "") {
        result= false;
      }
      if (this.state.closingDate === "" || this.state.closingDate ===null || this.state.closingDate === undefined) {
        result= false;
      }
      if(result)
      {
        if(this.state.data.length>0)
        {
          var recentEntry=this.state.data[0];
          var dateOne1 = new Date(this.state.closingDate);
          var dateTwo1 = new Date(recentEntry.ClosingDate);
          if (dateOne1 <= dateTwo1) {
              this.setState({ toDate: null });
              toast.error(messages.closingDateValidation, {
                autoClose: 10000,
              });
              result=false; 
          }
        }
      }
      else
      {
        toast.error(messages.validationMesage, {
          autoClose: 10000,
        });
      }
      
      
      return result;
    } catch (error) {
      console.log(error);
    }
  };
 
  //handles key down event form calender control
  onKeyDown = (event) => {
    var ASCIICode = event.which ? event.which : event.keyCode;
    
    if (
      ASCIICode === 187 ||
      ASCIICode === 101 ||
      ASCIICode === 69 ||
      ASCIICode === 40 ||
      ASCIICode === 189 ||
      ASCIICode === 38
    ) {
      event.preventDefault();
      return false;
    }
    return true;
  };
//this method will call api to save data from UI to DB
 submitData = () => {
    try {
      if (this.validateForm()) {
        const insertObject = {};
        insertObject.reason = this.state.reason.trimStart().trimEnd();
        insertObject.closingDate = new utc(this.formattedDate(this.state.closingDate));
        insertObject.createdBy = this.getName();
        this.setState({ isLoading: true });
        axios
          .post(config.baseURL + "SaveClosingStoredProc", insertObject)
          .then((response) => {
            // handle success
            this.setState({ isLoading: false });
            
            document.getElementById("submittedPopup").style.display = "block";
            document.getElementById("div-overlay").style.display = "block";
          })
          .catch((error) => {
            // handle error
            this.setState({ isLoading: false });
            toast.error("Submit Failed", {
              autoClose: 10000,
            });
          });
      }
      
    } catch (error) {
      console.log(error);
    }
  };
//this method handles functionlity when you close the submit popup
  closeSubmittedPopup() {
    try {
      document.getElementById("submittedPopup").style.display = "none";
      document.getElementById("div-overlay").style.display = "none";
      window.location.href = "/ClosingDates";
     
    } catch (error) {
      console.log(error);
    }
  }

//this method will fetch list of Closing dates from db by calling API 
  getClosingDates= async ()=>
  {
    
    try
    {
      const getClosingDatesData=async () => {
       
      axios
      .get(config.baseURL + "getClosingDates")
      .then((response) => {
        this.setState({ data: response.data});
        this.setState({ filterData: response.data});
        this.setState({ isLoading: false });   
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
    }
    await getClosingDatesData();
    }
    catch(error)
    {
      console.log(error);
    }
  }

//this method will handle inputChange event called when we enter data and updte the required session attribute
 handleChangeValue=(e,type)=>{
     if(type=="txt")
  this.setState({ reason: e.target.value });
  else
  this.setState({closingDate: e });
 }

 //handles key down event form calender control	
keyDownEvent = (event) => {
    try {
      const key = event.key;
      if (key === "Backspace" || key === "Delete") {
        return true;
      } else {
        event.preventDefault();
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };

//Will define columns for datatable
  columns = [
    {
      name: "User Id",
      selector: "CreatedBy",
      sortable: true,
      width:"200px",
      wrap:true
      
    },
    {
      name: "Reason",
      selector: "ShortReason",
      sortable: true,
      cell: (row) =>(
        
        row.Reason.length > 500 ? (
          <span>
                     {row.ShortReason +"..."}<img
                      data-tip={row.Reason}
                      data-for="colreason"
                      style={{
                        width: "25px",
                        cursor: "pointer",
                        marginLeft: "8px",
                      }}
                      src={commentIcon}
                      alt="icon"
                    />
                 
          
          <ReactTooltip
            aria-haspopup="true"
            role="example"
            className="extraClassClosingDates"
            id="colreason"
            multiline="true"
            type="dark"
          ></ReactTooltip>
        </span>
        ): (
          <span>{row.Reason}</span>
        )
      ) 
   
    },
    {
      name: "Closing Date",
      width:"180px",
      selector: "ClosingDate",
      sortable: true,
      wrap:true,
      textAlign:"center"
    }
    
  ];
  customStyles = {
  
    rows: {
      style: {
        minHeight: '62px',
        fontSize: '14px',
        wordBreak: 'break-all'
      },
    },
    cells: {
      style: {
        '&:not(:last-of-type)': {
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          borderRightColor: "rgba(0,0,0,.12)"
        },
      },
    },
  };
  //will create data table and render on page 
  getTableData = () => {
    return (
      
      <DataTableExtensions
        columns={this.columns}
        data={this.state.filterData}
        export={false}
        print={false}
        >
         <DataTable
          defaultSortField="id"
          defaultSortAsc={true}
          pagination
          paginationPerPage={20}
          highlightOnHover
          sort
          paginationRowsPerPageOptions={[20,50,100,200]}
          customStyles={this.customStyles}
         />
      </DataTableExtensions>
    );
  };
//this method will rest all session attribute values
  RestControls=()=> {
    
    this.setState({
       reason:"",
       closingDate:"",
      });
 }
//this method will format the date to date string mm/dd/yyyy
 formattedDate = (d) => {
  if (d !== "" && d !== undefined) {
    let month = String(d.getMonth() + 1);
    let day = String(d.getDate());
    const year = String(d.getFullYear());

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return `${year}/${month}/${day}`;
  } else {
    return "";
  }
};

  updateState = (stateObj) => {
    this.setState(stateObj);
  };

 render() {
    return (
      <div>
        <LoadingOverlay active={this.state.isLoading} spinner>
          {this.state.isLoading === true ? (
            ""
          ) : (
            <div class="body-support-block">
            <NavBarComponent updateState={this.updateState} activeTab = 'ClosingDates'/>
            <div class="tabcontent1">
              <div class="container-fluid">
                <div class="row">
                  <div class="cardsContainer">
                    <div class="responsive-table ClsSearchList">
                    <div class="ClsSplitTitle">Add Closing Date</div>
                      <div class="ClsClosingDateList1">
                        {" "}
                        <label for="lname">User ID</label>
                        <input
                          type="text"
                          maxLength="100"
                          value={this.state.username}
                          class="form-control"
                          disabled
                        />
                        </div>
                        <div class="ClsClosingDateList2">
                        {" "}
                        <label for="lname">Reason <span class="required"> *</span></label>
                        <input
                          type="text"
                          maxLength="3000"
                          value={this.state.reason}
                          class="form-control"
                          onChange={(e) =>
                            this.handleChangeValue(e, "txt")
                          }
                        /></div>
                        <div class="ClstableList">
                        {" "}
                        <label for="lname">Closing Date <span class="required"> *</span></label>  
                        <DatePicker
                          className="form-control  my-icon mb-2 mr-sm-4"
                          placeholderText="mm/dd/yyyy"
                          selected={this.state.closingDate}
                          onChange={(e) =>
                            this.handleChangeValue(e, "date")
                          }
                          autoComplete="off"
                          onKeyDown={(e) => {
                            this.keyDownEvent(e);
                          }}
                          dateFormat="MM/dd/yyyy"
                          maxDate={moment().toDate()}
                          minDate={subMonths(new Date(), 24)}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          ref={(c) => (this._startCalendar = c)}
                          id="dpStartDate"
                        />
                      </div>
                      
                      <div
                      style={{ marginRight: "0px" }}
                      className={
                        "updateRuleSearch"
          
                      }
                    >
                      <button
                        class="Clsbutton ClsSearch"
                        onClick={() => this.submitData()}
                      >
                        Save
                      </button>
                      <button
                        class="Clsbutton ClsReset"
                        onClick={() => this.RestControls()}
                      >
                        Reset
                      </button>
                    </div>
                     </div> 
                  </div>
                </div>
                <div className="row mt-10">
                  <div class="cardsContainer">
                    <div className="responsive-table tableSearchPlacement Searchamount tableClosingDates" style={{position:"relative"}}>
                    <div class="Closingdatestitle">Closing Dates History</div>
                    {this.getTableData()}
                    </div>
                  </div>
            </div>
              </div>
            </div>
            
            
        </div>
          )}
        </LoadingOverlay>

        <div className="modal show" id="submittedPopup">
          <div class="modal-dialog modal-success" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div class="successMessageMain">
                  <img src={popupIcon} alt="popup" />
                  <h4>Successfully Updated!</h4>
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => this.closeSubmittedPopup()}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}
