import React, { Component } from "react";
import * as config from "../../config";
import * as messages from "../../messages.js";
import axios from "../../api.js";
import Select from "react-select";
import RemoveIcon from "../SplitComponent/Delete-icon.svg";
import popupIcon from "../SplitComponent/tick-icon.png";
import AddIcon from "../SplitComponent/add.svg";
import LoadingOverlay from "react-loading-overlay";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { msalInstance } from "../../authConfig";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactTooltip from "react-tooltip";
import { makeStyles } from "@material-ui/styles";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import NavBarComponent from "../NavBarComponent/NavBarComponent.jsx";

toast.configure();

export default class UpdateRule extends Component {
  constructor() {
    super();
    this.state = {
      transId: 0,
      isLoading: false,
      totalAmount:100 ,
      remainingAmount: 100,
      splitData: [
        {
          id: 1,
          amount: "",
          l1Code: "",
          l2Code: "",
          pdAllocationCode: "",
          pdAllocationCodeDesc: "",
          L2CodeList: [],
          showAddButton: true,
          disablebtn: false,
          defaultvalue: "Select L2 Name",
          opacity: "1",
          isddlLoading: false,
          isAmountHighlight: false,
          l2mappingId: "",
          l2Id: "",
        },
      ],
      
      L1Codes: [],
      comments: "",
      splitObjectArray: [],
      isSaveDisabled: true,
      savebtnOpacity: ".5",
      isFrom: "",
      commentsArray: [],
      PoNumber:"",
      ShowDetails:true,
      ExixtingRules:[],
      PreviousRules:[],
      ExistingComments:{},
      PreviousProportions:[],
      POInfo:{},
      noRecords:"none",
      searchInput:"",
      ListPOs:[],
      selectPO:"",
    };
  }
//this method will call when page loads and load data to required controls
  componentDidMount = () => {
    this.getSerchPOswithVendorname()
    this.bindL1Codes(); 
  };

  // this will get name for the loggedin user
  getName = () => {
    try {
      var email =
        msalInstance.getAllAccounts()[0] !== null
          ? msalInstance.getAllAccounts()[0].username
          : "";

      return email;
    } catch (error) {
      console.log(error);
    }
  };

//this method will get Service data and bind to required sesssion Attributes
  bindL1Codes = () => {
    axios
      .get(config.baseURL + "getL1Codes")
      .then((response) => {
        console.log(response);
        this.setState({ L1Codes: response.data });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  };
//this method will get Service line data and bind to required sesssion Attributes
  bindL2Codes = (l1Code, idx) => {
    axios
      .get(config.baseURL + "getL2Codes/" + l1Code)
      .then((response) => {
        let arrayData = this.state.splitData.slice();
        arrayData[idx].l2code = "";
        arrayData[idx].L2CodeList = response.data;
        arrayData[idx].isddlLoading = false;
        arrayData[idx].pdAllocationCode = "";
        arrayData[idx].pdAllocationCodeDesc = "";
        this.setState({ splitData: arrayData });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  
//this method will get allocation data and bind to required sesssion Attributes
  getPdAllocationCode = (index, MGCCode) => {
    axios
      .get(config.baseURL + "getPDAllocationCode/" + MGCCode)
      .then((response) => {
        console.log("pdAllocationCode",response);
        let nextSocialData = this.state.splitData.slice();
        if (response.data.length > 0) {
          nextSocialData[index].pdAllocationCode =
            response.data[0].AllocationCode;
          nextSocialData[index].pdAllocationCodeDesc =
            response.data[0].AllocationDesc;
         
          
        } else {
          nextSocialData[index].pdAllocationCode = "";
          nextSocialData[index].pdAllocationCodeDesc = "";
         
        }

        this.setState({ splitData: nextSocialData });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //this method will add new row to attribute list
  handleAdd = (index) => {
    try {
      let array = this.state.splitData;
      array.push({
        id: array.length + 1,
        amount: "",
        l1Code: "",
        l2Code: "",
        pdAllocationCode: "",
        pdAllocationCodeDesc: "",
        L2CodeList: [],
        showAddButton: true,
        disablebtn: false,
        opacity: "1",
        l2mappingId: "",
        l2Id: ""
      });
      this.setState({ splitData: array });
      let sparray = this.state.splitData.slice();
      sparray[index].showAddButton = false;
      this.setState({ splitData: sparray });
    } catch (error) {
      console.log(error);
    }
  };

  onKeyDown = (event) => {
    var ASCIICode = event.which ? event.which : event.keyCode;
    
    if (
      ASCIICode === 187 ||
      ASCIICode === 101 ||
      ASCIICode === 69 ||
      ASCIICode === 40 ||
      ASCIICode === 189 ||
      ASCIICode === 38
    ) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  //this method will handle inputChange event called when we enter data and updte the required session attribute
  handleInputValueChange = (e, idx) => {
    try {
      var t = e.target.value;
      if(t>=0)
      {
        
          e.target.value =
          t.indexOf(".") >= 0
            ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)
            : t;
        e.target.setAttribute("class", "amountInput");
        let nextSocialData = this.state.splitData.slice();
        nextSocialData[idx].amount = e.target.value;
        nextSocialData[idx].isAmountHighlight = false;
        this.setState({ splitData: nextSocialData });
        var i;
        let array = this.state.splitData;
        var runningAmount = 0;
        for (i = 0; i < array.length; i++) {
          var rowAmount = array[i].amount !== "" ? array[i].amount : 0;
          let amount = parseFloat(rowAmount);
          runningAmount += amount;
        }
        let amnt = 0;
        runningAmount = runningAmount.toFixed(2);
        amnt = parseFloat(this.state.totalAmount) - runningAmount;
  
        let sparray = this.state.splitData.slice();
  
        if (parseFloat(runningAmount) === 100) {
          sparray[sparray.length - 1].disablebtn = true;
          sparray[sparray.length - 1].opacity = ".5";
          this.state.isSaveDisabled = false;
          this.setState({ savebtnOpacity: "1" });
        } else {
          //{
          sparray[sparray.length - 1].disablebtn = false;
          sparray[sparray.length - 1].opacity = "1";
          //}
          this.state.isSaveDisabled = true;
          this.setState({ savebtnOpacity: ".5" });
        }
  
        this.setState({ splitData: sparray });
        this.setState({ remainingAmount: parseFloat(amnt).toFixed(2) });
        
        
      }
      else{

      }
      
    } catch (error) {
      console.log(error);
    }
  };
//called when you move focus from input controls
  onpaste = (e, idx) => {
    try {
      if(e.target.value>=0)
      {
        if (e.target.value == 0) {
          let nextSocialData = this.state.splitData.slice();
          nextSocialData[idx].amount = "";
          nextSocialData[idx].isAmountHighlight = false;
          this.setState({ splitData: nextSocialData });
        } else {
          var i;
          let array = this.state.splitData;
          var runningAmount = 0;
          for (i = 0; i < array.length; i++) {
            var rowAmount = array[i].amount !== "" ? array[i].amount : 0;
            let amount = parseFloat(rowAmount);
            runningAmount += amount;
          }
          let amnt = 0;
          runningAmount = runningAmount.toFixed(2);
          amnt = parseFloat(this.state.totalAmount) - runningAmount;
  
          let sparray = this.state.splitData.slice();
          // if (amnt !=this.state.totalAmount) {
          //   //nextSocialData[idx].isAmountHighlight=true;
          //   //this.setState({ splitData: nextSocialData });
          //   toast.error(messages.mismatchAmount, {
          //     autoClose: 10000,
          //   });
          // }
          // runningAmount =
          //   this.state.totalAmount > 0 ? runningAmount : -Math.abs(runningAmount);
          if (parseFloat(runningAmount) ===100) {
            //sparray[idx].showAddButton = false;
            sparray[sparray.length - 1].disablebtn = true;
            sparray[sparray.length - 1].opacity = ".5";
            this.state.isSaveDisabled = false;
            this.setState({ savebtnOpacity: "1" });
          } else {
            // if(idx==sparray.length-1)
            //{
            // sparray[idx].showAddButton = true;
            sparray[sparray.length - 1].disablebtn = false;
            sparray[sparray.length - 1].opacity = "1";
            //}
            this.state.isSaveDisabled = true;
            this.setState({ savebtnOpacity: ".5" });
          }
  
          this.setState({ splitData: sparray });
          this.setState({ remainingAmount: parseFloat(amnt).toFixed(2) });
        }
      }
      
    } catch (error) {
      console.log(error);
    }
  };

  //this method will handle inputCommentChange event called when we enter data in to comments and updte the required session attribute
  handleCommentsChange = (e) => {
    try {
      this.setState({ comments: e.target.value });
    } catch (error) {
      console.log(error);
    }
  };
//in this method ae are setting value for service Drop down based on session attribute
  setl1_values = (idx) => {
    try {
      let nextSocialData = this.state.splitData.slice();
      return this.state.L1Codes.filter(
        (e) => e.label === nextSocialData[idx].l1Code
      );
    } catch (error) {
      console.log(error);
    }
  };
//in this method ae are setting value for service line Drop down based on session attribute
  setl2_values = (idx) => {
    try {
      let nextSocialData = this.state.splitData.slice();
      return nextSocialData[idx].L2CodeList.filter(
        (e) => e.label === nextSocialData[idx].l2Code
      );
    } catch (error) {
      console.log(error);
    }
  };
//this method will handle Service Change event called when we select data and updte the required session attribute
  handleL1codeChange = (e, l1label, idx, l1value) => {
    try {
      document.getElementById(e).setAttribute("class", "");
      let nextSocialData = this.state.splitData.slice();
      nextSocialData[idx].l1Code = l1label;
      nextSocialData[idx].l2Code = "";
      nextSocialData[idx].L2CodeList = [];
      nextSocialData[idx].isddlLoading = true;
      nextSocialData[idx].pdAllocationCode = "";
      nextSocialData[idx].pdAllocationCodeDesc = ""
      setTimeout(() => {
        this.setState({ splitData: nextSocialData });
      }, 500);

      this.bindL2Codes(l1label, idx);
    } catch (error) {
      console.log(error);
    }
  };
//this method will handle Service line Change event called when we select data and updte the required session attribute
  handleL2codeChange = (e, l2value, idx, MGCCode) => {
    try {
      document.getElementById(e).setAttribute("class", "");
      let nextSocialData = this.state.splitData.slice();

      nextSocialData[idx].l2Code = l2value;
      nextSocialData[idx].l2mappingId = MGCCode;

      setTimeout(() => {
        this.setState({ splitData: nextSocialData });
      }, 500);
      this.getPdAllocationCode(idx, MGCCode);
    } catch (error) {
      console.log(error);
    }
  };
//this method will remove row from attribute list
  handleRemove = (idx) => {
    try {
      let someArray = this.state.splitData;
      var runningAmount = 0;
      if (someArray.length > 1) {
        someArray.splice(idx, 1);
        this.setState({ splitData: someArray });
        var i;
        let array = this.state.splitData;

        for (i = 0; i < array.length; i++) {
          var rowAmount = array[i].amount !== "" ? array[i].amount : 0;
          let amount = parseFloat(rowAmount);
          runningAmount += amount;
        }

        let amnt = 0;
        amnt = parseFloat(this.state.totalAmount) - runningAmount;
        this.setState({ remainingAmount: parseFloat(amnt).toFixed(2) });
        let sparray = this.state.splitData.slice();
        sparray[sparray.length - 1].showAddButton = true;
        if (amnt === 0) {
          sparray[sparray.length - 1].disablebtn = true;
          sparray[sparray.length - 1].opacity = ".5";
          this.state.isSaveDisabled = false;
          this.setState({ savebtnOpacity: "1" });
        } else {
          sparray[sparray.length - 1].disablebtn = false;
          sparray[sparray.length - 1].opacity = "1";
          this.state.isSaveDisabled = true;
          this.setState({ savebtnOpacity: ".5" });
        }
        this.setState({ splitData: sparray });
      }
    } catch (error) {}
  };
//this method will call api to save data from UI to DB
  submitData = () => {
    try {
      if (this.validateForm()) {
        const insertObject = {};
        insertObject.transId = this.state.transId;
        insertObject.comments = this.state.comments.trimEnd().trimStart();
        insertObject.poNumber = this.state.PoNumber.trimEnd().trimStart();
        insertObject.splitData = this.state.splitObjectArray;
        insertObject.createdBy = this.getName();
        this.setState({ isLoading: true });
        axios
          .post(config.baseURL + "UpdateRule", insertObject)
          .then((response) => {
            // handle success
            this.setState({ isLoading: false });
          
            document.getElementById("submittedPopup").style.display = "block";
            document.getElementById("div-overlay").style.display = "block";
            
          })
          .catch((error) => {
            // handle error
            this.setState({ isLoading: false });
            this.setState({ splitObjectArray: [] });
            toast.error("Submit Failed", {
              autoClose: 10000,
            });
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

//this method handles functionlity when you close the submit popup
  closeSubmittedPopup() {
    try {
      document.getElementById("submittedPopup").style.display = "none";
      document.getElementById("div-overlay").style.display = "none";
      window.location.href = "/UpdateRule";
    } catch (error) {
      console.log(error);
    }
  }
//this method validate the form based on business functionalities
  validateForm = () => {
    try {
      var i;
      this.setState({ splitObjectArray: [] });
      let array = this.state.splitData;
      var totalPOAmount = 0;
      var result = true;
      var count = 0;
      for (i = 0; i < array.length; i++) {
        var amnt = array[i].amount;
        var l2code = array[i].l2Code;
        var l1code = array[i].l1Code;
        if (amnt === "" || amnt === undefined) {
         
          result = false;
        } else {
          
            var runningamount = parseFloat(array[i].amount);
            totalPOAmount += runningamount;
         
         }
        if (l1code === "" || l1code === undefined) {
          // var socialctrl = document.getElementById("ddl1" + i);
          // socialctrl.setAttribute("class", "errorClass");
          result = false;
        }
        if (l2code === "" || l2code === undefined) {
          // var socialctrl = document.getElementById("ddl2" + i);
          // socialctrl.setAttribute("class", "errorClass");
          result = false;
        }
        

        count = count + this.validateDuplicateServiceServiceLine(i);
        // if(!this.validateDuplicateServiceServiceLine(i))
        // {
        //   toast.error(messages.DuplicateL1L2, {
        //     autoClose: 10000,
        //   });
        // }

        var obj = {
          MgcCode: array[i].l2mappingId,
          ServiceL2: array[i].l2Code,
          LineId: array[i].id,
          Ratio: parseFloat(array[i].amount),
          
        };
        this.state.splitObjectArray.push(obj);
      }

      if (result) {
        totalPOAmount = totalPOAmount.toFixed(2);
        if (parseFloat(totalPOAmount) !== parseFloat(this.state.totalAmount)) {
          toast.error(messages.mismatchAmount, {
            autoClose: 10000,
          });
          result = false;
        } else if (count > 0) {
          toast.error(messages.DuplicateL1L2, {
            autoClose: 10000,
          });
          result = false;
        } else {
          if (this.state.comments.trimEnd().trimStart() === "") {
            result = false;
            toast.error(messages.validationMesage, {
              autoClose: 10000,
            });
          }
        }
      } else {
        toast.error(messages.validationMesage, {
          autoClose: 10000,
        });
      }

      return result;
    } catch (error) {
      console.log(error);
    }
  };
//halde cancel click in the page
  cancelClick = () => {
    try {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="conformDiv">
              <p className="conformBody">
                If you cancel then your changes will be lost. Are you sure you
                want to cancel?
              </p>
              <div className="ClsSearchPOsButton">
                <button
                  onClick={() => {
                    
                      window.location.href = "/UpdateRule";
                   
                  }}
                  className="Clsbutton ClsSearch"
                >
                  Yes
                </button>
                <button onClick={onClose} className="Clsbutton ClsReset">
                  No
                </button>
              </div>
            </div>
          );
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

 //validate Duplicate service and service line for different splits
  validateDuplicateServiceServiceLine = (idx) => {
    try {
      let splitArray = this.state.splitData.slice();
      let serviceLine = splitArray[idx].l1Code;
      let service = splitArray[idx].l2Code;
      if (
        serviceLine !== null &&
        serviceLine !== "" &&
        serviceLine !== undefined
      ) {
        let index = splitArray.findIndex(
          (row) => row.l1Code === serviceLine && row.l2Code === service
        );
        if (index >= 0 && index !== idx) {
          return 1;
        } else {
          return 0;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  
//this method will handle inputChange event called when we enter data and updte the required session attribute
 handleChagneValue=(e)=>{
   if(e.target.value!="")
   {
    this.setState({ PoNumber: e.target.value });
   }
   else
   {
     this.RestControls();
   }
  
 }
//method will search for rule in db based on Po number entered by user 
 SearchForPO=()=>
 {
    const select1= this.state.PoNumber.slice(0, this.state.PoNumber.indexOf('('));
     this.setState({PoNumber:select1})
   console.log(this.state.PoNumber,"poo")
   if(this.state.PoNumber!="")
   {
     var obj={
      PoNumber:this.state.PoNumber
     }
     this.setState({isLoading:true});
    axios
    .post(config.baseURL + "getPropotions",obj)
    .then((response) => {
      if(response.data!=null && response.data!=undefined && response.data[1].length>0)
      {
        this.setState({POInfo:response.data[0][0]});
        this.setState({searchInput:this.state.POInfo.PONumber});
      var Exixtingarray=response.data[1].filter(w=>w.IsExisting==1);
      var previousArray=response.data[1].filter(w=>w.IsExisting==0);
      var cmntsExixts=Exixtingarray[0];
    
      let distinctArray =[];
      var result=[];
      if(previousArray.length>0)
      {
        previousArray =  previousArray.sort(function(a, b){
          var keyA = new Date(a.ModifiedDate),
              keyB = new Date(b.ModifiedDate);
          // Compare the 2 dates
          if(keyA > keyB) return -1;
          if(keyA < keyB) return 1;
          return 0;
      });
        var result = previousArray.reduce(function(r, o){
          var k = o.ModifiedDate   // unique `loc` key
          if (r[k] || (r[k]=[])) r[k].push({Ratio:o.Ratio, ServiceLineL1: o.ServiceLineL1, ServiceL2: o.ServiceL2,AllocationCode: o.AllocationCode});
          return r;
        }, {});
        
        var key="ModifiedDate";
        var array = [...new Map(previousArray.map(item =>
          [item[key], item])).values()];
          var i=0;
          for(i=0;i<array.length;i++)
          {
            distinctArray.push(array[i])
          } 
          
         
      }
      else{
        this.setState({noRecords:"block"}); 
      }
      this.setState({transId:cmntsExixts.EntityID}); 
      this.setState({ExistingComments:cmntsExixts});
      this.setState({ExixtingRules:Exixtingarray});
      this.setState({PreviousProportions:result});
      this.setState({ ShowDetails: false});
      this.setState({PreviousRules:distinctArray});
      this.setState({isLoading:false});
    }
    else
    {
      toast.error(messages.NoPoFound, {
        autoClose: 10000,
      });
      
      this.setState({
        transId: 0,
        isLoading: false,
        totalAmount:100 ,
        remainingAmount: 100,
        splitData: [
          {
            id: 1,
            amount: "",
            l1Code: "",
            l2Code: "",
            pdAllocationCode: "",
            pdAllocationCodeDesc: "",
            L2CodeList: [],
            showAddButton: true,
            disablebtn: false,
            defaultvalue: "Select L2 Name",
            opacity: "1",
            isddlLoading: false,
            isAmountHighlight: false,
            l2mappingId: "",
            l2Id: "",
          },
        ],
        
        L1Codes: [],
        comments: "",
        splitObjectArray: [],
        isSaveDisabled: true,
        savebtnOpacity: ".5",
        isFrom: "",
        commentsArray: [],
        ShowDetails:true,
        ExixtingRules:[],
        PreviousRules:[],
        ExistingComments:{},
        PreviousProportions:[],
        POInfo:{},
        noRecords:"none"
      });
      this.setState({isLoading:false});
    }

    
    })
    .catch((error) => {
      this.setState({isLoading:false});
      console.log(error);
    });
    
   }
   else{
    toast.error(messages.PoRequired, {
         autoClose: 10000,
       });
   }
 }
//this will clear all session attribute values
 RestControls=()=>
 {
  this.setState({
    transId: 0,
    isLoading: false,
    totalAmount:100 ,
    remainingAmount: 100,
    splitData: [
      {
        id: 1,
        amount: "",
        l1Code: "",
        l2Code: "",
        pdAllocationCode: "",
        pdAllocationCodeDesc: "",
        L2CodeList: [],
        showAddButton: true,
        disablebtn: false,
        defaultvalue: "Select L2 Name",
        opacity: "1",
        isddlLoading: false,
        isAmountHighlight: false,
        l2mappingId: "",
        l2Id: "",
      },
    ],
    
    L1Codes: [],
    comments: "",
    splitObjectArray: [],
    isSaveDisabled: true,
    savebtnOpacity: ".5",
    isFrom: "",
    commentsArray: [],
    ShowDetails:true,
    ExixtingRules:[],
    PreviousRules:[],
    ExistingComments:{},
    PoNumber:"",
    PreviousProportions:[],
    POInfo:{},
    noRecords:"none",
    searchInput:"",
  });
 }
  // getting vendor Name 
  getSerchPOswithVendorname = () => {
    this.setState({ isLoading: true });
    axios
      .get(config.baseURL + "getPOsForSearchRule")
      .then((response) => {
        console.log(response, "resss");
        this.setState({ ListPOs: response.data[0] });

       // this.setState({ ListPOs: response.data[0] });
        this.setState(
          this.state.ListPOs.map((i) => ({
            PurchasingDocument:i.label,
            VendorName:i.value
          
          // this.state.ListPOs.map((i) => ({
          //   PurchasingDocument:i.PurchasingDocument,
          //   VendorName:i.VendorName
          }))
        )
        this.setState({ isLoading: false });
        console.log(this.state.ListPOs, "response.data")

     let data= this.state.ListPOs?.map((option) => ({
          label: option.PurchasingDocument,
          value: option.VendorName,
        }))
        this.setState({ListPOs:data})
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  };

  handleOnSelect = (v) => {
   this.setState({searchInput:v})
   if(v !== null){
   this.setState({PoNumber:v.slice(0, v.indexOf('('))})
   console.log(this.state.PoNumber,"selectPO")
   }
  };

  updateState = (stateObj) => {
    this.setState(stateObj);
  };

  render() {
    return (
      <div>
        <LoadingOverlay active={this.state.isLoading} spinner>
          {this.state.isLoading === true ? (
            ""
          ) : (
            <div class="body-support-block">
            <NavBarComponent updateState={this.updateState} activeTab = 'UpdateRule'/>
            <div class="tabcontent1">
              <div class="container-fluid">
                <div class="row">
                  <div class="cardsContainer">
                    <div class="responsive-table ClsSearchList  ">
                      <div class=" ClstableListAssignRule reactautofill  inputsearchfill-updaterule autosearchul  ">
                        {" "}
                        <label for="lname">Search for a Rule(PO Id/Vendor Name)</label>

                        <Autocomplete
                                   className=" reactautofill autofilltextarea"
                                  freeSolo
                                  id="free-solo-demo"
                                  value={this.state.searchInput}
                                  autoHighlight
                                  options={ this.state.ListPOs.map((option) => ((option.label) + '(' + (option.value) + `)`))}
                                  onChange={(e, v) => this.handleOnSelect(v)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label=""
                                      variant="outlined"
                                      placeholder=""
                                      size="small"
                                    />
                                  )}
                                />

                        {/* <input
                          type="text"
                          maxLength="100"
                          onChange={(e) =>
                            this.handleChagneValue(e)
                          }
                          value={this.state.PoNumber}
                          class="form-control"
                        /> */}
                      </div>
                      
                      <div
                      style={{ marginRight: "0px" }}
                      className={
                        "updateRuleSearch"
          
                      }
                    >
                      <button
                        class="Clsbutton ClsSearch"
                        onClick={() => this.SearchForPO()}
                      >
                        Search
                      </button>
                      <button
                        class="Clsbutton ClsReset"
                        onClick={() => this.RestControls()}
                      >
                        Reset
                      </button>
                    </div>
                     </div> 
                  </div>
                </div>
                
              </div>
            </div>

            <div  className={
                          this.state.ShowDetails
                            ? "hideDiv"
                            : "tabcontent1"
                        } style={{marginTop:"10px"}}>
              <div class="container-fluid">
                <div class="row">
                  <div class="cardsContainer">
                    <div class="responsive-table ClsSearchList">
                      <div style={{float:"left"}}>
                      <span>Vendor Name : </span>
                      <span style={{marginRight:"47px",fontWeight:"600"}}>{this.state.POInfo["Vendor Name"]}</span>{" "}
                      </div>
                      <div style={{float:"left"}}>
                      <span>Cost Center : </span>
                      <span style={{marginRight:"47px",fontWeight:"600"}}>{this.state.POInfo["Cost Center"]}</span>{" "}
                       </div>
                       <div style={{float:"left"}}>
                      <span>Cost Element : </span>
                      <span style={{marginRight:"47px",fontWeight:"600"}}>{this.state.POInfo["Cost Element"]}</span>{" "}
                       </div>
                       <div style={{float:"left"}}>
                      <span>Cost Center Name : </span>
                      <span style={{marginRight:"47px",fontWeight:"600"}}>{this.state.POInfo["Cost Center Name"]}</span>{" "}
                       </div>
                       <div style={{float:"left"}}>
                      <span>Cost Element Desc : </span>
                      <span style={{marginRight:"47px",fontWeight:"600"}}>{this.state.POInfo["Cost Element Desc"]}</span>{" "}
                       </div>
                     </div>

                  </div>
                </div>
                
              </div>
            </div>
              <div id="Unassigned" 
              className={
                this.state.ShowDetails
                  ? "hideDiv"
                  : "tabcontent tabcontent1"
              }>
                <div class="container-fluid splitAssignContainerFluid">
                  <div class="row">
                    <div class="cardsContainer bodyContentHeight">
                      <div class="responsive-table">
                        <div class="ClsUpdateRuleLeft">
                          <div class="ClsSplitTitle">Update Rule for PO {this.state.POInfo["PONumber"]}</div>
                          <div class="ClsSplitAssigndiv">
                            <div class="ClsSplitAddDiv">
                              <table className="table TableTitleHeight">
                                <thead className="splittablehead">
                                  <tr>
                                    <td class="col-2">
                                      <label for="lname">
                                        Ratio (%){" "}
                                        <span class="required"> *</span>
                                      </label>
                                    </td>
                                    <td className="col-3 ClsSplitass3">
                                      {" "}
                                      <label for="lname">
                                        Service Line{" "}
                                        <span class="required"> *</span>
                                      </label>
                                    </td>
                                    <td className="col-3 ClsSplitass3">
                                      {" "}
                                      <label for="lname">
                                        Service <span class="required"> *</span>
                                      </label>
                                    </td>
                                    <td className="col-2">
                                      {" "}
                                      <label for="lname">Allocation Code</label>
                                    </td>
                                    
                                    <td className="col-2 ClsSplitass2">
                                      <label for="lname"> &nbsp;</label>
                                    </td>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.splitData.map((Social, idx) => (
                                    <tr key={idx} className="col-12" row={idx}>
                                      <td class="col-2">
                                        <div>
                                          {" "}
                                          <input
                                            className={
                                              Social.isAmountHighlight
                                                ? "amountInput errorClass form-control"
                                                : "amountInput form-control"
                                            }
                                            id={"txt" + idx}
                                            value={Social.amount}
                                            type="number"
                                            autocomplete="off"
                                            placeholder={`Enter Ratio`}
                                            onKeyDown={(e) => {
                                              this.onKeyDown(e, idx);
                                            }}
                                            onChange={(e) => {
                                              this.handleInputValueChange(
                                                e,
                                                idx
                                              );
                                            }}
                                            onBlur={(e) => {
                                              this.onpaste(e, idx);
                                            }}
                                          />
                                        </div>
                                      </td>
                                      <td className="col-3 ClsSplitass3">
                                        <div>
                                          <Select
                                            id={"ddl1" + idx}
                                            options={this.state.L1Codes}
                                            value={this.setl1_values(idx)}
                                            onChange={(e) => {
                                              this.handleL1codeChange(
                                                "ddl1" + idx,
                                                e.label,
                                                idx,
                                                e.value
                                              );
                                            }}
                                          />
                                        </div>
                                      </td>
                                      <td className="col-3 ClsSplitass3">
                                        <div>
                                          <Select
                                            id={"ddl2" + idx}
                                            options={
                                              this.state.splitData[idx]
                                                .L2CodeList
                                            }
                                            value={this.setl2_values(idx)}
                                            isLoading={
                                              this.state.splitData[idx]
                                                .isddlLoading
                                            }
                                            onChange={(e) => {
                                              this.handleL2codeChange(
                                                "ddl2" + idx,
                                                e !== null ? e.label : "",
                                                idx,
                                                e !== null ? e.value : ""
                                              );
                                            }}
                                          />
                                        </div>
                                      </td>
                                      <td id={"pdtd" + idx} className="col-2">
                                        <div data-for="AllocDescSplit" data-tip={Social.pdAllocationCodeDesc} > 
                                          <input
                                            className="amountInput form-control"
                                            id={"txtPD" + idx}
                                            type="text"
                                            value={Social.pdAllocationCode}
                                            disabled  
                                          />
                                         
                                          <ReactTooltip
                                            aria-haspopup="true"
                                            role="example"
                                            className="extraClass"
                                            id="AllocDescSplit"
                                            multiline="true"
                                            type="dark"
                                          ></ReactTooltip>
                                        </div>
                                      </td>
                                      
                                      <td className="col-2 ClsSplitass2">
                                        <div>
                                          <button
                                            tabindex="0"
                                            onClick={() =>
                                              this.handleRemove(idx)
                                            }
                                            className="removeSpan Clsbutton"
                                          >
                                            <img
                                              style={{ width: "24px" }}
                                              src={RemoveIcon}
                                              alt="remove"
                                            />
                                          </button>
                                          <button
                                            style={{ opacity: Social.opacity }}
                                            className={
                                              Social.showAddButton
                                                ? "Clsbutton ClsAdd"
                                                : "Clsbutton ClsAdd hidden"
                                            }
                                            onClick={() => this.handleAdd(idx)}
                                            disabled={Social.disablebtn}
                                          >
                                            <img
                                              className="addbuttonImage"
                                              src={AddIcon}
                                              alt="add"
                                            />
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                            <div class="ClsTotalAmount">
                              <div class="row">
                                <div class="col-4">
                                  {" "}
                                  <label for="lname" className="labelAmounts">
                                    Remaining Ratio :
                                  </label>{" "}
                                  <span>
                                    <NumberFormat
                                      value={this.state.remainingAmount}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      suffix="%"
                                    />
                                  </span>{" "}
                                </div>
                               
                              </div>
                            </div>
                            <div class="ClsSplitCommentsArea">
                              <div>
                                {" "}
                                <label for="lname">
                                  Comments<span class="required"> *</span>
                                </label>{" "}
                                <textarea
                                  rows="4"
                                  cols="50"
                                  maxLength="3000"
                                  name="comment"
                                  form="usrform"
                                  class="form-control"
                                  onChange={(e) => {
                                    this.handleCommentsChange(e);
                                  }}
                                >
                                  {" "}
                                </textarea>{" "}
                              </div>
                            </div>
                            {this.state.commentsArray.map((cmntsObj, idx) => (
                              <div class="commentsHistory">
                                <div class="commentSingle">
                                  <div class="row">
                                    <div class="col-md-8">
                                      <h4>{cmntsObj.CreatedBy}</h4>
                                      <p class="textBreak">
                                        {cmntsObj.Comment}
                                      </p>
                                    </div>
                                    <div class="col-md-4">
                                      <p class="commentDate">
                                        {cmntsObj.CreatedDate}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div class="ClsSearchPOsButton">
                            <button
                              style={{ opacity: this.state.savebtnOpacity }}
                              class="Clsbutton ClsSearch"
                              onClick={() => this.submitData()}
                              disabled={this.state.isSaveDisabled}
                            >
                              Save
                            </button>
                            <button
                              class="Clsbutton ClsReset"
                              onClick={() => this.cancelClick()}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                        <div class="ClsUpdateRuleRight">
                          <div class="ClsSplitTitle">Current Rule</div>
                          <div class="ClsSplitDiv">
                          <table className="table" style={{marginLeft:""}}>
                                <thead className="splittablehead">
                                  <tr>
                                    <td class="">
                                      <label for="lname">
                                        Ratio (%){" "}
                                        
                                      </label>
                                    </td>
                                    <td className="">
                                      {" "}
                                      <label for="lname">
                                        Service Line{" "}
                                       
                                      </label>
                                    </td>
                                    <td className="">
                                      {" "}
                                      <label for="lname">
                                        Service 
                                      </label>
                                    </td>
                                    <td className="">
                                      {" "}
                                      <label for="lname">Allocation Code</label>
                                    </td>
                                  </tr>
                                </thead>

                                <tbody>
                                {this.state.ExixtingRules.map((Social, idx) => (
                                   <tr className="">
                                      <td class="">
                                        <span>{Social.Ratio}</span>
                                      </td>
                                      <td className="">
                                      <span>{Social.ServiceLineL1}</span>
                                      </td>
                                      <td className="">
                                      <span>{Social.ServiceL2}</span>
                                      </td>
                                      <td className="">
                                      <span>{Social.AllocationCode}</span>
                                      </td>                                                                       
                                    </tr>
                                  ))}
                                </tbody>
                          </table>
                          <div class="">                            
                                <div class="commentSingle commentSpacing">
                                  <div class="row">
                                    <div class="col-md-8">
                                      <h4>{this.state.ExistingComments.CreatedBy}</h4>
                                      <p class="textBreak">
                                      {this.state.ExistingComments.Comment}
                                      </p>
                                    </div>
                                    <div class="col-md-4">
                                      <p class="commentDate">
                                      {this.state.ExistingComments.CreatedDate}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              
                         </div>
                          
                          <div class="ClsSplitTitle">Previous Rules</div>
                          <span style={{textAlign:"center",padding:"40px",color:"#545454",display:this.state.noRecords}}>No Previous Rules</span>
                          {this.state.PreviousRules.map((previousrule, idx) => (
                          <div>
                            
                          <table className="table TableTitleHeight">
                                <thead className="splittablehead">
                                  <tr>
                                    <td class="col-2">
                                      <label for="lname">
                                        Ratio (%){" "}
                                       
                                      </label>
                                    </td>
                                    <td className="col-3 ClsSplitass3">
                                      {" "}
                                      <label for="lname">
                                        Service Line{" "}
                                       
                                      </label>
                                    </td>
                                    <td className="col-3 ClsSplitass3">
                                      {" "}
                                      <label for="lname">
                                        Service
                                      </label>
                                    </td>
                                    <td className="col-2">
                                      {" "}
                                      <label for="lname">Allocation Code</label>
                                    </td>
                                  </tr>
                                </thead>
                                <tbody>
                                {this.state.PreviousProportions[previousrule.ModifiedDate].map((previousrule, idx) => (
                                
                                   <tr className="col-12">
                                      <td class="col-2">
                                      {previousrule.Ratio}
                                      </td>
                                      <td className="col-3 ClsSplitass3">
                                      {previousrule.ServiceLineL1}
                                      </td>
                                      <td className="col-3 ClsSplitass3">
                                      {previousrule.ServiceL2}
                                      </td>
                                      <td className="col-2">
                                      {previousrule.AllocationCode}
                                      </td>
                                      
                                      
                                    </tr>
                                ))}
                                </tbody>
                          </table>
                          <div class="">
                             
                                <div class="commentSingle commentSpacing">
                                  <div class="row">
                                    <div class="col-md-8">
                                      <h4>{previousrule.CreatedBy}</h4>
                                      <p class="textBreak">
                                       {previousrule.Comment}
                                      </p>
                                    </div>
                                    <div class="col-md-4">
                                      <p class="commentDate">
                                       {previousrule.CreatedDate}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              
                          </div>
                          </div>
                          ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </LoadingOverlay>

        <div className="modal show" id="submittedPopup">
          <div class="modal-dialog modal-success" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div class="successMessageMain">
                  <img src={popupIcon} alt="popup" />
                  <h4>Successfully Updated!</h4>
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => this.closeSubmittedPopup()}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}
