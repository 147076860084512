import React, { Component } from "react";
import * as config from "../../config";
import * as messages from "../../messages.js";
import * as CurrencyList from "../../currency.js";
import axios from "../../api.js";
import popupIcon from "../SplitComponent/tick-icon.png";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { msalInstance } from "../../authConfig";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import moment from "moment";
import { subMonths } from "date-fns";
import { utc } from "moment";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import NavBarComponent from "../NavBarComponent/NavBarComponent.jsx";

toast.configure();

export default class PlugTranscation extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      username: "",
      Comments: "",
      closingDate: "",
      L1Codes: [],
      L2Codes: [],
      l1Code: "",
      l2Code: "",
      MgcCode: "",
      AllocationCode: "",
      softwareDisplay: "",
      Softwares: [],
      Software: "",
      AllocationCodeDesc: "",
      PorefferenceId: "",
      SystemgeneratedRefID: "",
      IsSysGenerated: "",
      EntityId: "",
      PostingDate: "",
      DocumentDate: "",
      DocumnetNumber: "",
      Fiscalyear: "",
      InvoiceDocumentNumber: "",
      CostElement: [],
      CostElementName: "",
      CostElementValue: "",
      CostCenter: [],
      CostCenterName: "",
      CostCenterValue: "",
      CostElemntSubCat: "",
      RollUpScorecard: "",
      ITFunction: "",
      Performance: "",
      ValueAreaCurrency: "",
      ObjectCurrency: "",
      ObjectCurrencyValue: "",
      ObjectCurrencyList:[],
      Currency: "USD",
      StillToBeDeliveredValue: "",
      StillToBeDeliveredQty: "",
      NetOrderValue: "",
      GrossOrderValue: "",
      ValueinObjectCurrency: "",
      iscurrencyddlLoading:false,
      OrderQuantity: "",
      NetPrice: "",
      OrderUnit: "",
      ShortText: "",
      ProfitCenter: "",
      PriceDate: "",
      Period: "",
      isddlLoading: false,
    };
  }
//this method will call when page loads and load data to required controls
  componentDidMount = () => {
    this.prepopulatePostingDateRelatedData();
    this.bindSoftwareNames();
    this.bindL1Codes();
    this.bindCostCenters();
    this.bindCostElement();
    this.getLastRefferenceId();
  };
  
//this method will get Softwre data and bind to required sesssion Attributes
  bindSoftwareNames = () => {
    axios
      .get(config.baseURL + "getServiceSoftwareNames")
      .then((response) => {
        var swarray = response.data;
        var otherobj = {
          label: "Other",
          value: "Other",
        };
        swarray.push(otherobj);

        this.setState({ Softwares: swarray });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  };
// this will get name for the loggedin user
  getName = () => {
    try {
      var email =
        msalInstance.getAllAccounts()[0] !== null
          ? msalInstance.getAllAccounts()[0].username
          : "";
      this.setState({ username: email });
      return email;
    } catch (error) {
      console.log(error);
    }
  };
//handles key down event form input control	
  onKeyDown = (event) => {
    var ASCIICode = event.which ? event.which : event.keyCode;
   if (
      ASCIICode === 187 ||
      ASCIICode === 101 ||
      ASCIICode === 69 ||
      ASCIICode === 40 ||
      ASCIICode === 189 ||
      ASCIICode === 38
    ) {
      event.preventDefault();
      return false;
    }
    return true;
  };
//in this method we are validation po number is  system generated or not
  checkPoFormat = () => {
    try {
      var result = 0;
      var posubstr = this.state.PorefferenceId.substring(0, 11);
      var sysPosubstr = this.state.SystemgeneratedRefID.substring(0, 11);
      if (posubstr === sysPosubstr) {
        var lastPostring = this.state.PorefferenceId.substring(
          11,
          this.state.PorefferenceId.length
        );
        var lastSysPostring = this.state.SystemgeneratedRefID.substring(
          11,
          this.state.PorefferenceId.length
        );
        if (
          this.isNumeric(lastSysPostring) &&
          parseInt(lastPostring) >= lastSysPostring
        )
          result = 1;
      }
      return result;
    } catch (error) {
      console.log(error);
    }
  };
//this method will call validate  and   save sub methods method
  submitData = () => {
    try {
      if (this.validate()) {
        if (this.validateAmountFields()) {
          //if (this.validateFiscalYear()) {
              this.validateEntityId()
           //}
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //this method will call api to save data from UI to DB
  saveData=()=>
  {
    try{
      var EntityId =
      this.state.DocumnetNumber +
      "_" +
      this.state.InvoiceDocumentNumber;
    const insertObject = {};
    insertObject.PostingDate = new utc(
      this.formattedDate(this.state.PostingDate)
    );
    insertObject.DocumentDate = new utc(
      this.formattedDate(this.state.DocumentDate)
    );
    insertObject.PriceDate = new utc(
      this.formattedDate(this.state.PriceDate)
    );
    insertObject.createdBy = this.getName();
    insertObject.l1Code = this.state.l1Code;
    insertObject.l2Code = this.state.l2Code;
    insertObject.MgcCode = this.state.MgcCode;
    insertObject.AllocationCode = this.state.AllocationCode;
    insertObject.Software = this.state.Software;
    insertObject.PorefferenceId = this.state.PorefferenceId.trimEnd().trimStart();
    insertObject.IsSysGenerated = this.checkPoFormat();
    insertObject.DocumnetNumber = this.state.DocumnetNumber.trimEnd().trimStart();
    insertObject.Fiscalyear = this.state.Fiscalyear.trimEnd().trimStart();
    insertObject.InvoiceDocumentNumber =
      this.state.InvoiceDocumentNumber.trimEnd().trimStart();
    insertObject.CostElementName = this.state.CostElementName;
    insertObject.CostElementValue = this.state.CostElementValue;
    insertObject.EntityId = EntityId;
    insertObject.CostCenterName = this.state.CostCenterName;
    insertObject.CostCenterValue = this.state.CostCenterValue;
    insertObject.CostElemntSubCat = this.state.CostElemntSubCat;
    insertObject.RollUpScorecard = this.state.RollUpScorecard;
    insertObject.ITFunction = this.state.ITFunction;
    insertObject.Performance = this.state.Performance;
    insertObject.ValueAreaCurrency = this.state.ValueAreaCurrency;
    insertObject.ObjectCurrency = this.state.ObjectCurrencyValue;
    insertObject.Currency = this.state.Currency;
    insertObject.StillToBeDeliveredValue =
      this.state.StillToBeDeliveredValue;
    insertObject.StillToBeDeliveredQty =
      this.state.StillToBeDeliveredQty;
    insertObject.NetOrderValue = this.state.NetOrderValue;
    insertObject.GrossOrderValue = this.state.GrossOrderValue;
    insertObject.ValueinObjectCurrency =
      this.state.ValueinObjectCurrency;
    insertObject.OrderQuantity = this.state.OrderQuantity;
    insertObject.NetPrice = this.state.NetPrice;
    insertObject.OrderUnit = this.state.OrderUnit.trimEnd().trimStart();
    insertObject.ShortText = this.state.ShortText.trimEnd().trimStart();
    insertObject.ProfitCenter = this.state.ProfitCenter;
    insertObject.TransPeriod = this.state.Period;
    insertObject.Comments = this.state.Comments.trimEnd().trimStart();
    this.setState({ isLoading: true });
    axios
      .post(config.baseURL + "ExecuteSaveTransStoredProc", insertObject)
      .then((response) => {
        // handle success
        this.setState({ isLoading: false });

        document.getElementById("submittedPopup").style.display = "block";
        document.getElementById("div-overlay").style.display = "block";
      })
      .catch((error) => {
        // handle error
        this.setState({ isLoading: false });
        toast.error("Submit Failed", {
          autoClose: 10000,
        });
      });
    }
    catch(error)
    {
      console.log(error);
    }
  }

//this method handles functionlity when you close the submit popup
  closeSubmittedPopup() {
    try {
      document.getElementById("submittedPopup").style.display = "none";
      document.getElementById("div-overlay").style.display = "none";
      window.location.href = "/AdjustmentTransaction";
    } catch (error) {
      console.log(error);
    }
  }

//this method will handle inputChange event called when we enter data  and updte the required session attribute
  handleChangeValue = (e, type, property) => {
    try {
      if (type == "txt") {
        this.setState({ [property]: e.target.value });
      } else if (type == "date") {
        this.setState({ [property]: e });
        if (property === "PostingDate") {
          var period = "";
          var financialYear="";
          if (e != null) {
            
            var newDate = new Date(e);
            let month = String(newDate.getMonth() + 1);
            financialYear=String(newDate.getFullYear());
            this.bindCurrency(financialYear);
            if (month.length < 2) {
              period = "00" + month;
            } else {
              period = "0" + month;
            }
            this.setState({ Period: period,Fiscalyear:financialYear });
            
          } else {
            this.setState({ Period: period });
            this.setState({ Period: period,Fiscalyear:financialYear });
            this.setState({ ObjectCurrencyValue:"",ObjectCurrency:"" }); 
            this.setState({ ObjectCurrencyList: [] });
          }

        }
      } else {
        e.target.value =
        e.target.value.indexOf(".") >= 0
          ? e.target.value.substr(0, e.target.value.indexOf(".")) + e.target.value.substr(e.target.value.indexOf("."), 3)
          : e.target.value;
          
        this.setState({ [property]: e.target.value });
      }
    } catch (error) {
      console.log(error);
    }
  };

  //called when you move focus from input controls
  onBlurInput = (e, type, property) => {
    try {
      if (type == "txt") {
        if (
          property == "DocumnetNumber" ||
          property == "InvoiceDocumentNumber"
        ) {
          var regex = new RegExp("^[a-zA-Z0-9\b]+$");
          if (e.target.value != "") {
            if (regex.test(e.target.value)) {
              this.setState({ [property]: e.target.value });
            } else {
              this.setState({ [property]: "" });
              toast.error(messages.DocNumberValidation, {
                autoClose: 10000,
              });
            }
          } else {
            this.setState({ [property]: e.target.value });
          }
        } else if (property == "Fiscalyear") {
          var Fiscregex = new RegExp("^[0-9]+$");
          if (e.target.value != "") {
            if (Fiscregex.test(e.target.value)) {
              this.setState({ [property]: e.target.value });
            } else {
              this.setState({ [property]: "" });
              toast.error(messages.FiscalValidation, {
                autoClose: 10000,
              });
            }
          } else {
            this.setState({ [property]: e.target.value });
          }
        } 
       
        else {
          
            this.setState({ [property]: e.target.value });
          }
      }
      else{
        if(this.getLength(property)<=27)
          {
            this.setState({ [property]: e.target.value });
          }
          else{
            this.setState({ [property]:"" });
            toast.error(messages.numbervalidation, {
              autoClose: 10000,
            });
          }
      }
    } catch (error) {
      console.log(error);
    }
  };

 
//this method will handle dropdown Change event called when we select data and updte the required session attribute
  handleSelectChange = (label, value, property) => {
    try {
      this.setState({ [property]: label });
      if (property === "l1Code") {
        this.setState({
          AllocationCode: "",
          softwareDisplay: false,
          Software: "",
          AllocationCodeDesc: "",})
        this.bindL2Codes(label);
      } else if (property === "l2Code") {
        this.setState({
          AllocationCode: "",
          softwareDisplay: false,
          Software: "",
          AllocationCodeDesc: "",})
        this.setState({ MgcCode: value });
        this.getPdAllocationCode(value);
      } else if (property === "CostElementName") {
        this.setState({ CostElementValue: value });
        this.setState({ CostElemntSubCat: "" });
        this.getCostElementSubCategory(value);
      } else if (property === "CostCenterName") {
        this.setState({ CostCenterValue: value });
        this.setState({ RollUpScorecard: "" });
        this.setState({ ProfitCenter: "" });
        this.setState({ ITFunction: "" });
        this.getCostCenterRelatedData(value);
      }
      else if (property === "ObjectCurrency") {
        this.setState({ ObjectCurrencyValue: value });
        
      }
      
    } catch (error) {
      console.log(error);
    }
  };

//handles key down event form calender control	
  keyDownEvent = (event) => {
    try {
      const key = event.key;
      if (key === "Backspace" || key === "Delete") {
        return true;
      } else {
        event.preventDefault();
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };

//it will refresh the page
  RestControls = () => {
    window.location.href = "/AdjustmentTransaction";
  };

//this method will format the date to date string mm/dd/yyyy
  formattedDate = (d) => {
    if (d !== "" && d !== undefined) {
      let month = String(d.getMonth() + 1);
      let day = String(d.getDate());
      const year = String(d.getFullYear());

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return `${year}/${month}/${day}`;
    } else {
      return "";
    }
  };

//in this method ae are setting value for service Drop down based on session attribute
  setl1_values = () => {
    try {
      return this.state.L1Codes.filter((e) => e.label === this.state.l1Code);
    } catch (error) {
      console.log(error);
    }
  };

//in this method ae are setting value for service line Drop down based on session attribute
  setl2_values = () => {
    try {
      return this.state.L2Codes.filter((e) => e.label === this.state.l2Code);
    } catch (error) {
      console.log(error);
    }
  };

//in this method ae are setting value for software Drop down based on session attribute
  setSoftware_values = () => {
    try {
      return this.state.Softwares.filter(
        (e) => e.label === this.state.Software
      );
    } catch (error) {
      console.log(error);
    }
  };

//in this method ae are setting value for Cost Element Drop down based on session attribute
  setCostElementName_values = () => {
    try {
      return this.state.CostElement.filter(
        (e) => e.label === this.state.CostElementName
      );
    } catch (error) {
      console.log(error);
    }
  };

//in this method ae are setting value for Cost center Drop down based on session attribute
  setCostCenterName_values = () => {
    try {
      return this.state.CostCenter.filter(
        (e) => e.label === this.state.CostCenterName
      );
    } catch (error) {
      console.log(error);
    }
  };

//in this method ae are setting value for Object Currency Drop down based on session attribute
  setObjectCurrencyName_values = () => {
    try {
      return this.state.ObjectCurrencyList.filter(
        (e) => e.label === this.state.ObjectCurrency
      );
    } catch (error) {
      console.log(error);
    }
  };
  
//handles key down event form input control	
  onKeyDown = (event) => {
    var ASCIICode = event.which ? event.which : event.keyCode;
    //ASCIICode === 189 ||
    if (
      ASCIICode === 187 ||
      ASCIICode === 101 ||
      ASCIICode === 69 ||
      ASCIICode === 40 ||
      ASCIICode === 38
    ) {
      event.preventDefault();
      return false;
    }
    return true;
  };
  //this method will get last system genrated refference id and based on the refference id will validate format of refference id which user entered from UI and Update IsSystem genrated Flag
  getLastRefferenceId = () => {
    try {
      axios
        .get(config.baseURL + "getLastRefferenceID")
        .then((response) => {
          if (response.data.length > 0) {
            var str = response.data[0].PurchasingDocument.substring(11);
            var incrementNumber = parseInt(str) + 1;
            str =
              "BPC" +
              this.formattedUTCDate() +
              incrementNumber.toString().padStart(4, "0");
            this.setState({ PorefferenceId: str });
            this.setState({ SystemgeneratedRefID: str });
            this.setState({ isLoading: false });
          } else {
            str = "BPC" + this.formattedUTCDate() + "000" + 1;
            this.setState({ PorefferenceId: str });
            this.setState({ SystemgeneratedRefID: str });
            this.setState({ isLoading: false });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  //this method will format the date to date string mm/dd/yyyy
  formattedUTCDate = () => {
    let month = String(new Date().getUTCMonth() + 1);
    let day = String(new Date().getUTCDate());
    const year = String(new Date().getUTCFullYear());

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return `${year}${month}${day}`;
  };
//to retrun length of amount field value
  getLength = (property) => {
    switch (property) {
      case "Performance":
        return this.state.Performance.length;
      case "ValueAreaCurrency":
        return this.state.ValueAreaCurrency.length;
      case "StillToBeDeliveredValue":
        return this.state.StillToBeDeliveredValue.length;
      case "StillToBeDeliveredQty":
        return this.state.StillToBeDeliveredQty.length;
      case "GrossOrderValue":
        return this.state.GrossOrderValue.length;
      case "NetOrderValue":
        return this.state.NetOrderValue.length;
      case "ValueinObjectCurrency":
        return this.state.ValueinObjectCurrency.length;
      case "OrderQuantity":
        return this.state.OrderQuantity.length;
      case "NetPrice":
        return this.state.NetPrice.length;
        default:
          return 0;
    }
  };
//vallidate value is numeric or not
  isNumeric = (value) => {
    return /^-?\d+$/.test(value);
  };

//this method will get Service data and bind to required sesssion Attributes
  bindL1Codes = () => {
    try {
      axios
        .get(config.baseURL + "getL1Codes")
        .then((response) => {
          this.setState({ L1Codes: response.data });
          // if (!this.state.isEdit) {
          //   this.setState({ isLoading: false });
          // }
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isLoading: false });
        });
    } catch (error) {
      console.log(error);
    }
  };

//this method will get Service line data and bind to required sesssion Attributes
  bindL2Codes = (l1Code) => {
    try {
      this.setState({ l2Code: "" });
      this.setState({ MgcCode: "" });
      this.setState({ isddlLoading: true });
      axios
        .get(config.baseURL + "getL2Codes/" + l1Code)
        .then((response) => {
          this.setState({ L2Codes: response.data });
          this.setState({ isddlLoading: false });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isddlLoading: false });
        });
    } catch (error) {
      console.log(error);
    }
  };
//it will fetch currenct based of fscial year  from db and bind to required session attribute
  bindCurrency = (fisclyear) => {
    try {
      this.setState({ ObjectCurrencyValue:"USD",ObjectCurrency:"USD - United States" }); 
      this.setState({ ObjectCurrencyList: [] });
      this.setState({ iscurrencyddlLoading: true });
      axios
        .get(config.baseURL + "getCurrencyList/" + fisclyear)
        .then((response) => {
          this.setState({ ObjectCurrencyList: response.data });

          this.setState({ iscurrencyddlLoading: false });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ iscurrencyddlLoading: false });
        });
    } catch (error) {
      console.log(error);
    }
  };
  //it will fetch Allocation Codes from db and bind to required session attribute
  getPdAllocationCode = (MgcCode) => {
    try {
      axios
        .get(config.baseURL + "getPDAllocationCode/" + MgcCode)
        .then((response) => {
          if (response.data.length > 0) {
            this.setState({
              AllocationCode: response.data[0].AllocationCode,
              AllocationCodeDesc: response.data[0].AllocationDesc,
            });
            if (
              response.data[0].AllocationCode !== null &&
              response.data[0].AllocationCode !== undefined
            ) {
              if (response.data[0].AllocationCode.trimEnd() === "U4") {
                this.setState({
                  softwareDisplay: true,
                });
              } else {
                this.setState({
                  softwareDisplay: false,
                });
                this.setState({
                  Software: "",
                });
              }
            } else {
              this.setState({
                softwareDisplay: false,
              });
              this.setState({
                Software: "",
              });
            }
          } else {
            this.setState({
              softwareDisplay: false,
            });
            this.setState({
              Software: "",
            });
          }

          //this.setState({ isLoading: false });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  //it will fetch cost centers  from db and bind to required session attribute
  bindCostCenters = () => {
    try {
      axios
        .get(config.baseURL + "getCostCenters")
        .then((response) => {
          this.setState({ CostCenter: response.data });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isLoading: false });
        });
    } catch (error) {
      console.log(error);
    }
  };
  //it will fetch cost elements from db and bind to required session attribute
  bindCostElement = () => {
    try {
      axios
        .get(config.baseURL + "getCostElement")
        .then((response) => {
          this.setState({ CostElement: response.data });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isLoading: false });
        });
    } catch (error) {
      console.log(error);
    }
  };
  //it will fetch Cost Element Sub Category
  getCostElementSubCategory = (CostElement) => {
    try {
      axios
        .get(config.baseURL + "getCostElementSubCategory/" + CostElement)
        .then((response) => {
          if (response.data.length > 0) {
            this.setState({ CostElemntSubCat: response.data[0].SubCategory });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
//it will fetch Cost Center Related Data
  getCostCenterRelatedData = (CostCenter) => {
    try {
      axios
        .get(config.baseURL + "getCostCenterRelatedData/" + CostCenter)
        .then((response) => {
          if (response.data.length > 0) {
            this.setState({
              RollUpScorecard: response.data[0].RollupScorecard,
            });
            this.setState({ ProfitCenter: response.data[0].ProfitCenter });
            this.setState({ ITFunction: response.data[0].ITFunction });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
//it will rendor html for controls based on condotion
  getControl = () => {
    if (this.state.softwareDisplay) {
      return (
        <Select
          id="ddlSw"
          value={this.setSoftware_values()}
          options={this.state.Softwares}
          onChange={(e) => {
            this.handleSelectChange(e.label, e.value, "Software");
          }}
        />
      );
    } else {
      return (
        <input className="amountInput form-control" type="text" disabled />
      );
    }
  };
//thsi will validate amount fields should be greater than 0 or not
  validateAmountFields = () => {
    var result = true;
    if (
      this.state.Performance != "" &&
      this.state.Performance != null &&
      this.state.Performance != undefined
    ) {
      if (
        parseInt(this.state.ValueAreaCurrency) == 0 ||
        parseInt(this.state.Performance) == 0||
        parseInt(this.state.StillToBeDeliveredValue) == 0 ||
        parseInt(this.state.StillToBeDeliveredQty) == 0 ||
        parseInt(this.state.NetOrderValue) == 0 ||
        parseInt(this.state.GrossOrderValue) == 0 ||
        parseInt(this.state.ValueinObjectCurrency) == 0 ||
        parseInt(this.state.OrderQuantity) == 0 ||
        parseInt(this.state.NetPrice) == 0
      ) {
        result = false;
        toast.error(messages.NoZeroValidation, {
          autoClose: 10000,
        });
      }
    } else {
      result = false;
      toast.error(messages.validationMesage, {
        autoClose: 10000,
      });
    }

    return result;
  };
//this method will validate Entity already exixts or not
  validateEntityId = () => {
    var result = true;
    try {
      this.setState({ isLoading: true });
      var EntityId =
        this.state.DocumnetNumber + "_" + this.state.InvoiceDocumentNumber;
      axios
        .get(config.baseURL + "ValidateEntityId/" + EntityId)
        .then((response) => {
          if (response.data.length > 0) {
            if (response.data[0].COUNT > 0) {
              this.setState({ isLoading: false });
              toast.error(messages.duplicateEntityId, {
                autoClose: 10000,
              });
              result = false;
            }
            else{
                this.saveData();
            }
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log(error);
        });
    } catch (error) {
      this.setState({ isLoading: false });
      console.log(error);
    }
    return result;
  };
//this method will validate Fiscal Year is between 2020 to 2050
  validateFiscalYear = () => {
    var result = true;

    try {
      if (this.state.Fiscalyear != "") {
        if (
          parseInt(this.state.Fiscalyear) > 2050 ||
          parseInt(this.state.Fiscalyear) < 2020
        ) {
          toast.error(messages.FiscalYearValidation, {
            autoClose: 10000,
          });
          result = false;
        }
      } else {
        toast.error(messages.validationMesage, {
          autoClose: 10000,
        });
        result = false;
      }
    } catch (error) {
      this.setState({ isLoading: false });
      console.log(error);
    }
    return result;
  };

//this method validate the form based on business functionalities
  validate = () => {
    var result = true;
    try {
      if (
        this.state.PorefferenceId.trimEnd().trimStart() == "" ||
        this.state.PostingDate == "" ||
        this.state.PostingDate == null ||
        this.state.DocumnetNumber.trimEnd().trimStart() == "" ||
        this.state.InvoiceDocumentNumber.trimEnd().trimStart() == "" ||
        this.state.l1Code == "" ||
        this.state.l2Code == "" ||
        (this.state.softwareDisplay == true && this.state.Software == "") ||
        this.state.CostElementName == "" ||
        this.state.CostElementValue == "" ||
        this.state.CostCenterName == "" ||
        this.state.CostCenterValue == "" ||
        this.state.Comments.trimEnd().trimStart() == ""||
        this.state.ObjectCurrencyValue == ""
      ) {
        toast.error(messages.validationMesage, {
          autoClose: 10000,
        });
        result = false;
      }
    } catch (error) {
      this.setState({ isLoading: false });
      console.log(error);
    }
    return result;
  };

//this method will prepopulate the controls like fiscal year,period and Objecurrency in UI based on Posting Date
  prepopulatePostingDateRelatedData=()=>{
    try{
      let period="";
      var newDate = new Date();
      let month = String(newDate.getMonth() + 1);
      let financialYear=String(newDate.getFullYear());
      this.bindCurrency(financialYear);
      if (month.length < 2) {
        period = "00" + month;
      } else {
        period = "0" + month;
      }
      this.setState({ PostingDate:newDate,Period: period,Fiscalyear:financialYear });
    }
    catch(error)
    {
      console.log(error);
    }
  }

  updateState = (stateObj) => {
    this.setState(stateObj);
  };

  render() {
    return (
      <div>
        <LoadingOverlay active={this.state.isLoading} spinner>
          {this.state.isLoading === true ? (
            ""
          ) : (
            <div class="body-support-block">
              <NavBarComponent updateState={this.updateState} activeTab = 'AdjustmentTransaction'/>
              <div class="tabcontent1">
                <div class="container-fluid">
                  <div class="row">
                    <div class="cardsContainer">
                      <div class="responsive-table ClsSearchList">
                        <div class="ClstableList">
                          {" "}
                          <label for="lname">
                            PO/Reference Id<span class="required"> *</span>
                          </label>
                          <input
                            type="text"
                            maxLength="50"
                            value={this.state.PorefferenceId}
                            class="form-control"
                            onChange={(e) =>
                              this.handleChangeValue(e, "txt", "PorefferenceId")
                            }
                          />
                        </div>
                        <div class="ClstableList">
                          {" "}
                          <label for="lname">
                            Posting Date <span class="required"> *</span>
                          </label>
                          <DatePicker
                            className="form-control  my-icon mb-2 mr-sm-4"
                            placeholderText="mm/dd/yyyy"
                            selected={this.state.PostingDate}
                            onChange={(e) =>
                              this.handleChangeValue(e, "date", "PostingDate")
                            }
                            autoComplete="off"
                            onKeyDown={(e) => {
                              this.keyDownEvent(e);
                            }}
                            dateFormat="MM/dd/yyyy"
                            maxDate={moment().toDate()}
                            minDate={subMonths(new Date(), 24)}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            ref={(c) => (this._startCalendar = c)}
                            id="dpPostingDate"
                          />
                        </div>
                        <div class="ClstableList">
                          {" "}
                          <label for="lname">
                            Fiscal Year
                          </label>
                          <input
                            type="text"
                            maxLength="4"
                            value={this.state.Fiscalyear}
                            class="form-control"
                            // onChange={(e) =>
                            //   this.handleChangeValue(e, "txt", "Fiscalyear")
                            // }
                            // onBlur={(e) =>
                            //   this.onBlurInput(e, "txt", "Fiscalyear")
                            // }
                            disabled
                          />
                        </div>
                        <div class="ClstableList">
                          {" "}
                          <label for="lname">Document Date</label>
                          <DatePicker
                            className="form-control  my-icon mb-2 mr-sm-4"
                            placeholderText="mm/dd/yyyy"
                            selected={this.state.DocumentDate}
                            onChange={(e) =>
                              this.handleChangeValue(e, "date", "DocumentDate")
                            }
                            autoComplete="off"
                            onKeyDown={(e) => {
                              this.keyDownEvent(e);
                            }}
                            dateFormat="MM/dd/yyyy"
                            maxDate={moment().toDate()}
                            minDate={subMonths(new Date(), 24)}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            ref={(c) => (this._startCalendar = c)}
                            id="dpDocumentDate"
                          />
                        </div>
                        <div class="ClstableList">
                          {" "}
                          <label for="lname">
                            Document Number <span class="required"> *</span>
                          </label>
                          <input
                            type="text"
                            maxLength="10"
                            value={this.state.DocumnetNumber}
                            class="form-control"
                            onChange={(e) =>
                              this.handleChangeValue(e, "txt", "DocumnetNumber")
                            }
                            onBlur={(e) =>
                              this.onBlurInput(e, "txt", "DocumnetNumber")
                            }
                          />
                        </div>
                        <div class="ClstableList">
                          {" "}
                          <label for="lname">
                            Invoice Document Item{" "}
                            <span class="required"> *</span>
                          </label>
                          <input
                            type="text"
                            maxLength="3"
                            value={this.state.InvoiceDocumentNumber}
                            class="form-control"
                            onChange={(e) =>
                              this.handleChangeValue(
                                e,
                                "txt",
                                "InvoiceDocumentNumber"
                              )
                            }
                            onBlur={(e) =>
                              this.onBlurInput(
                                e,
                                "txt",
                                "InvoiceDocumentNumber"
                              )
                            }
                          />
                        </div>
                        
                      </div>
                      <div class="responsive-table ClsSearchList">
                        <div class="ClstableList">
                          {" "}
                          <label for="lname">
                            Service Line<span class="required"> *</span>
                          </label>
                          <Select
                            id="ddlL1"
                            options={this.state.L1Codes}
                            value={this.setl1_values()}
                            onChange={(e) =>
                              this.handleSelectChange(
                                e.label,
                                e.value,
                                "l1Code"
                              )
                            }
                          />
                        </div>
                        <div class="ClstableList">
                          {" "}
                          <label for="lname">
                            Service<span class="required"> *</span>
                          </label>
                          <Select
                            id="ddll2"
                            isLoading={this.state.isddlLoading}
                            options={this.state.L2Codes}
                            value={this.setl2_values()}
                            onChange={(e) =>
                              this.handleSelectChange(
                                e.label,
                                e.value,
                                "l2Code"
                              )
                            }
                          />
                        </div>
                        <div class="ClstableList">
                          {" "}
                          <label for="lname">Allocation Code</label>
                          <span
                            data-for="AllocDescPlugTrans"
                            data-tip={this.state.AllocationCodeDesc}
                          >
                            <input
                              type="text"
                              maxLength="100"
                              value={this.state.AllocationCode}
                              class="form-control"
                              disabled
                            />
                          </span>
                          <ReactTooltip
                            aria-haspopup="true"
                            role="example"
                            className="extraClass"
                            id="AllocDescPlugTrans"
                            multiline="true"
                            type="dark"
                          ></ReactTooltip>
                        </div>
                        <div class="ClstableList">
                          {" "}
                          <label for="lname">
                            Software <span class={this.state.softwareDisplay?"required":"hidden"}>*</span>
                          </label>
                          {this.getControl()}
                        </div>

                        <div class="ClstableList">
                          {" "}
                          <label for="lname">
                            Cost Element Name<span class="required"> *</span>
                          </label>
                          <Select
                            id="ddlCE"
                            options={this.state.CostElement}
                            value={this.setCostElementName_values()}
                            onChange={(e) =>
                              this.handleSelectChange(
                                e.label,
                                e.value,
                                "CostElementName"
                              )
                            }
                          />
                        </div>
                        <div class="ClstableList">
                          {" "}
                          <label for="lname">Cost Element Sub-Category</label>
                          <input
                            type="text"
                            maxLength="100"
                            value={this.state.CostElemntSubCat}
                            class="form-control"
                            disabled
                          />
                        </div>
                      </div>
                      <div class="responsive-table ClsSearchList">
                        <div class="ClstableList">
                          {" "}
                          <label for="lname">
                            Cost Center Name<span class="required"> *</span>
                          </label>
                          <Select
                            id="ddlCC"
                            options={this.state.CostCenter}
                            value={this.setCostCenterName_values()}
                            onChange={(e) =>
                              this.handleSelectChange(
                                e.label,
                                e.value,
                                "CostCenterName"
                              )
                            }
                          />
                        </div>

                        <div class="ClstableList">
                          {" "}
                          <label for="lname">Roll-up ScoreCard</label>
                          <input
                            type="text"
                            maxLength="100"
                            value={this.state.RollUpScorecard}
                            class="form-control"
                            disabled
                          />
                        </div>
                        <div class="ClstableList">
                          {" "}
                          <label for="lname">IT Function</label>
                          <input
                            type="text"
                            maxLength="100"
                            value={this.state.ITFunction}
                            class="form-control"
                            disabled
                          />
                        </div>
                        <div class="ClstableList">
                          {" "}
                          <label for="lname">Profit Center</label>
                          <input
                            type="text"
                            maxLength="100"
                            value={this.state.ProfitCenter}
                            class="form-control"
                            disabled
                          />
                        </div>
                        <div class="ClstableList">
                          {" "}
                          <label for="lname">
                            Performance $ <span class="required"> *</span>
                          </label>
                          <input
                            type="number"
                            autocomplete="off"
                            value={this.state.Performance}
                            class="form-control"
                            onChange={(e) =>
                              this.handleChangeValue(e, "number", "Performance")
                            }
                            onKeyDown={(e) => {
                              this.onKeyDown(e);
                            }}
                            onBlur={(e) =>
                              this.onBlurInput(e, "number", "Performance")
                            }
                           
                           
                          />
                        </div>

                        <div class="ClstableList">
                          {" "}
                          <label for="lname">Object Currency <span class="required"> *</span> </label>
                          <Select
                            id="ddlOBJC"
                            isLoading={this.state.iscurrencyddlLoading}
                            options={this.state.ObjectCurrencyList}
                            value={this.setObjectCurrencyName_values()}
                            onChange={(e) =>
                              this.handleSelectChange(
                                e.label,
                                e.value,
                                "ObjectCurrency"
                              )
                            }
                          />
                          
                         
                        </div>
                        
                      </div>
                      <div class="responsive-table ClsSearchList">
                      <div class="ClstableList">
                          {" "}
                          <label for="lname">
                            Value in Controlling Area Currency
                          </label>
                          <input
                            type="number"
                            autocomplete="off"
                            value={this.state.ValueAreaCurrency}
                            class="form-control"
                            onChange={(e) =>
                              this.handleChangeValue(
                                e,
                                "number",
                                "ValueAreaCurrency"
                              )
                            }
                            onKeyDown={(e) => {
                              this.onKeyDown(e);
                            }}
                            onBlur={(e) =>
                              this.onBlurInput(e, "number", "ValueAreaCurrency")
                            }
                          />
                        </div>
                        <div class="ClstableList">
                          {" "}
                          <label for="lname">Net Price</label>
                          <input
                            type="number"
                            autocomplete="off"
                            value={this.state.NetPrice}
                            class="form-control"
                            onChange={(e) =>
                              this.handleChangeValue(e, "number", "NetPrice")
                            }
                            onKeyDown={(e) => {
                              this.onKeyDown(e);
                            }}
                            onBlur={(e) =>
                              this.onBlurInput(e, "number", "NetPrice")
                            }
                          />
                        </div>

                        <div class="ClstableList">
                          {" "}
                          <label for="lname">Still to be Delivered Value</label>
                          <input
                            type="number"
                            autocomplete="off"
                            value={this.state.StillToBeDeliveredValue}
                            class="form-control"
                            onChange={(e) =>
                              this.handleChangeValue(
                                e,
                                "number",
                                "StillToBeDeliveredValue"
                              )
                            }
                            onKeyDown={(e) => {
                              this.onKeyDown(e);
                            }}
                            onBlur={(e) =>
                              this.onBlurInput(e, "number", "StillToBeDeliveredValue")
                            }
                          />
                        </div>
                        <div class="ClstableList">
                          {" "}
                          <label for="lname">
                            Still to be Delivered Quantity
                          </label>
                          <input
                            type="number"
                            autocomplete="off"
                            value={this.state.StillToBeDeliveredQty}
                            class="form-control"
                            onChange={(e) =>
                              this.handleChangeValue(
                                e,
                                "number",
                                "StillToBeDeliveredQty"
                              )
                            }
                            onKeyDown={(e) => {
                              this.onKeyDown(e);
                            }}
                            onBlur={(e) =>
                              this.onBlurInput(e, "number", "StillToBeDeliveredQty")
                            }
                          />
                        </div>

                        <div class="ClstableList">
                          {" "}
                          <label for="lname">Gross Order Value</label>
                          <input
                            type="number"
                            autocomplete="off"
                            value={this.state.GrossOrderValue}
                            class="form-control"
                            onChange={(e) =>
                              this.handleChangeValue(
                                e,
                                "number",
                                "GrossOrderValue"
                              )
                            }
                            onKeyDown={(e) => {
                              this.onKeyDown(e);
                            }}
                            onBlur={(e) =>
                              this.onBlurInput(e, "number", "GrossOrderValue")
                            }
                          />
                        </div>
                        <div class="ClstableList">
                          {" "}
                          <label for="lname">Net Order Value</label>
                          <input
                            type="number"
                            autocomplete="off"
                            value={this.state.NetOrderValue}
                            class="form-control"
                            onChange={(e) =>
                              this.handleChangeValue(
                                e,
                                "number",
                                "NetOrderValue"
                              )
                            }
                            onKeyDown={(e) => {
                              this.onKeyDown(e);
                            }}
                            onBlur={(e) =>
                              this.onBlurInput(e, "number", "NetOrderValue")
                            }
                          />
                        </div>
                      </div>
                      <div class="responsive-table ClsSearchList">
                        <div class="ClstableList">
                          {" "}
                          <label for="lname">Value In Object Currency</label>
                          <input
                            type="number"
                            autocomplete="off"
                            value={this.state.ValueinObjectCurrency}
                            class="form-control"
                            onChange={(e) =>
                              this.handleChangeValue(
                                e,
                                "number",
                                "ValueinObjectCurrency"
                              )
                            }
                            onKeyDown={(e) => {
                              this.onKeyDown(e);
                            }}
                            onBlur={(e) =>
                              this.onBlurInput(e, "number", "ValueinObjectCurrency")
                            }
                          />
                        </div>
                        <div class="ClstableList">
                          {" "}
                          <label for="lname">Currency</label>
                          <input
                            type="text"
                            value={this.state.Currency}
                            class="form-control"
                            disabled
                          />
                        </div>
                        <div class="ClstableList">
                          {" "}
                          <label for="lname">Order Quantity</label>
                          <input
                            type="number"
                            autocomplete="off"
                            value={this.state.OrderQuantity}
                            class="form-control"
                            onChange={(e) =>
                              this.handleChangeValue(
                                e,
                                "number",
                                "OrderQuantity"
                              )
                            }
                            onKeyDown={(e) => {
                              this.onKeyDown(e);
                            }}
                            onBlur={(e) =>
                              this.onBlurInput(e, "number", "OrderQuantity")
                            }
                          />
                        </div>
                        <div class="ClstableList">
                          {" "}
                          <label for="lname">Order Unit</label>
                          <input
                            maxLength={3}
                            type="text"
                            autocomplete="off"
                            value={this.state.OrderUnit}
                            class="form-control"
                            onChange={(e) =>
                              this.handleChangeValue(e, "txt", "OrderUnit")
                            }
                          />
                        </div>
                        <div class="ClstableList">
                          {" "}
                          <label for="lname">Short Text</label>
                          <input
                            maxLength={1500}
                            type="text"
                            autocomplete="off"
                            value={this.state.ShortText}
                            class="form-control"
                            onChange={(e) =>
                              this.handleChangeValue(e, "txt", "ShortText")
                            }
                          />
                        </div>
                        <div class="ClstableList">
                          {" "}
                          <label for="lname">
                            Price Date
                          </label>
                          <DatePicker
                            className="form-control  my-icon mb-2 mr-sm-4"
                            placeholderText="mm/dd/yyyy"
                            selected={this.state.PriceDate}
                            onChange={(e) =>
                              this.handleChangeValue(e, "date", "PriceDate")
                            }
                            autoComplete="off"
                            onKeyDown={(e) => {
                              this.keyDownEvent(e);
                            }}
                            dateFormat="MM/dd/yyyy"
                            maxDate={moment().toDate()}
                            minDate={subMonths(new Date(), 24)}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            ref={(c) => (this._startCalendar = c)}
                            id="dpStartDate"
                          />
                        </div>
                      </div>
                      <div class="responsive-table ClsSearchList">
                        <div class="ClstableList">
                          {" "}
                          <label for="lname">Period</label>
                          <input
                            type="text"
                            maxLength="3"
                            value={this.state.Period}
                            class="form-control"
                            disabled
                          />
                        </div>
                        <div class="ClsPlugTranscationCommentsArea">
                          <div>
                            {" "}
                            <label for="lname">
                              Comments<span class="required"> *</span>
                            </label>{" "}
                            <textarea
                              rows="4"
                              cols="50"
                              maxLength="3000"
                              value={this.state.Comments}
                              name="comment"
                              form="usrform"
                              class="form-control"
                              onChange={(e) => {
                                this.handleChangeValue(e, "txt", "Comments");
                              }}
                            >
                              {" "}
                            </textarea>{" "}
                          </div>
                        </div>

                        <div
                          style={{ marginRight: "0px" }}
                          className={"PlugTranscationButtons"}
                        >
                          <button
                            class="Clsbutton ClsSearch"
                            onClick={() => this.submitData()}
                          >
                            Save
                          </button>
                          <button
                            class="Clsbutton ClsReset"
                            onClick={() => this.RestControls()}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </LoadingOverlay>

        <div className="modal show" id="submittedPopup">
          <div class="modal-dialog modal-success" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div class="successMessageMain">
                  <img src={popupIcon} alt="popup" />
                  <h4>Successfully Updated!</h4>
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => this.closeSubmittedPopup()}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
