import React, { Component } from "react";
import * as config from "../../config";
import * as messages from "../../messages.js";
import axios from "../../api.js";
import popupIcon from "../SplitComponent/tick-icon.png";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { msalInstance } from "../../authConfig";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
//import TextField from "@material-ui/core/TextField";
//import Autocomplete from "@material-ui/lab/Autocomplete";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Select from "react-select";
import DataTable from "react-data-table-component";
import warningIcon from "../MCGL2MappingComponent/Warning.png";
import SharedModel from '../SharedModel/Model';
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import NavBarComponent from "../NavBarComponent/NavBarComponent.jsx";

toast.configure();

export default class AssignRule extends Component {
  constructor() {
    super();
    this.state = {
      transId: "",
      isLoading: false,
      PoNumber: "",
      ShowDetails: true,
      ShowUnAssignedTransactions: true,
      ShowAssignButton: true,
      ExixtingRules: [],
      ExistingComments: {},
      PreviousProportions: [],
      POInfo: {},
      ShowPoVendorSearch: true,
      PoNumberorvendorName: "",
      filterData: [],
      selectedRows: [],
      IsReset: true,
      transctionData: [],
      showLoadinggif: false,
      show: false,
      ListPOs:[],
      searchInput:"",
      selectPO:"",

    };
  }

  componentDidMount = () => {
     this.getSerchPOswithVendorname()
  };

  // this will get name for the loggedin user
  getName = () => {
    try {
      var email =
        msalInstance.getAllAccounts()[0] !== null
          ? msalInstance.getAllAccounts()[0].username
          : "";

      return email;
    } catch (error) {
      console.log(error);
    }
  };

  closePopup = () => {
    try {
      this.setState({ show: false })
    }
    catch (error) {
      console.log(error)
    }
  }
  top100Films = [
    { title: "The Shawshank Redemption", year: 1994 },
    { title: "The Godfather", year: 1972 },
    { title: "The Godfather: Part II", year: 1974 },
    { title: "The Dark Knight", year: 2008 },
  ]
  //get Unassigned list data from db by calling an API
  getUnassignedListForAssignRule = async () => {
    try {

      if (this.state.PoNumberorvendorName.trimEnd().trimStart() != "") {
        this.setState({ isLoading: true });
        var obj = {
          SearchParam: this.state.PoNumberorvendorName,
        };
        axios
          .post(config.baseURL + "getUnAssignedPolistForAssignedRule", obj)
          .then((response) => {
            if (response.data[0].length > 0) {
              this.setState({ filterData: response.data[0] });
              this.setState({ ShowUnAssignedTransactions: false });
              this.setState({ ShowAssignButton: false });
              this.setState({ isLoading: false });
            } else {
              this.setState({ isLoading: false, ShowAssignButton: true, });
              this.setState({ filterData: [] });

              toast.error(messages.NoTransactions, {
                autoClose: 10000,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({ isLoading: false });
          });
      } else {
        toast.error(messages.PoVendorRequired, {
          autoClose: 10000,
        });
      }

    } catch (error) {
      console.log(error);
    }
  };

  validate = () => {

    if (
      this.state.transId != null &&
      this.state.transId != undefined &&
      this.state.transId != ""
    ) {
      if (this.state.selectedRows.length > 0) {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="conformDiv">
                <div class="WarningMessageMain">
                  <img src={warningIcon} alt="warning" />
                  <h4>Warning!</h4>
                </div>
                <p className="conformBody" style={{ textAlign: "center" }}>
                  Are you sure you want to assign rule to the selected
                  transactions?
                </p>
                <div className="ClsSearchPOsButton">
                  <button
                    onClick={() => {
                      onClose();
                      this.setState({ isLoading: true });
                      let entityArray = [];
                      this.state.selectedRows.forEach((row) => {
                        var obj = { EntityId: row.EntityId };
                        entityArray.push(obj);
                      });
                      var AssignRuleObject = {
                        transId: this.state.transId,
                        splitData: entityArray,
                        createdBy: this.getName(),
                        comments: "Transaction was processed from GUI using the rule for PO " + this.state.PoNumber,
                      };
                      axios
                        .post(
                          config.baseURL + "AssignRuleUpdate",
                          AssignRuleObject
                        )
                        .then((response) => {
                          // handle success
                          this.setState({ isLoading: false });
                          document.getElementById(
                            "submittedPopup"
                          ).style.display = "block";
                          document.getElementById(
                            "div-overlay"
                          ).style.display = "block";
                        })
                        .catch((error) => {
                          // handle error
                          console.log(error);
                          this.setState({ isLoading: false });
                        });
                    }}
                    className="Clsbutton ClsSearch"
                  >
                    Yes
                  </button>
                  <button onClick={onClose} className="Clsbutton ClsReset">
                    No
                  </button>
                </div>
              </div>
            );
          },
        });
      } else {
        toast.error(messages.TransactionRequired, {
          autoClose: 10000,
        });
      }
    } else {
      toast.error(messages.RuleRequired, {
        autoClose: 10000,
      });
    }

  };

  //this method handles functionlity when you close the submit popup
  closeSubmittedPopup() {
    try {
      document.getElementById("submittedPopup").style.display = "none";
      document.getElementById("div-overlay").style.display = "none";
      window.location.href = "/AssignRule";
    } catch (error) {
      console.log(error);
    }
  }

  //halde cancel click in the page
  CancelClick = () => {
    try {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="conformDiv">
              <p className="conformBody">
                If you cancel then your changes will be lost. Are you sure you
                want to cancel?
              </p>
              <div className="ClsSearchPOsButton">
                <button
                  onClick={() => {
                    window.location.href = "/AssignRule";
                  }}
                  className="Clsbutton ClsSearch"
                >
                  Yes
                </button>
                <button onClick={onClose} className="Clsbutton ClsReset">
                  No
                </button>
              </div>
            </div>
          );
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  
  //this method will handle inputChange event called when we enter data and updte the required session attribute
  handleChagneValue = (e, ctrlName) => {
    if (ctrlName == "SearchRule") {
      if (e != "") {
        this.setState({ PoNumber: e });
      } else {
        this.ResetSearchRule();
      }
    } else {
      if (e != "") {
        this.setState({ PoNumberorvendorName: e });
      } else {
        this.RestControls();
      }
    }
  };
  //method will search for rule in db based on Po number entered by user
  SearchForPO = () => {
    const select1= this.state.PoNumber.slice(0, this.state.PoNumber.indexOf('('));
    this.setState({PoNumber:select1})
    if (this.state.PoNumber.trimEnd().trimStart() != "") {
      var obj = {
        PoNumber: this.state.PoNumber,
      };
      this.setState({ isLoading: true });
      this.setState({ selectedRows: [] });
      axios
        .post(config.baseURL + "getPropotions", obj)
        .then((response) => {
          if (
            response.data != null &&
            response.data != undefined &&
            response.data[1].length > 0
          ) {
            this.setState({ POInfo: response.data[0][0] });
            this.setState({searchInput:this.state.POInfo.PONumber});
            var Exixtingarray = response.data[1].filter(
              (w) => w.IsExisting == 1
            );
            var cmntsExixts = Exixtingarray[0];
            this.setState({ transId: cmntsExixts.EntityID });
            this.setState({ ExistingComments: cmntsExixts });
            this.setState({ ExixtingRules: Exixtingarray });
            this.setState({ ShowDetails: false });
            this.setState({ ShowPoVendorSearch: false });
            this.setState({ IsReset: false });

            this.setState({ isLoading: false });
          } else {
            this.setState({
              POInfo: {},
              transId: "",
              ExistingComments: {},
              ExixtingRules: [],
              selectedRows: [],
              IsReset: true,
            });
            toast.error(messages.NoPoFound, {
              autoClose: 10000,
            });
            this.setState({ isLoading: false });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log(error);
        });
    } else {
      toast.error(messages.PoRequired, {
        autoClose: 10000,
      });
    }
  };
  //this will clear all session attribute values
  RestControls = () => {
    this.setState({
      PoNumberorvendorName: "",
      ShowAssignButton: true,
      filterData: [],
      selectedRows: [],

    });
  };

  bindPoPUpData = (transId, linenumber) => {
    this.setState({ showLoadinggif: true, transctionData: [] });
    var obj = {
      entityId: transId,
      linenumber: linenumber,
      UserName: this.getName(),
    };
    axios
      .post(config.baseURL + "getPODetails", obj)
      .then((response) => {
        console.log(response);
        this.setState({ transctionData: response.data[0] });
        this.setState({ showLoadinggif: false, show: true });

      })
      .catch((error) => {
        console.log(error);
        this.setState({ showLoadinggif: false });
      });
  };

  //Will define columns for datatable
  columns = [
    {
      name: "PO/Reference Id",
      selector: "PurchasingDocument",
      sortable: true,
      wrap:true,
      cell: (row) =>
        <a href="#"
          onClick={(e) => {
            this.bindPoPUpData(row.EntityId, row.LineNo);
          }}

        >
          {row.PurchasingDocument}
        </a>

    },
    {
      name: "Vendor Name",
      selector: "VendorName",
      sortable: true,
      wrap:true,
    },
    {
      name: "Cost Center Name",
      selector: "CostCenterName",
      sortable: true,
      wrap:true,
    },
    {
      name: "Cost Element Name",
      selector: "CostElementName",
      sortable: true,
      wrap:true,
    },

  ];

  updateState = (state) => {
    this.setState({ selectedRows: state.selectedRows }); // triggers MyComponent to re-render with new state
  };




  handleOnSelect = (v) => {
    this.setState({searchInput:v})
    if(v !== null){
    this.setState({PoNumber:v.slice(0, v.indexOf('('))})
    console.log(this.state.PoNumber,"selectPO")
    }
  };




  //this will clear all session attribute values
  getUnAssignedTransByPOorVendor = () => {
    return (
      <DataTable
        columns={this.columns}
        data={this.state.filterData}
        defaultSortField="id"
        defaultSortAsc={true}
        highlightOnHover
        sort
        selectableRows={true}
        onSelectedRowsChange={this.updateState}
      //  customStyles={this.customStyles}
        
      />
    );
  };


  //this will clear all session attribute values related to LHS
  ResetSearchRule = () => {
    try {
      this.setState({
        POInfo: {},
        transId: "",
        PoNumber: "",
        ExistingComments: {},
        ExixtingRules: [],
        selectedRows: [],
        IsReset: true,
        searchInput:[],
      });
    } catch (error) {
      console.log(error);
    }
  };
   // getting vendor Name 
   getSerchPOswithVendorname = () => {
    this.setState({ isLoading: true });
    axios
      .get(config.baseURL + "getPOsForSearchRule")
      .then((response) => {
        console.log(response, "resss");
        this.setState({ ListPOs: response.data[0] });
        this.setState({ ListPOs: response.data[0] });
        this.setState(
          this.state.ListPOs.map((i) => ({
            PurchasingDocument:i.label,
            VendorName:i.value
          }))
        )
        this.setState({ isLoading: false });
        console.log(this.state.ListPOs, "response.data")
        let data= this.state.ListPOs?.map((option) => ({
          label: option.PurchasingDocument,
          value: option.VendorName,
        }))
        this.setState({ListPOs:data})
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  };

  updateState = (stateObj) => {
    this.setState(stateObj);
  };

  render() {
    return (
      <div>
        <LoadingOverlay active={this.state.isLoading} spinner>
          {this.state.isLoading === true ? (
            ""
          ) : (
            <div class="body-support-block">
              <NavBarComponent updateState={this.updateState} activeTab = 'AssignRule'/>
              <div class="tabcontent1">
                <div class="container-fluid">
                  <div class="row">
                    <div class="cardsContainerAssignRule">
                      <div class="responsive-table ClsSearchList">
                        <div class="ClstableListAssignRule reactautofill">
                          {" "}
                          <label for="lname">Search for a Rule(PO Id/Vendor Name)</label>
                          <Autocomplete
                                   className=" reactautofill autofilltextarea"
                                  freeSolo
                                  id="free-solo-demo"
                                  value={this.state.searchInput}
                                  autoHighlight
                                  autoSelect={true}
                                  options={ this.state.ListPOs.map((option) => ((option.label) + '(' + (option.value) + `)`))}
                                  onChange={(e, v) => this.handleOnSelect(v)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                     
                                      label=""
                                      variant="outlined"
                                      placeholder=""
                                      size="small"
                                    />
                                  )}
                                />
                    
                        </div>
                        <div
                          style={{ marginRight: "0px" }}
                          className={"updateRuleSearch"}
                        >
                          <button
                            class="Clsbutton ClsSearch"
                            style={{ marginLeft: "10px" }}
                            onClick={() => this.SearchForPO()}
                          >
                            Search
                          </button>
                          <button
                            class="Clsbutton ClsReset"
                            onClick={() => this.ResetSearchRule()}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        this.state.ShowPoVendorSearch
                          ? "hideDiv"
                          : "cardsContainerAssignRule"
                      }
                    >
                      <div class="responsive-table ClsSearchList">
                        <div class="ClstableListAssignRule">
                          {" "}
                          <label for="lname">PO Id/Vendor Name/Cost Center/Cost Element</label>
                          <input
                            type="text"
                            maxLength="100"
                            onChange={(e) =>
                              this.handleChagneValue(e.target.value, "SearchPo")
                            }
                            value={this.state.PoNumberorvendorName}
                            class="form-control"
                          />
                        </div>

                        <div
                          style={{ marginRight: "0px" }}
                          className={"updateRuleSearch"}
                        >
                          <button
                            class="Clsbutton ClsSearch"
                            style={{ marginLeft: "10px" }}
                            onClick={() =>
                              this.getUnassignedListForAssignRule()
                            }
                          >
                            Search
                          </button>
                          <button
                            class="Clsbutton ClsReset"
                            onClick={() => this.RestControls()}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="Unassigned" className="tabcontent tabcontent1">
                <div class="container-fluid splitAssignContainerFluid">
                  <div class="row">
                    <div
                      className={
                        this.state.ShowDetails
                          ? "hideDiv"
                          : "cardsContainerAssignRule"
                      }
                    >
                      <div class="responsive-table">
                        <div class="ClsSplitTitle">Rule Details</div>
                        <div className={
                          this.state.IsReset
                            ? "hideDiv"
                            : this.state.ShowUnAssignedTransactions ? "ClsCurrenctRuleDivwithTxn ClsUpdateRuleLeftAssignLHS" : this.state.ShowAssignButton ? "ClsCurrenctRuleDivwithTxn ClsUpdateRuleLeftAssignLHS" : "ClsCurrenctRuleDiv ClsUpdateRuleLeftAssignLHS"
                        }>

                          <div
                            className={
                              this.state.IsReset ? "hideDiv" : "container-fluid"
                            }
                          >
                            <div class="row">
                              <div class="ruleDetailsAssign">
                                <ul style={{ padding: "0px" }}>
                                  <li>
                                    <span className="valueFontAssignScreen">Vendor Name</span>
                                    <span>
                                      {this.state.POInfo["Vendor Name"]}
                                    </span>{" "}
                                  </li>
                                  <li>
                                    <span className="valueFontAssignScreen">Cost Center</span>
                                    <span>
                                      {this.state.POInfo["Cost Center"]}
                                    </span>{" "}
                                  </li>
                                  <li>
                                    <span className="valueFontAssignScreen">Cost Center Name</span>
                                    <span>
                                      {this.state.POInfo["Cost Center Name"]}
                                    </span>{" "}
                                  </li>
                                  <li>
                                    <span className="valueFontAssignScreen">Cost Element</span>
                                    <span>
                                      {this.state.POInfo["Cost Element"]}
                                    </span>{" "}
                                  </li>

                                  <li>
                                    <span className="valueFontAssignScreen">Cost Element Desc</span>
                                    <span>
                                      {this.state.POInfo["Cost Element Desc"]}
                                    </span>{" "}
                                  </li>
                                  <li>
                                    <span></span>
                                    <span></span>{" "}
                                  </li>
                                </ul>
                              </div>
                            </div>

                          </div>
                          <div>
                            <table
                              className="table"
                              style={{ marginLeft: "" }}
                            >
                              <thead className="splittablehead">
                                <tr>
                                  <td class="ruletableFonts">
                                    <label for="lname">Ratio (%) </label>
                                  </td>
                                  <td className="ruletableFonts">
                                    {" "}
                                    <label for="lname">Service Line </label>
                                  </td>
                                  <td className="ruletableFonts">
                                    {" "}
                                    <label for="lname">Service</label>
                                  </td>
                                  <td className="ruletableFonts">
                                    {" "}
                                    <label for="lname">Allocation Code</label>
                                  </td>
                                </tr>
                              </thead>

                              <tbody>
                                {this.state.ExixtingRules.map((Social, idx) => (
                                  <tr>
                                    <td>
                                      <span>{Social.Ratio}</span>
                                    </td>
                                    <td className="">
                                      <span>{Social.ServiceLineL1}</span>
                                    </td>
                                    <td className="">
                                      <span>{Social.ServiceL2}</span>
                                    </td>
                                    <td className="">
                                      <span>{Social.AllocationCode}</span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div class="">
                              <div class="commentSingle commentSpacing">
                                <div class="row">
                                  <div class="col-md-8">
                                    <h4>
                                      {this.state.ExistingComments.CreatedBy}
                                    </h4>
                                    <p class="textBreak">
                                      {this.state.ExistingComments.Comment}
                                    </p>
                                  </div>
                                  <div class="col-md-4">
                                    <p class="commentDate">
                                      {this.state.ExistingComments.CreatedDate}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        this.state.ShowUnAssignedTransactions
                          ? "hideDiv"
                          : "cardsContainerAssignRule"
                      }
                    >
                      <div class="responsive-table tableAssignRuleTXN">
                        <div class="ClsAsignRuletTitle">PO Details</div>
                        <div
                          style={{ marginRight: "0px" }}
                          className={
                            this.state.ShowAssignButton
                              ? "hideDiv"
                              : "assignRuleFooter"
                          }

                        >
                          <button
                            class="Clsbutton ClsSearch"
                            onClick={() => this.validate()}
                          >
                            Assign Rule
                          </button>
                          <button
                            class="Clsbutton ClsReset"
                            onClick={() => this.CancelClick()}
                          >
                            Cancel
                          </button>
                        </div>
                        <div class="ClsUpdateRuleRightAssignRHS">
                          {this.getUnAssignedTransByPOorVendor()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          )}
        </LoadingOverlay>
        <SharedModel show={this.state.show} closePopup={this.closePopup} tableData={this.state.transctionData} showLoadinggif={this.state.showLoadinggif}>

        </SharedModel>
        <div className="modal show" id="submittedPopup">
          <div class="modal-dialog modal-success" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div class="successMessageMain">
                  <img src={popupIcon} alt="popup" />
                  <h4>Successfully Updated!</h4>
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => this.closeSubmittedPopup()}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
