import axios from '../../api.js';
import React, { Component } from "react";
import * as config from "../../config";
import DataTable from "react-data-table-component";
import LoadingOverlay from "react-loading-overlay";
import DataTableExtensions from "react-data-table-component-extensions";
import buttonIcon from "../UnassignedPOList/Split-assign-icon.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as messages from "../../messages.js";
import { msalInstance } from "../../authConfig";
import popupIcon from "../SplitComponent/tick-icon.png";
import NavBarComponent from "../NavBarComponent/NavBarComponent.jsx";

toast.configure();
export default class MCGListList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      viewRegistrations: [],
      filterData: [],
      isLoading: true,
      columns: [],
      selectedFiles:[]
    };

    
  }

//Will define columns for datatable
columns = [
  {
    name: "Parent MGC (TBM)",
    selector: row => row.MgcCode,
    sortable: true,
    wrap: true,
    width: "150px"
  },
  {
    name: "Child MGC Codes",
    selector: row => row.ChildMGC,
    sortable: true,
    wrap:true,
  },
  {
    name: " Parent MGC Description",
    wrap: true,
    selector: row => row.MGCDescription,
    sortable: true,
  },
  {
    name: "Service Line",
    selector: row => row.ServiceLineL1,
    sortable: true,
    wrap: true,
    width: "180px",
  },
  {
    name: "Service",
    selector: row => row.ServiceL2,
    sortable: true,
    wrap: true,
  },
  {
    name: "Allocation Code",
    selector: row => row.AllocationCode,
    sortable: true,
    wrap: true,
  },
  {
    name: "",
    selector: "",
    width: "110px",
    cell: (row) => (
      <button
        className="btn btn-primary"
        onClick={(e) => {
          window.location.href = "/EditMGC?id=" + row.MgcCode;
        }}
      >
        <img style={{ width: "33px" }} src={buttonIcon} alt="edit" />
      </button>
    ),
  },
];
  //this method will call when page loads and load data to required controls
  componentDidMount() {
		this._isMounted = true;
	  this.getMGCList();
    
	}
  //this method will called when page unloads
  componentWillUnmount() {
		this._isMounted = false;
	}
//this method will get list of MGC related data
  getMGCList= async ()=>
  {
    try
    {
      const getMGCListData=async () => {
        axios
        .get(config.baseURL + "getMCGL2MappingList")
        .then((response) => {
          
          
          this.setState({ data: response.data });
          this.setState({ viewRegistrations: response.data });
          this.setState({ filterData: response.data });
          this.setState({ isLoading: false });

          if (JSON.parse(sessionStorage.getItem("row")).length > 0) {
            this.setState({ data: JSON.parse(sessionStorage.getItem("row")) });
          }
          else {
            this.setState({ data: response.data });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isLoading: false });
        });
      }
      await getMGCListData();
    }
    catch(error)
    {

    }
  }

  customStyles = {
  
    rows: {
      style: {
        minHeight: '60px',
        fontSize: '14px',
        wordBreak: 'break-all'
      },
    },
    cells: {
      style: {
        '&:not(:last-of-type)': {
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          borderRightColor: "rgba(0,0,0,.12)"
        },
      },
    },
  };
//will create data table and render on page 
  getTableData = () => {
    return (
      <DataTableExtensions
        columns={this.columns}
        data={this.state.data}
        export={false}
        print={false}
        filter={false}
      >
        <DataTable
          pagination
          highlightOnHover
          paginationPerPage={20}
         sortFunction={this.customSort}
          paginationRowsPerPageOptions={[20,50,100,200]}
          customStyles={this.customStyles}
        />
      </DataTableExtensions>
    );
  };

  customSort(rows, selector, direction) {
    let sorted = rows.sort((rowA, rowB) => {
      // use the selector function to resolve your field names by passing the sort comparitors
      const aField = selector(rowA)
      const bField = selector(rowB)
      let comparison = 0;
      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }
      return direction === 'desc' ? comparison * -1 : comparison;
    });
    sessionStorage.setItem("row", JSON.stringify(sorted));
    return sorted;

  }

  // custom search
  globalSearch = (value) => {
    sessionStorage.setItem("searchInputMGC", value);
    let searchInput = value;
    const searchText = searchInput.trim().toLowerCase();
    this.setState({ searchText: searchText });
    let searchData = this.state.filterData.filter(
      (value) =>
        (!!value.MgcCode && value.MgcCode.toLowerCase().includes(searchText)) ||
        (!!value.ChildMGC && value.ChildMGC.toLowerCase().includes(searchText)) ||
        (!!value.MGCDescription &&
          value.MGCDescription.toLowerCase().includes(searchText)) ||
        (!!value.ServiceLineL1 &&
          value.ServiceLineL1.toLowerCase().includes(searchText)) ||
        (!!value.ServiceL2 &&
          value.ServiceL2.toLowerCase().includes(searchText)) ||
        (!!value.AllocationCode &&
          value.AllocationCode.toLowerCase().includes(searchText))
    );

    this.setState({ data: searchData });
    sessionStorage.setItem("row", JSON.stringify(searchData));

  };
//this method will export list of MGC related data
  exportDataToExcel=()=> {
    
    window.location.href = config.baseURL+'ExportMGCAttributes/'+this.getName();

 }
// this will get name for the loggedin user
getName = () => {
  try {
    var email =
      msalInstance.getAllAccounts()[0] !== null
        ? msalInstance.getAllAccounts()[0].username
        : "";
    

    return email;
  } catch (error) {
    console.log(error);
  }
};
//it will clear the file which save in session attribute
resetSelectedFiles(e) {
  e.target.value = null;
}
//method will convert file to base 64 string
getBase64(file) {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
  });
}
//this method will import excel file from UI and update the data to db bycalling API
uploadAttachment(e, el) {
  
  const files = e.target.files;
  var obj={};
  for (let i = 0; i < files.length; i++) {
    let file = e.target.files[i];
    let fileName = file.name;
    let fileType = file.type;
    if (this.validateExtension(file)) {
      this.getBase64(file).then((data) => {
         obj = {
          attachmentid: 0,
          documentname: fileName,
          documenttype: "MCGAttributeList",
          documenturl: "",
          documentformat: fileType,
          fileContent: data.split(";base64,")[1],
          isdeleted: false,
          Username:this.getName()
        };
        this.setState({ selectedFiles: obj }); 
        this.setState({ isLoading: true });
        axios
        .post(config.baseURL + "excelImport", obj)
        .then((response) => {
          
          // handle success
         var res=response.data;
         this.setState({ isLoading: false });
         if(res.length>0)
          {
            var msgTxt="";
            const myArr = res[0].value.split(";");
            
            switch(myArr[0])
            {
              case "ERRATTR100":
                  msgTxt=messages.ERRATTR100;
                  break;
                  case "ERRATTR101":
                  msgTxt=messages.ERRATTR101;
                  break;
                  case "ERRATTR102":
                  msgTxt=messages.ERRATTR102;
                  break;
                  case "ERRMGC103":
                  msgTxt=messages.ERRMGC103;
                  break;
                  case "ERRMGC104":
                  msgTxt=messages.ERRMGC104+myArr[1]+" - unable to import";
                  break;
                  case "ERRMGC105":
                  msgTxt=messages.ERRMGC105;
                  break;
                  case "ERRMGC106":
                  msgTxt=messages.ERRMGC106;
                  break;
                  default:
                    msgTxt=res[0].value;
                    break;

            }
            toast.error(msgTxt,{
                autoClose: 10000,
             });
            
           
          }
          else{
           document.getElementById("submittedPopup").style.display = "block";
           document.getElementById("div-overlay").style.display = "block";
          }
        })
        .catch((error) => {
          // handle error
          
          this.setState({ isLoading: false });
        });
      });
    } 
    else {
      toast.error(messages.invalidExcel,{
        autoClose: 10000,
      });
    }

  }
  
}
//this method handles functionlity when you close the submit popup
closeSubmittedPopup() {
  try {
    document.getElementById("submittedPopup").style.display = "none";
    document.getElementById("div-overlay").style.display = "none";
    this.setState({ isLoading: true });
    axios
      .get(config.baseURL + "getMCGL2MappingList")
      .then((response) => {
        
        this.setState({ data: response.data });
        this.setState({ viewRegistrations: response.data });
        this.setState({ filterData: response.data });
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  } catch (error) {
    console.log(error);
  }
}
//this method validate the form based on business functionalities
validateExtension(inputFile) {
  const validFileExtensions = ["xls", "xlsx"];
  if (
    validFileExtensions.includes(
      inputFile.name.split(".").pop().toLowerCase()
    )
  ) {

    return true;
  }
   else {
    return false;
  }
} 

updateState = (stateObj) => {
  this.setState(stateObj);
};

  render() {
    return (
      <div>
      <LoadingOverlay active={this.state.isLoading} spinner>
        {this.state.isLoading === true ? (
          ""
        ) : (
          <div class="body-support-block">
            <NavBarComponent updateState={this.updateState} activeTab = 'MGCList'/>
            <div id="Unassigned" class="tabcontent tabcontent1">
              <div class="container-fluid">
                <div class="row">
                  <div class="cardsContainer">
                  <div class="responsive-table tableSearchPlacement MCGL2Mapping">
                        <div className='searchInputMGC'>
                          <input type="text" name="filterDataTable" className="filter-text"
                            id="txtSearchInput"
                            placeholder='Filter Table'
                            value={sessionStorage.getItem("searchInputMGC")}
                            onInput={(e) => this.globalSearch(e.target.value)}
                          />
                        </div>
                        <div class="exportBtnGroup">
                          <span className="input-group-btn left0">
                            <button
                              className="btn btn-primary btn-file btn-inner"
                              id="importbtn"
                            >
                              Import  Excel{" "}
                              <input
                                type="file"
                                name="importFiles"
                                id="importAttrinput"
                                data-test="importAttrinput1"
                                title=" "
                                onClick={(e) => this.resetSelectedFiles(e)}
                                onChange={(e) => this.uploadAttachment(e, this)}
                                accept=".xlsx"
                              />
                            </button>
                          </span>
                          <button class="btn btn-primary" onClick={
                            (e) => { this.exportDataToExcel() }
                          }>Export Excel</button>
                        </div>
                        {this.getTableData()}
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </LoadingOverlay>
       <div className="modal show" id="submittedPopup">
       <div class="modal-dialog modal-success" role="document">
         <div class="modal-content">
           <div class="modal-body">
             <div class="successMessageMain">
               <img src={popupIcon} alt="popup"/>
               <h4>Successfully Imported!</h4>
               <button
                 type="button"
                 class="btn btn-primary"
                 onClick={() => this.closeSubmittedPopup()}
               >
                 Done
               </button>
             </div>
           </div>
         </div>
       </div>
     </div>
     </div>
    );
  }
}
